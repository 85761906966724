import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Typography
}
    from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Calfform(props) {
    const [showUncommonField, setShowUncommonField] = useState(false);
    const [weightError, setWeightError] = useState("");
    const [calvingEaseDisable, setCalvingEaseDisable] = useState(false);
    const [birthDateWarning, setBirthDateWarning] = useState(false);
    const [showFreeMartin, setShowFreeMartin] = useState(false);
    const [notWeighed, setNotWeighed] = useState(false);

    useEffect(() => {
        if (props.sireId && props.damId) {
            const sireBreedCode = props.bulls.find(bull => bull.id == props.sireId)?.breed;
            const damBreedCode = props.cows.find(cow => cow.id == props.damId)?.breed;
            props.setPreferredBreeds([...props.preferredBreeds, `${sireBreedCode}/${damBreedCode}`]);
            props.setBreed(`${sireBreedCode}/${damBreedCode}`)
        }
    }, [props.sireId, props.damId]);

    useEffect(() => {
        checkBirthWeight();
    }, [props.birthWeight, props.birthCircumstance]);

    useEffect(() => {
        if (props.birthCircumstance == "Cow Aborted") {
            if (props.birthWeight === "") {
                props.setBirthWeight(0);
            }
        } 
        if (props.birthCircumstance == "Purchased"){
            props.setCalvingEase(1);
            setCalvingEaseDisable(true);
        }else{
            setCalvingEaseDisable(false);
        }
    }, [props.birthCircumstance]);

    useEffect(() => {
        if (props.birthCircumstance == "Purchased"){
            setBirthDateWarning(false);
            return
        }
        
        const recentBtodDate = new Date(props.herd.recent_btod);
        const calfBirthDate = new Date(props.birthDate);
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        setBirthDateWarning(Math.floor((calfBirthDate - recentBtodDate) / _MS_PER_DAY) < 260);
    }, [props.birthDate]);

    useEffect(() => {
        //hefer is 2, refer to Calf model on rails end. that 2 is pretty standard
        //the calf has to female twin to be freemartin
        setShowFreeMartin(props.sex == 2 && props.twinCalf != 1);
    }, [props.sex, props.twinCalf]);

    const checkBirthWeight = () => {
        if(notWeighed){
            setWeightError("");
            return
        }
        if (props.birthCircumstance == "Live Birth") {
            if (props.birthWeight < 40 || props.birthWeight > 160) {
                setWeightError("Birth weight must be > 40 lbs and < 160 lbs.");
            }else{
                setWeightError("");
            }
        } else if (props.birthCircumstance == "Purchased") {
            if (props.birthWeight < 40) {
                setWeightError("Birth weight must be > 40 lbs.");
            } else {
                setWeightError("");
            }
        } else{
            setWeightError("");
        }
    }

    const toggleShowUncommonField = () => {
        if(showUncommonField){
            setShowUncommonField(false);
        }else{
            setShowUncommonField(true);
        }
    }

    const fieldProps = {
        fullWidth: true,
        variant:"outlined",
        size:"small",
        className:"form-field",
    }

    const handleNotWeighedSelection = () =>{
        if (notWeighed) {
            setNotWeighed(false);
            checkBirthWeight();
        }else{
            setNotWeighed(true);
            props.setBirthWeight("");
            setWeightError("");
        }
    }

    return (
        <Grid container>
            <Grid item md={6} xs={12} className="animal-form-column">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Birth Circumstance"
                            value={props.birthCircumstance}
                            {...fieldProps}
                            required
                            onChange={(e) => props.setBirthCircumstance(e.target.value)}
                        >
                            {
                                props.birthCircumstanceOptions.map((birthCircumstanceOption) => (
                                    < MenuItem key={birthCircumstanceOption[0]} value={birthCircumstanceOption[0]} style={{ padding: "5px" }}>
                                        {birthCircumstanceOption[0]}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Calf ID"
                            value={props.earTagId}
                            {...fieldProps}
                            required
                            onChange={(e) => props.setEarTagId(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Calf Birth Date"
                            value={props.birthDate}
                            {...fieldProps}
                            type="date"
                            required
                            onChange={(e) => props.setBirthDate(e.target.value)}
                        />
                        {
                            birthDateWarning &&
                            <Typography variant="caption" display="block" gutterBottom>
                                Calf's birth date is less than 260 days from last bull turn out date. Are you sure that is right date?
                            </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Calf Birth Weight (lbs.)"
                            value={props.birthWeight}
                            {...fieldProps}
                            type="number"
                            required={!notWeighed}
                            disabled={notWeighed}
                            onChange={(e) => props.setBirthWeight(e.target.value)}
                        />
                        {
                            weightError && 
                            <Typography variant="caption" display="block" gutterBottom>
                                {weightError}
                            </Typography>
                        }
                        <FormControlLabel control={
                            <Checkbox
                                checked={notWeighed}
                                onChange={handleNotWeighedSelection}
                                sx={{
                                    color: "#0A5640",
                                    '&.Mui-checked': {
                                        color: "#0A5640"
                                    }
                                }}
                            />
                        }
                            label="Not Weighed" />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Sex of Calf"
                            value={props.sex}
                            {...fieldProps}
                            required
                            onChange={(e) => props.setSex(e.target.value)}
                        >
                            {
                                props.sexOptions.map((sexOption) => (
                                    < MenuItem key={sexOption[1]} value={sexOption[1]} style={{ padding: "5px" }}>
                                        {`(${sexOption[1]}) ${sexOption[0]}`}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            disabled={calvingEaseDisable}
                            label="Calving Ease"
                            value={props.calvingEase}
                            {...fieldProps}
                            required
                            onChange={(e) => props.setCalvingEase(e.target.value)}
                        >
                            {
                                props.calvingEaseOptions.map((calvingEaseOption) => (
                                    < MenuItem key={calvingEaseOption[1]} value={calvingEaseOption[1]} style={{ padding: "5px" }}>
                                        {`(${calvingEaseOption[1]}) ${calvingEaseOption[0]}`}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>    
            <Grid item md={6} xs={12} className="animal-form-column">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Sire of Calf"
                            value={props.sireId}
                            {...fieldProps}
                            onChange={(e) => props.setSireId(e.target.value)}
                        >
                            {
                                props.bulls.map((bull) => (
                                    < MenuItem key={bull.id} value={bull.id} style={{ padding: "5px" }}>
                                        {bull.ear_tag_id}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Dam of Calf"
                            value={props.damId}
                            {...fieldProps}
                            onChange={(e) => props.setDamId(e.target.value)}
                        >
                            {
                                props.cows.map((cow) => (
                                    < MenuItem key={cow.id} value={cow.id} style={{ padding: "5px" }}>
                                        {cow.ear_tag_id}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Calf Breed"
                            value={props.breed}
                            {...fieldProps}
                            required
                            onChange={(e) => props.setBreed(e.target.value)}
                        >
                            {
                                props.preferredBreeds.map((breed) => (
                                    < MenuItem key={breed} value={breed} style={{ padding: "5px" }}>
                                        {breed}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Registration Name"
                            value={props.registrationName}
                            {...fieldProps}
                            onChange={(e) => props.setRegistrationName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Registration Number"
                            value={props.registrationNumber}
                            {...fieldProps}
                            onChange={(e) => props.setRegistrationNumber(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Electronic ID Number"
                            value={props.electronicId}
                            {...fieldProps}
                            type="number"
                            step="1"
                            onChange={(e) => props.setElectronicId(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Additional Certificate ID"
                            value={props.additionalCertificateId}
                            {...fieldProps}
                            onChange={(e) => props.setAdditionalCertificateId(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12} xs={12} className="animal-form-column">
                    <Grid container>
                        <TextField
                            label="Notes"
                            multiline
                            rows={4}
                            value={props.notes}
                            {...fieldProps}
                            onChange={(e) => props.setNotes(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <hr className="uncommon-situation-form-divider" />
            <Grid container>
                <Grid item xs={12}>
                    <Typography className="uncommon-situation-form-header">
                        <b>Other Situations</b> <span onClick={toggleShowUncommonField}>{showUncommonField ? <ArrowDropDownIcon className="uncommon-situation-arrow" /> : <ArrowDropUpIcon className="uncommon-situation-arrow" /> }</span>
                    </Typography>
                </Grid>
            </Grid>
            {
                showUncommonField &&
                <Grid container>
                    <Grid item md={6} xs={12} className="animal-form-column">
                        <Grid container>
                            <Grid item xs={12}>
                            <TextField
                                select
                                label="Calf raised by foster cow"
                                value={props.raisedByFoster}
                                {...fieldProps}
                                onChange={(e) => props.setRaisedByFoster(e.target.value)}
                            >
                                < MenuItem key="false" value="false" style={{ padding: "5px" }}>
                                    No
                                </MenuItem>
                                < MenuItem key="true" value="true" style={{ padding: "5px" }}>
                                    Yes
                                </MenuItem>
                            </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} className="animal-form-column">
                        <Grid container>
                            <Grid item xs={12}>
                            <TextField
                                select
                                label="Embryo transplant"
                                value={props.embryoTransplant}
                                {...fieldProps}
                                onChange={(e) => props.setEmbryoTransplant(e.target.value)}
                            >
                                < MenuItem key="false" value="false" style={{ padding: "5px" }}>
                                    No
                                </MenuItem>
                                < MenuItem key="true" value="true" style={{ padding: "5px" }}>
                                    Yes
                                </MenuItem>
                            </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                            <TextField
                                select
                                label="Twin"
                                value={props.twinCalf}
                                {...fieldProps}
                                onChange={(e) => props.setTwinCalf(e.target.value)}
                            >
                                {
                                    props.twinCalfOptions.map((twinCalfOption) => (
                                        < MenuItem key={twinCalfOption[1]} value={twinCalfOption[1]} style={{ padding: "5px" }}>
                                            {
                                                twinCalfOption[0]
                                            }
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            </Grid>
                        </Grid>
                        {
                            showFreeMartin &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label="Freemartin"
                                        value={props.freeMartin}
                                        {...fieldProps}
                                        onChange={(e) => props.setFreeMartin(e.target.value)}
                                    >
                                        < MenuItem key="false" value="false" style={{ padding: "5px" }}>
                                            No
                                        </MenuItem>
                                        < MenuItem key="true" value="true" style={{ padding: "5px" }}>
                                            Yes
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}