import React, {useState, useEffect} from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Radio
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../layouts/Flash";
import { formatStandardDate } from "../utils/Helper"


export default function WeightRecord(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [selectedWeight, setSelectedWeight] = useState("");
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [date, setDate] = useState("");
    const [weight, setWeight] = useState("");
    const [weightType, setWeightType] = useState();
    const [hipHeight, setHipHeight] = useState("");
    const [scrotalCircumference, setScrotalCircumference] = useState("");

    const handleClickOpen = (weight) => {
        setSelectedWeight(weight);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShowEditFormOpen = (weight) => {
        setSelectedWeight(weight);
        setShowEditForm(true);
    }

    const handleShowEditFormClose = () => {
        setShowEditForm(false);
    }

    useEffect(() => {
        setDate(selectedWeight.date || "");
        setWeight(selectedWeight.weight || "");
        setHipHeight(selectedWeight.hip_height || "");
        setWeightType(selectedWeight.weight_type || ""); 
        setScrotalCircumference(selectedWeight.scrotal_circumference || "");

    }, [selectedWeight]);

    const weightDelete = () => {
        event.preventDefault();
        fetch(`/delete_weight_capture`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            }, 
            body: JSON.stringify({
                weight_capture: {
                    id: selectedWeight.id,
                    animal_type: "Calf",
                    animal_id: props.calf.id,
                },
            })

        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const weightSave = () => {
        event.preventDefault();
        fetch(`/update_weight_capture`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                weight_capture: {
                    id: selectedWeight.id,
                    animal_type: "Calf",
                    animal_id: props.calf.id,
                    date: date,
                    weight: weight,
                    weight_type: weightType,
                    hip_height: hipHeight,
                    scrotal_circumference: scrotalCircumference
                },
            })

        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Grid container className="the-box">
            <Grid item md={12} xs={12} className="the-box-header">
                <span className="left-item">
                    Weight Records
                </span>
            </Grid>
            <Grid item md={12} xs={12} className="box-table-container">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Weight</TableCell>
                                <TableCell className="header-cell">Date</TableCell>
                                <TableCell className="header-cell">Weight Type</TableCell>
                                <TableCell className="header-cell">Hip Height</TableCell>
                                {
                                    props.calf.sex == "Bull" &&
                                        < TableCell className="header-cell">Scrotal Circumference</TableCell>
                                }
                                <TableCell className="header-cell"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.calf.calf_weights?.map((weight) => (
                                <TableRow key={weight.id}>
                                    <TableCell className="data-cell">
                                        {weight.weight} lbs.
                                    </TableCell>
                                    <TableCell className="data-cell">{formatStandardDate(weight.date)}</TableCell>
                                    <TableCell className="data-cell">{weight.weight_type}</TableCell>
                                    <TableCell className="data-cell">
                                        {
                                            weight.hip_height &&
                                            <span>
                                                {weight.hip_height} inches
                                            </span>
                                        }
                                    </TableCell>
                                    {
                                            
                                            props.calf.sex == "Bull" &&
                                            <TableCell className="data-cell">
                                                {
                                                    weight.scrotal_circumference &&
                                                    <span>
                                                        {weight.scrotal_circumference} cm
                                                    </span>
                                                }
                                            </TableCell>
                                    }
                                    <TableCell className="data-cell">
                                        <span >
                                            <Link href="#" onClick={() => handleShowEditFormOpen(weight)}>
                                                <CreateIcon fontSize="small" className="grey-icons"/>
                                            </Link>
                                        </span>
                                        <span style={{ marginLeft: "20px" }}>
                                            <Link href="#" onClick={() =>handleClickOpen(weight)} className="grey-icons">
                                                <DeleteIcon fontSize="small" />
                                            </Link>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Delete weight for calf {props.calf.ear_tag_id} captured on {selectedWeight.date}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={weightDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div className="delete-modal">
                <Dialog
                    open={showEditForm}
                    onClose={handleShowEditFormClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Grid className="edit-modal" container justify="center">
                        <Grid item md={12}>
                            <Grid container justify="center" className="edit-modal-main-container">
                                <Grid item xs={10} className="edit-modal-form-container">
                                    <div className="header-container">
                                        <span>
                                            Herd {props.herd.herd_id} — Calf {props.calf.ear_tag_id}
                                        </span>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={12} className="form-container">
                                            <span className="secondary-header">
                                                Edit Weight Record
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Date"
                                                value={date}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Weight (lbs.)"
                                                value={weight}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                className="form-field"
                                                required
                                                onChange={(e) => setWeight(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <span>
                                                <Radio
                                                    checked={weightType == "Actual"}
                                                    onChange={(e) => setWeightType(e.target.value)}
                                                    value="Actual"
                                                /> Actual
                                            </span>
                                            <span>
                                                <Radio
                                                    checked={weightType == "Pen Average"}
                                                    onChange={(e) => setWeightType(e.target.value)}
                                                    value="Pen Average"
                                                /> Pen Average
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Hip Height (inches)"
                                                value={hipHeight}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                onChange={(e) => setHipHeight(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            {
                                                props.calf.sex == "Bull" &&
                                                    <TextField
                                                        fullWidth
                                                        label="Scrotal Circumference (cm)"
                                                        value={scrotalCircumference}
                                                        variant="outlined"
                                                        size="small"
                                                        className="form-field"
                                                        onChange={(e) => setScrotalCircumference(e.target.value)}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <Grid container justify="space-between">
                                                <Grid item xs={6} className="cancel-link-container">
                                                    <Link href="#" onClick={handleShowEditFormClose} className="modal-cancel-link">
                                                        Cancel
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={6} className="modal-button-container" >
                                                    <Button onClick={weightSave} className="modal-yes-button">
                                                        <span className="green-font">Save</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
            
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </Grid>
    )
}
