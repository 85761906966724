import React, { useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
    Link,
    MenuItem
}
    from '@material-ui/core';
import BullForm from './BullForm';
import Flash from "../layouts/Flash";
import { getTodayDate } from "../utils/Helper";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default function New(props) {

    const [animalType, setAnimalType] = useState("Bull");
    const [earTagId, setEarTagId] = useState("");
    const [birthDate, setBirthDate] = useState(getTodayDate);
    const [breed, setBreed] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [registrationName, setRegistrationName] = useState("");
    const [electronicId, setElectronicId] = useState("");
    const [sireId, setSireId] = useState("");
    const [damId, setDamId] = useState("");
    const [source, setSource] = useState("Purchased");
    const [use, setUse] = useState("Natural Service");
    const [status, setStatus] = useState("Active");
    const [providerPreferredBreeds, setProviderPreferredBreeds] = useState(props.providerPreferredBreeds);
    const [notes, setNotes] = useState("");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const animalTypes = ["Bull"];
    const sourceOptions = ["Raised", "Purchased"];
    const useOptions = ["Natural Service", "Artificial Insemination"];

    const submitNewAnimalForm = () => {
        event.preventDefault();
        fetch(`/businesses/${props.provider.id}/bulls`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                bull: {
                    ear_tag_id: earTagId,
                    birth_date: birthDate,
                    breed: breed,
                    registration_number: registrationNumber,
                    registration_name: registrationName,
                    electronic_id: electronicId,
                    sire_id: sireId,
                    dam_id: damId,
                    source: source,
                    use: use,
                    status: status,
                    notes: notes
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/businesses/${props.provider.id}/bulls`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="main-container herd animal content-body">
            <Grid container className="grid-container">
                <Grid item xs={12} className="herd-detail-header">
                    <span className="back-arrow"><Link href={`/businesses/${props.provider.id}/bulls`}><ArrowBackIosIcon /></Link></span> Bulls
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="flex-start" className="grid-container">
                <Grid item container md={5} xs={12} className="button-container" >
                    <Grid container className="animal-form-container">
                        <Grid item xs={12} className="animal-form-header-container">
                            <Typography variant="h6" gutterBottom className="animal-form-header">
                                Add a Bull
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="animal-form-field-container">
                            <form onSubmit={submitNewAnimalForm}>
                                <Grid container>
                                    <Grid item md={6} xs={12} className="animal-form-column">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Add Animal"
                                                    select
                                                    value={animalType}
                                                    variant="outlined"
                                                    size="small"
                                                    className="form-field"
                                                    onChange={(e) => { setAnimalType(e.target.value) }}
                                                    required
                                                >
                                                    {
                                                        animalTypes.map((animal, index) => (
                                                            < MenuItem key={index} value={animal} style={{ padding: "5px" }}>
                                                                {animal}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <BullForm
                                    earTagId={earTagId}
                                    setEarTagId={setEarTagId}
                                    birthDate={birthDate}
                                    setBirthDate={setBirthDate}
                                    breed={breed}
                                    setBreed={setBreed}
                                    registrationNumber={registrationNumber}
                                    setRegistrationNumber={setRegistrationNumber}
                                    registrationName={registrationName}
                                    setRegistrationName={setRegistrationName}
                                    electronicId={electronicId}
                                    setElectronicId={setElectronicId}
                                    sireId={sireId}
                                    setSireId={setSireId}
                                    damId={damId}
                                    setDamId={setDamId}
                                    source={source}
                                    setSource={setSource}
                                    use={use}
                                    setUse={setUse}
                                    status={status}
                                    setStatus={setStatus}
                                    notes={notes}
                                    setNotes={setNotes}
                                    sourceOptions={sourceOptions}
                                    useOptions={useOptions}
                                    cows={props.cows}
                                    bulls={props.bulls}
                                    providerPreferredBreeds={providerPreferredBreeds}
                                    setProviderPreferredBreeds={setProviderPreferredBreeds}
                                />
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className="small-container" container alignItems="center" justify="flex-end">
                                            <Link href={`/businesses/${props.provider.id}/bulls`} className="grey-font">
                                                Cancel
                                            </Link>
                                            <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                <span className="green-font">SAVE</span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}