import React, {useState, useEffect} from 'react';
import {
    Typography,
    Grid,
    Link,
    TextField,
    Button,
    MenuItem
}
from '@material-ui/core';
import Flash from "../layouts/Flash";
import states from "../utils/states";
import AddIcon from '@material-ui/icons/Add';

export default function New(props) {
    const [herdId, setHerdId] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState({name: "", id: ""});
    const [locations, setLocations] = useState(props.locations);
    const [name, setName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("AL");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("United States");
    const [county, setCounty] = useState("");
    const [township, setTownship] = useState("");
    const [range, setRange] = useState("");
    const [section, setSection] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [usdaPremiseId, setUsdaPremiseId] = useState("");
    const [haveLocation, setHaveLocation] = useState(locations.length >= 1);
    const [showLocationForm, setShowLocationForm] = useState(false);
    const [flashInfo, setFlashInfo] = useState({flashSeverity: "", flashMessage: ""})
    const [showFlash, setShowFlash] = useState(false);

    const countries = ["United States"];

    useEffect(() => {
        setHaveLocation(locations.length >= 1);
    }, [locations]);

    const saveLocation = () => {
        event.preventDefault();
        if(locations.find(loc => loc.name == name))
        {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Location with same name already exist."})
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
        }else{
            if (locations.find(loc => loc.id == location.id)) {
                const originalLocations = locations.filter((loc) => loc.id != location.id);
                setLocations([...originalLocations, { name: name, id: name }])

            } else {
                setLocations([...locations, { name: name, id: name }])
            }
            setLocation({ name: name, id: name })
            setShowLocationForm(false);
        }
    }

    const handlePhysicalLocationChange = (e) => {
        const selectedLocation = locations.find(loc => loc.id == e.target.value)
        setLocation(selectedLocation);
    }

    const copyBusinessLocation = () => {
        setName(props.business.business_name);
        setAddress1(props.business.address_1);
        setAddress2(props.business.address_2);
        setCity(props.business.city);
        setState(props.business.state);
        setZip(props.business.zip);
        setCountry(props.business.country);
    }

    const saveAndSubmitform = () => {
        event.preventDefault();
        fetch("/herds",{
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                herd: {
                    herd_id: herdId,
                    description: description,
                    location: location
                },
                location: {
                    name: name,
                    address_1: address1,
                    address_2: address2,
                    city: city,
                    state: state,
                    zip: zip,
                    country: country,
                    county: county,
                    township: township,
                    range: range,
                    section: section,
                    latitude: latitude,
                    longitude: longitude,
                    usda_premise_id: usdaPremiseId,
                    business_id: props.business.id
                }
            })
        })
        .then((result)=> result.json())
        .then((result) => {
            if(result.success){
                window.location.href = `/herds/${result.resource.id}/preferred_breeds/new`
            }else{
                setFlashInfo({flashSeverity: "error", flashMessage: result.message})
                setShowFlash(true);
                setTimeout( () => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }
    
    return (
        <div className="main-container herd content-body">
            <Grid container className="grid-container">
                <Grid item md={12} xs={12} >
                    <Typography variant="h6" gutterBottom className="green-font">
                        <b>Create a New Herd</b>
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12} className="table-container">
                    <Grid container>
                        <Grid item md={3} xs={12}>
                            <form onSubmit={saveAndSubmitform}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            label="Herd ID"
                                            value={herdId}
                                            variant="outlined"
                                            size="small"
                                            className="form-field"
                                            onChange={(e)=>setHerdId(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            label="Herd Description"
                                            value={description}
                                            variant="outlined"
                                            size="small"
                                            className="form-field"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                { haveLocation &&
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Physical Location"
                                                value={location.id}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                onChange={handlePhysicalLocationChange}
                                                required
                                            >
                                                {
                                                    locations.map((location, index) => (
                                                        <MenuItem key={index} value={location.id} style={{ padding: "5px" }}>
                                                            {location.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container>
                                    <Grid item xs={8} className="create-new-location-link">
                                        <Link href="#" className="green-font underline-el " onClick={() => setShowLocationForm(true)}>
                                            <span> <AddIcon className="plus-icon" /></span>Create new Physical Location
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8} container alignItems="center" justify="flex-end">  
                                        {
                                            haveLocation ?
                                            <>
                                                <Link href="/" className="grey-font" >
                                                    Cancel
                                                </Link>
                                                <Button variant="contained" type="submit" className="submit-btn">
                                                    <span className="green-font">SAVE AND CONTINUE</span>
                                                </Button>
                                            </>
                                            : 
                                            <p>
                                                You must assign a Physical Location to this herd. Start by creating a new Physical Location.
                                            </p>
                                        }
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>

                        { showLocationForm &&
                            <Grid item md={5} className="location-form-container">
                                <Grid container>
                                    <form onSubmit={saveLocation}>
                                        <Grid item xs={12} className="location-form-header-container">
                                            <Typography variant="h6" gutterBottom className="location-form-header">
                                                Physical location of herd
                                            </Typography>
                                        </Grid>
                                        <Grid container className="ml-25">
                                            <Grid item xs={12} className="actions">
                                                <Link href="#" className="green-font underline-el" onClick={() => copyBusinessLocation(false)}>
                                                    Copy Business Location
                                                </Link>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Grid container>
                                                    <Grid item md={6} xs={12} className="location-form-column">
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Location name"
                                                                    value={name}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setName(e.target.value)}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Address 1"
                                                                    value={address1}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setAddress1(e.target.value)}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Address 2"
                                                                    value={address2}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setAddress2(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="City"
                                                                    value={city}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setCity(e.target.value)}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    label="State"
                                                                    value={state}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onClick={(e) => setState(e.target.value)}
                                                                    required
                                                                >
                                                                    {
                                                                        states.map((state) => (
                                                                            <MenuItem key={state} value={state} style={{ padding: "5px" }}>
                                                                                {state}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Zip/Postal Code"
                                                                    value={zip}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setZip(e.target.value)}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    label="Country"
                                                                    value={country}
                                                                    onChange={(e) => setCountry(e.target.value)}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    required
                                                                >
                                                                    {
                                                                        countries.map((country) => (
                                                                            <MenuItem key={country} value={country} style={{ padding: "5px" }}>
                                                                                {country}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} className="location-form-column">
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="County"
                                                                    value={county}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    required
                                                                    onChange={(e) => setCounty(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Legal land description/Township"
                                                                    value={township}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setTownship(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Range"
                                                                    value={range}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setRange(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Section"
                                                                    value={section}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setSection(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Latitude"
                                                                    value={latitude}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setLatitude(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Longitude"
                                                                    value={longitude}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setLongitude(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="USDA Premises ID"
                                                                    value={usdaPremiseId}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className="form-field"
                                                                    onChange={(e) => setUsdaPremiseId(e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={10} className="actions">
                                                <Grid container >
                                                    <Grid item xs={12} container alignItems="center" justify="flex-end">
                                                        <Link  href="#" className="grey-font" onClick={() => setShowLocationForm(false)}>
                                                            Cancel
                                                        </Link>
                                                        <Button variant="contained" type="submit" className="submit-btn">
                                                            <span className="green-font">SAVE</span>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                showFlash && 
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
            
        </div>
    )
}