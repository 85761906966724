import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Radio,
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getTodayDate, calculateAge, formatStandardDate } from '../utils/Helper';
import Flash from "../layouts/Flash";
import CowWeightForm from "./CowWeightForm"
import BullWeightForm from "./BullWeightForm"
import CalfWeightForm from "./CalfWeightForm"

export default function New(props) {
    const [animals, setAnimals] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedAnimal, setSelectedAnimal] = useState("");
    const [date, setDate] = useState(getTodayDate);
    const [bodyConditionScore, setBodyConditionScore] = useState("7");
    const [weight, setWeight] = useState("");
    const [weightType, setWeightType] = useState("Actual");
    const [hipHeight, setHipHeight] = useState("");
    const [scrotalCircumference, setScrotalCircumference] = useState("");
    const [wean, setWean] = useState(false);
    const [sell, setSell] = useState(false);

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const toggleWean = () => {
        wean ? setWean(false) : setWean(true)
    }

    const toggleSell = () => {
        sell ? setSell(false) : setSell(true)
    }

    useEffect(() => {
        setWeightType("Actual");
    },[selectedAnimal])

    const search = () => {
        event.preventDefault();
        fetch(`/search_animals`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                search_key: searchValue,
                herd_id: props.herd.id
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                setAnimals(result.resource)
            } else {
                setAnimals([]);
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    const submitForm = () => {
        
        event.preventDefault();
        console.log("Submitting form")
        fetch(`/weight_captures`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                weight_capture: {
                    animal_info: selectedAnimal,
                    weight: weight,
                    date: date,
                    weight_type: weightType,
                    body_condition_score: bodyConditionScore,
                    hip_height: hipHeight,
                    scrotal_circumference: scrotalCircumference,
                    wean: wean,
                    sell: sell
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                setFlashInfo({ flashSeverity: "success", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
                setAnimals([]);
                setSelectedAnimal("");
                setDate(getTodayDate);
                setWeight("");
                setWeightType("");
                setBodyConditionScore("");
                setHipHeight("");
                setScrotalCircumference("");
                setWean(false);
                setSell(false);
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    const checkselectedAnimal = (animal) => {
        return selectedAnimal.type == animal.type && selectedAnimal.id == animal.id
    }

    const selectAnimal = (animal) => {
        setSelectedAnimal(animal);
    }

    return (
        <div className="main-container herd cow content-body weight-captures">
            <Grid container item xs={12} className="cow-container">
                <Grid container >
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> Weight Capture
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Grid item xs={12}>
                                    <p>
                                        <strong>Enter animal ID</strong>
                                    </p>
                                </Grid>
                                <Grid container>
                                    <Grid item md={2}>
                                        <TextField
                                            fullWidth
                                            value={searchValue}
                                            variant="outlined"
                                            placeholder="Search"
                                            size="small"
                                            className="form-field"
                                            onChange={(e) => { setSearchValue(e.target.value)}}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Grid container >
                                            <Grid item xs={12} container alignItems="center" justify="flex-end">
                                                <Button variant="contained" style={{minWidth: "80%"}} onClick={search} className="submit-btn">
                                                    <span className="green-font">Search</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={6} style={{ marginTop: "10px"}}>
                                <TableContainer className="cull-animals-table">
                                    <Table aria-label="customized table">
                                        <TableHead className="animals-table-header">
                                            <TableRow>
                                                <TableCell align="center" ></TableCell>
                                                <TableCell align="center">
                                                    Animal ID
                                                </TableCell>
                                                <TableCell align="center">
                                                    Age
                                                </TableCell>
                                                <TableCell align="center">
                                                    Type
                                                </TableCell>
                                                <TableCell align="center">
                                                    Sex
                                                </TableCell>
                                                <TableCell align="center">
                                                    Herd
                                                </TableCell>
                                                <TableCell align="center">
                                                    Last Weighed
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                animals.map((animal) => {
                                                    return (
                                                        <TableRow key={`${animal.type}-${animal.id}`}>
                                                            <TableCell align="center" className="check-box">
                                                                <Radio
                                                                    checked={checkselectedAnimal(animal)}
                                                                    onChange={() => selectAnimal(animal)}
                                                                    value={animal}
                                                                />
                                                            </TableCell>
                                                                {
                                                                    animal.type == "Calf" ?
                                                                    <TableCell align="center" onClick={() => { window.location.href = `/herds/${animal.herd_id}/calves/${animal.id}` }} className="black-font link">
                                                                            {animal.ear_tag_id}
                                                                        </TableCell>
                                                                    : animal.type == "Cow" ?
                                                                        <TableCell align="center" onClick={() => { window.location.href = `/herds/${animal.herd_id}/cows/${animal.id}` }} className="black-font link">
                                                                            {animal.ear_tag_id}
                                                                        </TableCell>
                                                                    : animal.type == "Bull" &&
                                                                        <TableCell align="center" onClick={() => { window.location.href = `/businesses/${animal.business_id}/bulls/${animal.id}` }} className="black-font link">
                                                                            {animal.ear_tag_id}
                                                                        </TableCell>
                                                                }
                                                            <TableCell align="center">{calculateAge(animal.birth_date)}</TableCell>
                                                            <TableCell align="center">{animal.type}</TableCell>
                                                            <TableCell align="center">{animal.sex}</TableCell>
                                                            <TableCell align="center">{animal.herd}</TableCell>
                                                            <TableCell align="center">{formatStandardDate(animal.last_weighed)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item md={6}>
                                {
                                    selectedAnimal.type == "Cow" &&
                                        <CowWeightForm
                                            submitForm={submitForm}
                                            date={date}
                                            setDate={setDate}
                                            bodyConditionScore={bodyConditionScore}
                                            setBodyConditionScore={setBodyConditionScore}
                                            weight={weight}
                                            setWeight={setWeight}
                                            weightType={weightType}
                                            setWeightType={setWeightType}
                                            herd={props.herd}
                                            bcsScores={props.bcsScores}
                                        />
                                }
                                {
                                    selectedAnimal.type == "Bull" &&
                                    <BullWeightForm
                                        submitForm={submitForm}
                                        date={date}
                                        setDate={setDate}
                                        weight={weight}
                                        setWeight={setWeight}
                                        weightType={weightType}
                                        setWeightType={setWeightType}
                                        herd={props.herd}
                                    />
                                }
                                {
                                    selectedAnimal.type == "Calf" &&
                                    <CalfWeightForm
                                        submitForm={submitForm}
                                        date={date}
                                        setDate={setDate}
                                        hipHeight={hipHeight}
                                        setHipHeight={setHipHeight}
                                        weight={weight}
                                        setWeight={setWeight}
                                        weightType={weightType}
                                        setWeightType={setWeightType}
                                        herd={props.herd}
                                        sex={selectedAnimal.sex }
                                        scrotalCircumference={scrotalCircumference}
                                        setScrotalCircumference={setScrotalCircumference}
                                        wean={wean}
                                        toggleWean={toggleWean}
                                        sell={sell}
                                        toggleSell={toggleSell}
                                        weaned={selectedAnimal.weaned}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}