import React from 'react';
import {
    Grid,
    Link
} from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function Pagination(props){
    return(
        <Grid item xs={12}>
            <Grid container justify="center" className="pagination-container">
                <Grid item md={6} xs={12}>
                    {
                        props.totalPages > 1 &&
                        <ul>
                            {
                                props.offset > 0 &&
                                <li>
                                    <Link href="#" onClick={() => props.handlePaginationPageChange(props.offset - 1)}>
                                        <ArrowBackIosIcon className="arrows" fontSize="small" />
                                    </Link>
                                </li>
                            }
                            {
                                [...Array(props.totalPages)].map((val, index) => {
                                    return (
                                        <li key={index}>
                                            <Link href="#" onClick={() => props.handlePaginationPageChange(index)}>
                                                <span className={props.offset == index ? "active" : ""}>{index + 1}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                            {
                                props.offset < props.totalPages - 1 &&
                                <li>
                                    <Link href="#" onClick={() => props.handlePaginationPageChange(props.offset + 1)}>
                                        <ArrowForwardIosIcon className="arrows" fontSize="small" />
                                    </Link>
                                </li>
                            }
                        </ul>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}