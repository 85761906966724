import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem,
    Checkbox
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '../shared/Pagination';
import { getTodayDate, calculateAge } from '../utils/Helper';
import Flash from "../layouts/Flash";
import CheckIcon from '@material-ui/icons/Check';

export default function New(props) {
    const [cullDate, setCullDate] = useState(getTodayDate);
    const [cullReason, setCullReason] = useState("H");
    const [selectedBulls, setSelectedBulls] = useState([]);

    const [bulls, setBulls] = useState(props.bulls);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState({ sortBy: "bullId", order: "ascending" });

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(bulls.length / perPage));

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value;
        const filteredBulls = props.bulls.filter((bull) => bull.ear_tag_id.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1);
        setSearchValue(searchParam);
        setBulls(filteredBulls);
    }

    const sortBulls = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    useEffect(() => {
        const arrayBulls = [...bulls];
        if (sortValue.sortBy == "bullId") {
            arrayBulls.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "age") {
            arrayBulls.sort((a, b) => (a.birth_date < b.birth_date ? 1 : -1));
        }

        if (sortValue.sortBy == "sire") {
            arrayBulls.sort((a, b) => (a.sire_ear_tag_id > b.sire_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "dam") {
            arrayBulls.sort((a, b) => (a.dam_ear_tag_id > b.dam_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "breed") {
            arrayBulls.sort((a, b) => (a.breed > b.breed ? 1 : -1));
        }

        if (sortValue.order == "ascending") {
            setBulls(arrayBulls);
        } else {
            setBulls(arrayBulls.reverse());
        }
    }, [sortValue]);

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.bulls];
            setBulls(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const handleCheckboxClick = (bullId) => {
        if (selectedBull(bullId)) {
            const restOfBullIds = selectedBulls.filter((id) => id != bullId);
            setSelectedBulls(restOfBullIds);
        } else {
            setSelectedBulls([...selectedBulls, bullId])
        }
    }

    const selectedBull = (bullId) => {
        return selectedBulls.findIndex((id) => id == bullId) != -1;
    }

    const submitForm = () => {
        event.preventDefault();

        if (selectedBulls.length == 0) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select a bull." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        fetch(`/businesses/${props.business.id}/bull_cullings`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                bull_culling: {
                    culled_date: cullDate,
                    culled_reason: cullReason,
                    animal_ids: selectedBulls,
                    animal_type: "Bull"
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/businesses/${props.business.id}/bulls`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className="main-container herd bull content-body">
            <Grid container item xs={12} className="bull-container">
                <Grid container>
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/businesses/${props.business.id}/bulls`}><ArrowBackIosIcon /></Link></span> Bulls
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { search(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <form onSubmit={submitForm}>
                                    <Grid container justify="space-between">
                                        <div>
                                            <p>Select Animals to Cull</p>
                                        </div>
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Culled Date"
                                                value={cullDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setCullDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Culled Reason"
                                                value={cullReason}
                                                variant="outlined"
                                                placeholder="Search"
                                                size="small"
                                                className="form-field"
                                                onChange={(e) => { setCullReason(e.target.value) }}
                                                select
                                            >
                                                {
                                                    props.cullReasons.map((cullReason) => (
                                                        < MenuItem key={cullReason[1]} value={cullReason[1]} style={{ padding: "5px" }}>
                                                            {cullReason[1]}: {cullReason[0]}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Grid container justify="flex-end">
                                                <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end" style={{ paddingRight: "0", marginRight: "0" }}>
                                                    <Link href={`/businesses/${props.business.id}/bulls`} className="grey-font">
                                                        Cancel
                                                    </Link>
                                                    <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                        <span className="green-font">SAVE</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <TableContainer className="cull-animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" ><CheckIcon fontSize="large" align="center" /></TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("bullId", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire ID
                                            {
                                                sortValue.sortBy == "bullId" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "bullId" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("age", sortValue.order == "ascending" ? "descending" : "ascending")}>Age
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("sire", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire of Sire
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("dam", sortValue.order == "ascending" ? "descending" : "ascending")}>Dam of Sire
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>Breed
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Source</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Culled Date</TableCell>
                                        <TableCell align="center">Culled Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        bulls.map((bull) => {
                                            return (
                                                <TableRow key={bull.id}>
                                                    <TableCell align="center" className="check-box">
                                                        <Checkbox
                                                            checked={selectedBull(bull.id)}
                                                            onChange={() => handleCheckboxClick(bull.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/businesses/${props.business.id}/bulls/${bull.id}` }} className="black-font link" >
                                                        {bull.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calculateAge(bull.birth_date)}</TableCell>
                                                    <TableCell align="center">{bull.sire_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{bull.dam_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{bull.breed}</TableCell>
                                                    <TableCell align="center">{bull.source}</TableCell>
                                                    <TableCell align="center">{bull.status}</TableCell>
                                                    <TableCell align="center">{bull.culled_date}</TableCell>
                                                    <TableCell align="center">{bull.formatted_culled_reason}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}