import React from 'react';
import Link from '@material-ui/core/Link';

export default function AdminNav() {
    const activeURL = (checkParam) => {
        return window.location.href.includes(`admin_users/${checkParam}`) ? "active-admin-menu-item" : ""
    }

    return (
        <div className="admin-menu-container">
            <Link href="/admin_users/producers" className={`admin-menu-item  ${activeURL("producers")}`}>
                <span>
                    Producers
                </span> 
            </Link>
            <Link href="/admin_users/producer_invites" className={`admin-menu-item  ${activeURL("producer_invites")}`}>
                <span>
                    Producer Invites
                </span>
            </Link>
            <Link href="/admin_users/admin_users" className={`admin-menu-item  ${activeURL("admin_users")}`}>
                <span>
                    Admin Users
                </span>
            </Link>
        </div>
    )
}
