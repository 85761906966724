import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
}
    from '@material-ui/core';
import Flash from "../../layouts/Flash";

export default function Index(props) {
    const [file, setFile] = useState("");
    const [validatedData, setValidatedData] = useState([]);
    const [falseValidatedData, setFalseValidatedData] = useState([]);

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleFileCapture = (e) => {
        setFile(e.target.files[0]);
        setValidatedData([]);
        setFalseValidatedData([]);
    }

    useEffect(() => {
        const falseRecord = validatedData.filter((data) => {return data.valid == false});
        setFalseValidatedData(falseRecord);
    }, [validatedData]);

    const validateBulkUploadCSV = () => {
        event.preventDefault();
        if (file){
            let formData = new FormData();
            formData.append('animals_file_upload[file]', file);
            formData.append('animals_file_upload[herd_id]', props.herd.id)
            fetch(`/admin_users/validate_bulk_upload_csv`, {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": props.authenticity_token,
                    encType: "multipart/form-data"
                },
                body: formData
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.success) {
                        setValidatedData(result.resource);
                        setFlashInfo({ flashSeverity: "success", flashMessage: `${result.resource.length} records validated.` })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    } else {
                        if (result.resource.length > 0){
                            setValidatedData(result.resource);
                        }
                        setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    const submitForm = () => {
        event.preventDefault();
        fetch(`/admin_users/animals_file_uploads`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                animals_file_upload: {
                    data: validatedData,
                    herd_id: props.herd.id
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/admin_users/producers/${props.herd.business_id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    
    return (
        <div className="admin-main-container invite-admin">
            <Grid container>
                <Grid item xs={12}>
                    <p className="header-text">Upload Data for herd {props.herd.herd_id}</p>
                </Grid>
                <Grid item xs={12}>
                    <p>1. First download the <a href="/bulk_animal_upload_template.csv" target="_blank" className="download-template-link"> CSV Template</a></p>
                </Grid>
                <Grid item xs={12}>
                    <p>2. Checklist for CSV upload </p>
                    <ul>
                        <li style={{ marginTop: "5px" }}>Order the animals by birth date, oldest animal first</li>
                        <li style={{ marginTop: "5px" }}>Animal type should be either "cow" or "bull" </li>
                        <li style={{ marginTop: "5px" }}>Ear tag ID must be present</li>
                        <li style={{ marginTop: "5px" }}>Birth date must be present and in yyyy-mm-dd format</li>
                        <li style={{ marginTop: "5px" }}>Breed must be present</li>
                        <li style={{ marginTop: "5px" }}>Source should be either "Raised" or "Purchased"</li>
                        <li style={{ marginTop: "5px" }}>For cows, Herd Entry Date must be present and in yyyy-mm-dd format</li>
                        <li style={{ marginTop: "5px" }}>For bulls, Use must be present and should be either "Natural Service" or "Artificial Insemination"</li>
                        
                    </ul>
                </Grid>
                <Grid item xs={12}>
                    <div style={{paddingBottom: "15px"}} >
                        {file?.name}
                    </div>
                    <div className="invite-button-container" style={{ textAlign: "left" }}>
                        <Link href={`/admin_users/producers/${props.herd.business_id}`} className="cancel-link" style={{ marginLeft: "25px" }}>
                            Cancel
                        </Link>
                        <input
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={(e) => handleFileCapture(e)}
                        />
                        <label htmlFor="raised-button-file">
                            <Button style={{ marginLeft: "10px" }} component="span" className="upload-btn">
                                Upload CSV
                            </Button>
                        </label>

                        {
                            file && falseValidatedData.length == 0 && validatedData.length == 0 ?
                                <Button variant="contained" onClick={validateBulkUploadCSV} className="submit-btn" style={{ marginLeft: "10px" }}>
                                    <span className="green-font">Validate CSV</span>
                                </Button>
                            :
                                <Button variant="contained" className="submit-btn-disabled" style={{ marginLeft: "10px"}}>
                                    <span >Validate CSV</span>
                                </Button>
                        }

                        {
                            falseValidatedData.length == 0 && validatedData.length > 0 ?
                                <Button variant="contained" onClick={submitForm} className="submit-btn" style={{ marginLeft: "10px"}}>
                                    <span className="green-font">Save</span>
                                </Button>
                            :
                                <Button variant="contained" className="submit-btn-disabled"  style={{ marginLeft: "10px"}}>
                                    <span>Save</span>
                                </Button>
                        }
                        
                    </div>
                    <Grid item xs={6} className="invite-button-container" style={{textAlign: "left"}}>
                        {
                            falseValidatedData.length > 0 &&
                                <>
                                    <p><strong>Invalid records</strong></p>
                                    <Grid item xs={12}>
                                        <TableContainer className="table-container">
                                            <Table className="table" style={{ marginBottom: "20px" }}>
                                                <TableHead className="table-head">
                                                    <TableRow className="table-head-row">
                                                        <TableCell>
                                                            Animal Type
                                                        </TableCell>
                                                        <TableCell>
                                                            Ear Tag ID
                                                        </TableCell>
                                                        <TableCell>
                                                            Valid
                                                        </TableCell>
                                                        <TableCell>
                                                            Validation Message
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        falseValidatedData.map((data, index) => (
                                                            <TableRow key={index} className="data-row">
                                                                <TableCell className="table-cell">
                                                                    {data.animal_type}
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    {data.ear_tag_id}
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    {data.valid.toString()}
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    {data.validation_message}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}