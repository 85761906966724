import React from 'react';
import { Link, TextField, Button, Grid } from "@material-ui/core";
import Flash from "../../layouts/Flash";
import HomeInfo from '../../shared/HomeInfo';

class NewAdmin extends React.Component{
    render(){
        return(
            <div className="devise main-container">
                <div className="pages-background sessions">
                    <form action="/admin_users/sign_in" method="post">
                        <input
                        type="hidden"
                        name="authenticity_token"
                        value={this.props.authenticity_token}
                        />
                        <Grid container justify="flex-end" >
                            <Grid item md={3} xs={12}>
                                <Grid container className="form-container">
                                    <Grid item xs={12} className="header">
                                        CHAPS ADMIN LOGIN
                                    </Grid>
                                    <Grid item xs={12} className="field-container">
                                        <TextField
                                            id="email-address"
                                            label="Email Address"
                                            required
                                            name="admin_user[email]"
                                            variant="outlined"
                                            className="form-field"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="field-container">
                                        <TextField
                                            id="password"
                                            required
                                            label="Password"
                                            name="admin_user[password]"
                                            variant="outlined"
                                            type="password"
                                            className="form-field"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="submit-btn-container">
                                        <Button variant="contained" className="submit-btn" fullWidth type="submit">Login</Button>
                                    </Grid>
                                    <Grid item xs={12} className="cancel-link-container">
                                        <Link href="/" color="inherit">
                                            Cancel
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12} className="cancel-link-container">
                                        <Link href="/admin_users/password/new" color="inherit">
                                            Forgot your Password?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container alignItems="flex-end" className="container">
                        <HomeInfo />
                    </Grid>
                </div>
                <Flash severity={this.props.flash_severity} message={this.props.flash_message} />
            </div>
        )
    }
}
export default NewAdmin