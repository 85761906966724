import React, { useState, useEffect } from 'react';
import {
    Grid,
    Link,
    InputLabel,
}
    from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Flash from '../layouts/Flash';
import CrossBreedForm from '../preferred_breeds/CrossBreedFrom';
import SingleBreedForm from '../preferred_breeds/SingleBreedForm';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

export default function HerdBreeds(props) {
    //selectedSingleBreedList is used as temprorary holding area of selected single breed
    //finalSingleBreedList is when single breeds are saved, selectedSingleBreedList converts into finalSingleBreedList after save is clicked

    //crossBreedCombinationList holds all possible Combinations of crossBreeds from finalSingleBreedList
    //propsBreeds is state, initally holds all breeds from props and get updated as filter applies and assign to allBreeds

    //allBreeds are structured for multicolumn list eg. [[{id: 1, breed_code: "AN", breed_name: "Angus"}]]
    //allCrossBreeds are grouped by sire breed eg[[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}]]
    //allCrossBreeds are structured from crossBreedCombination List

    const [showSingleBreedsForm, setShowSingleBreedsForm] = useState(false);
    const [showCrossBreedsForm, setShowCrossBreedsForm] = useState(false);
    const [selectedSingleBreedList, setSelectedSingleBreedList] = useState([]);
    //eg: [{id: 1, breed_code: "AN", breed_name: "Angus"}], used to temproraliy save single breed, persists on cancel but not saved

    const [finalSingleBreedList, setFinalSingleBreedList] = useState([]);
    //eg: [{id: 1, breed_code: "AN", breed_name: "Angus"}], not update until saved, used for cross breed combination construction

    const [crossBreedCombinationList, setCrossBreedCombinationList] = useState([]);
    // eg: [{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, dam: {id: 2, breed_code: "LM", breed_name: "limousin"}}], array of all possible combination of cross breed
    //crossBreedCombinationList feeds to allCrossBreed, which is structured for mulicolumn lists

    const [selectedCrossBreedList, setSelectedCrossBreedList] = useState([]);
    // this array is temproray holding for selected cross breeds, canceling the form persists the selected option

    const [finalCrossBreedList, setFinalCrossBreedList] = useState([]);
    //until user hit saved selectedCrossBreed wont turn into finalCross Breed, only the item in finalCrossBreed will submitted

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [propsBreeds, setPropsBreeds] = useState(props.breeds);
    //propsBreeds is an array of all single breeds, [{id: 2, breed_code: "LM", breed_name: "limousin"}, {id: 1, breed_code: "AN", breed_name: "Angus"}]
    //the reason for propsBreeds is feeder for allBreeds which is designed for multicolumn grid

    const [allBreeds, setAllBreeds] = useState([]);
    //allbreeds is filtered and propsBreeds is not filtered
    //this converts array of breeds into array of array of breeds so that we can display in multiple columns
    //eg [[{id: 1, breed_code: "AN", breed_name: "Angus"}], [{id: 1, breed_code: "AN", breed_name: "Angus"}], [{id: 1, breed_code: "AN", breed_name: "Angus"}]]

    const [allCrossBreeds, setAllCrossBreeds] = useState([]);
    //this convers array of crossBreeds into array or array of crossBreed so that we can display in multiple column
    //eg: [[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}],[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}]]

    const [editable, setEditable] = useState(false);

    useEffect(() => {
        updateLists(props.preferred_single_breeds, props.preferred_cross_breeds)
    }, []);

    const updateLists = (preferred_single_breeds, preferred_cross_breeds) => {
        let tempSingleBreeds = [];
        preferred_single_breeds.forEach((sb) => {
            tempSingleBreeds.push({
                id: sb.breed_id,
                breed_code: sb.breed_code,
                breed_name: sb.breed_name,
                single_breed_id: sb.id
            })
        })
        setSelectedSingleBreedList(tempSingleBreeds);
        setFinalSingleBreedList(tempSingleBreeds)

        let tempCrossBreeds = [];

        preferred_cross_breeds.forEach((cb) => {
            tempCrossBreeds.push(
                {
                    sire: {
                        id: cb.sire_breed.id,
                        breed_code: cb.sire_breed.breed_code,
                        breed_name: cb.sire_breed.breed_name,
                    },
                    dam: {
                        id: cb.dam_breed.id,
                        breed_code: cb.dam_breed.breed_code,
                        breed_name: cb.dam_breed.breed_name,
                    },
                    cross_breed_id: cb.id
                }
            )
        })
        setSelectedCrossBreedList(tempCrossBreeds);
        setFinalCrossBreedList(tempCrossBreeds);
    }

    useEffect(() => {
        //after finalSingleBreedList update, we generate all the possible combinations of crossBreeds
        const crossBreedsOptions = []
        finalSingleBreedList.map((sire) => {
            finalSingleBreedList.map((dam) => {
                if (sire.id != dam.id) {
                    crossBreedsOptions.push({
                        sire: sire,
                        dam: dam
                    })
                }
            });
        });
        setCrossBreedCombinationList(crossBreedsOptions);
    }, [finalSingleBreedList]);

    useEffect(() => {
        //after array of crossBreed update we group them by sire breed_code and convert form array of crossBreed to array of array of crossBreed for multicoulumn view
        // [] => [[][][]]
        const crossBreedsArray = [...crossBreedCombinationList];
        const dividedCrossBreeds = crossBreedsArray.reduce((r, a) => {
            r[a.sire.breed_code] = [...r[a.sire.breed_code] || [], a];
            return r;
        }, {});
        setAllCrossBreeds(Object.values(dividedCrossBreeds));
    }, [crossBreedCombinationList]);

    useEffect(() => {
        //this converts array of breed into array of array of breeds for 3 column division
        //the sub array has almost equal length so that we can show that in muliple column
        // [] => [[][][]]
        const breedsArray = [...propsBreeds];
        const dividedBreeds = [];
        for (let i = 3; i > 0; i--) {
            dividedBreeds.push(breedsArray.splice(0, Math.ceil(breedsArray.length / i)))
        }
        dividedBreeds[dividedBreeds.length - 1].push(...props.hidden_breeds)
        setAllBreeds(dividedBreeds);
    }, [propsBreeds]);

    const updateFinalCrossBreedList = () => {
        //when on save on finalCrossBreed form we updated the finalCrossBreedList array and get ready for submission
        submitUpdatedPreferredBreeds(selectedSingleBreedList, selectedCrossBreedList);
        setShowCrossBreedsForm(false);
    }

    const updateFinalBreedList = () => {
        //calls on save in single breed form
        //converts temporary saving SelectedSingleBreedList into finalSingleBreedList that feeds cross breed list
        submitUpdatedPreferredBreeds(selectedSingleBreedList, selectedCrossBreedList);
        setShowSingleBreedsForm(false);
    }

    const submitUpdatedPreferredBreeds = (single_breeds, cross_breeds) => {
        fetch(`/update_herd_breeds/${props.herd.id}`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                preferred_breeds: {
                    single_breeds: single_breeds,
                    cross_breeds: cross_breeds
                }
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    setFlashInfo({ flashSeverity: "success", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                    updateLists(result?.resource?.preferred_single_breeds, result?.resource?.preferred_cross_breeds)
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const filterKeys = (keys) => () => {
        let filteredBreeds = [];
        if (keys == "AG") {
            filteredBreeds = props.breeds.filter((breed) => ["A", "B", "C", "D", "E", "F", "G"].includes(breed.breed_code[0]));
        } else if (keys == "HP") {
            filteredBreeds = props.breeds.filter((breed) => ["H", "I", "J", "K", "L", "M", "N", "O", "P"].includes(breed.breed_code[0]));
        } else if (keys == "RZ") {
            filteredBreeds = props.breeds.filter((breed) => ["Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"].includes(breed.breed_code[0]));
        } else if (keys == "all") {
            filteredBreeds = props.breeds
        }
        setPropsBreeds(filteredBreeds);
    }

    const removeSingleBreed = (singleBreed) => {
        const singleBreedID = singleBreed.single_breed_id;
        const breedName = `${singleBreed.breed_code} ${singleBreed.breed_name}`;
        if (confirm(`Are you sure you want to delete ${breedName}?`)) {
            const tempSelectedSingleBreeds = selectedSingleBreedList.filter(item => item.single_breed_id != singleBreedID)
            submitUpdatedPreferredBreeds(tempSelectedSingleBreeds, selectedCrossBreedList);
        }
    }

    const removeCrossBreed = (crossBreed) => {
        const crossBreedID = crossBreed.cross_breed_id
        const breedName = `${crossBreed.sire.breed_code}${crossBreed.dam.breed_code} ${crossBreed.sire.breed_name}-${crossBreed.dam.breed_name}`
        if (confirm(`Are you sure you want to delete ${breedName}?`)) {
            const tempSelectedCrossBreeds = selectedCrossBreedList.filter(item => item.cross_breed_id != crossBreedID)
            submitUpdatedPreferredBreeds(selectedSingleBreedList, tempSelectedCrossBreeds);
        }
    }

    const handleEditClick = () => {
        setEditable(true)
    }

    return (
        <div className="main-container herd content-body">
            <Grid container className="grid-container">
                <Grid item xs={12} className="herd-detail-header">
                    <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> <span>Edit Herd: {props.herd.herd_id}</span>
                </Grid>
            </Grid>
            <div className="main-container herd preferred-breeds content-body">
                <form action="/herds">
                    <Grid container className="grid-container">
                        <Grid item md={12} xs={12}>
                            <Grid container>
                                <Grid item md={3} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className="herd-info-container">
                                            <InputLabel htmlFor="component-simple" className="pb-15">Herd ID</InputLabel>
                                            {props.herd.herd_id}
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid item xs={12} className="herd-info-container">
                                            <InputLabel htmlFor="component-simple" className="pb-15">Description</InputLabel>
                                            {props.herd.description}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} className="herd-info-container">
                                            <InputLabel htmlFor="component-simple" className="pb-15">Physical Location</InputLabel>
                                            {props.location.name}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    showSingleBreedsForm ?
                                        <SingleBreedForm
                                            allBreeds={allBreeds}
                                            filterKeys={filterKeys}
                                            updateFinalBreedList={updateFinalBreedList}
                                            herd={props.herd}
                                            setShowSingleBreedsForm={setShowSingleBreedsForm}
                                            setSelectedSingleBreedList={setSelectedSingleBreedList}
                                            selectedSingleBreedList={selectedSingleBreedList}
                                            setFinalSingleBreedList={setFinalSingleBreedList}
                                            finalSingleBreedList={finalSingleBreedList}
                                        />
                                        :
                                        showCrossBreedsForm ?
                                            <CrossBreedForm
                                                selectedCrossBreedList={selectedCrossBreedList}
                                                finalCrossBreedList={finalCrossBreedList}
                                                finalSingleBreedList={finalSingleBreedList}
                                                allCrossBreeds={allCrossBreeds}
                                                herd={props.herd}
                                                setShowCrossBreedsForm={setShowCrossBreedsForm}
                                                updateFinalCrossBreedList={updateFinalCrossBreedList}
                                                setSelectedCrossBreedList={setSelectedCrossBreedList}
                                            />
                                            :
                                            <Grid item md={3}>
                                                <Grid item xs={12}>
                                                    <div className="selected-breed-container">
                                                        <InputLabel htmlFor="component-simple" className="pb-15">
                                                            Preferred Breeds
                                                            {
                                                                !editable &&
                                                                <Link href="#" onClick={handleEditClick} style={{ marginLeft: "20px", color: "gray" }}><CreateIcon fontSize="small" /></Link>
                                                            }
                                                        </InputLabel>
                                                        {
                                                            finalSingleBreedList.length >= 1 &&
                                                            finalSingleBreedList.map((breed, index) => {
                                                                return (
                                                                    <Grid container justify="space-between" key={index}>
                                                                        <Grid item xs={10}>
                                                                            <div className="pb-5">
                                                                                <b>{breed.breed_code}</b> {breed.breed_name}
                                                                            </div>
                                                                        </Grid>
                                                                        {
                                                                            editable &&
                                                                            <Grid item xs={2}>
                                                                                <span><Link onClick={() => removeSingleBreed(breed)}> <DeleteIcon fontSize="small" style={{ color: "gray" }} /></Link></span>
                                                                            </Grid>
                                                                        }
                                                                        {
                                                                            editable && 
                                                                            <Grid item xs={12} style={{borderBottom: "2px solid grey"}}></Grid>
                                                                        }
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            finalCrossBreedList.length > 0 &&
                                                            finalCrossBreedList.map((crossBreed, index) => {
                                                                return (
                                                                    <Grid container justify="space-between" key={index}>
                                                                        <Grid item xs={10}>
                                                                            <div className="pb-5">
                                                                                <b>{crossBreed.sire.breed_code}{crossBreed.dam.breed_code}</b> {crossBreed.sire.breed_name}-{crossBreed.dam.breed_name}
                                                                            </div>
                                                                        </Grid>
                                                                        {
                                                                            editable &&
                                                                            <Grid item xs={2}>
                                                                                <span><Link onClick={() => removeCrossBreed(crossBreed)}> <DeleteIcon fontSize="small" style={{ color: "gray" }} /> </Link></span>
                                                                            </Grid>
                                                                        }
                                                                        {
                                                                            editable &&
                                                                            <Grid item xs={12} style={{ borderBottom: "2px solid grey" }}></Grid>
                                                                        }
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        editable &&
                                                        <>
                                                            <div className="add-breed-link">
                                                                <Link href="#" className={`underline-el pl-5 ${finalSingleBreedList.length > 0 ? "grey-font" : "green-font"}`} onClick={() => setShowSingleBreedsForm(true)}>
                                                                    <span className="green-font"> <AddIcon className="plus-icon" /></span>Add single breed(s) to Preferred Breed list
                                                                </Link>
                                                            </div>
                                                            {
                                                                finalSingleBreedList.length < 1 &&
                                                                <p className="pl-10">
                                                                    You must assign at least one breed to this herd. <br />
                                                                    Start by adding single breed(s) to your Preferred Breed List.
                                                                </p>
                                                            }
                                                            {
                                                                finalSingleBreedList.length > 1 &&
                                                                <div className="add-breed-link">
                                                                    <Link href="#" className={`underline-el pl-5 ${finalCrossBreedList.length > 0 ? "grey-font" : "green-font"}`} onClick={() => setShowCrossBreedsForm(true)}>
                                                                        <span className="green-font"> <AddIcon className="plus-icon" /></span>Add crossbreed(s) to Preferred Breed list
                                                                    </Link>
                                                                    {
                                                                        finalCrossBreedList.length == 0 &&
                                                                        <p className="pl-10">
                                                                            Add crossbreeds if necessary.
                                                                        </p>
                                                                    }

                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                {
                    showFlash &&
                    <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
                }
            </div>
        </div>
    )
}