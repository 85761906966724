import React, {useState, useEffect} from 'react';
import {
    Grid,
    Link,
    Button
}
from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CowForm from '../animals/CowForm';
import Flash from "../layouts/Flash";

export default function Edit(props) {

    const [earTagId, setEarTagId] = useState(props.cow.ear_tag_id);
    const [birthDate, setBirthDate] = useState(props.cow.birth_date);
    const [breed, setBreed] = useState(props.cow.breed);
    const [registrationNumber, setRegistrationNumber] = useState(props.cow.registration_number || "");
    const [registrationName, setRegistrationName] = useState(props.cow.registration_name || "");
    const [electronicId, setElectronicId] = useState(props.cow.electronic_id || "");
    const [sireId, setSireId] = useState(props.cow.sire_id || "");
    const [damId, setDamId] = useState(props.cow.dam_id || "");
    const [herdEntryDate, setHerdEntryDate] = useState(props.cow.herd_entry_date);
    const [source, setSource] = useState(props.cow.source || "");
    const [status, setStatus] = useState(props.cow.status);
    const [preferredBreeds, setPreferredBreeds] = useState(props.preferredBreeds);
    const [notes, setNotes] = useState(props.cow.notes || "");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [bangsId, setBangsId] = useState(props.cow.bangs_id || "");

    const sourceOptions = ["Raised", "Purchased"];

    useEffect(() => {
        if (!preferredBreeds.includes(breed)) {
            setPreferredBreeds([...preferredBreeds, breed])
        }
    }, [breed]);

    const submitForm = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/cows/${props.cow.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                cow: {
                    ear_tag_id: earTagId,
                    birth_date: birthDate,
                    breed: breed,
                    registration_number: registrationNumber,
                    registration_name: registrationName,
                    electronic_id: electronicId,
                    sire_id: sireId,
                    dam_id: damId,
                    herd_entry_date: herdEntryDate,
                    source: source,
                    status: status,
                    notes: notes,
                    bangs_id: bangsId
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/herds/${props.herd.id}/cows/${props.cow.id}`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }
    return (
        <div className="main-container herd cow content-body">
            <Grid container className="cow-container grid-container">
                <Grid item md={12} xs={12} className="herd-detail-header">
                    <span className="back-arrow"><Link href={`/herds/${props.herd.id}/cows/${props.cow.id}`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Cow {props.cow.ear_tag_id}
                </Grid>
                <Grid container className="table-container">
                    <form onSubmit={submitForm}>
                        <Grid container>
                            <Grid item md={5} xs={12} className="cow-form-container">
                                <CowForm
                                    herd={props.herd}
                                    earTagId={earTagId}
                                    setEarTagId={setEarTagId}
                                    birthDate={birthDate}
                                    setBirthDate={setBirthDate}
                                    breed={breed}
                                    setBreed={setBreed}
                                    registrationNumber={registrationNumber}
                                    setRegistrationNumber={setRegistrationNumber}
                                    registrationName={registrationName}
                                    setRegistrationName={setRegistrationName}
                                    electronicId={electronicId}
                                    setElectronicId={setElectronicId}
                                    sireId={sireId}
                                    setSireId={setSireId}
                                    damId={damId}
                                    setDamId={setDamId}
                                    herdEntryDate={herdEntryDate}
                                    setHerdEntryDate={setHerdEntryDate}
                                    source={source}
                                    setSource={setSource}
                                    status={status}
                                    setStatus={setStatus}
                                    notes={notes}
                                    setNotes={setNotes}
                                    sourceOptions={sourceOptions}
                                    cows={props.cows}
                                    bulls={props.bulls}
                                    preferredBreeds={preferredBreeds}
                                    setPreferredBreeds={setPreferredBreeds}
                                    bangsId={bangsId}
                                    setBangsId={setBangsId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end">
                                        <Link href={`/herds/${props.herd.id}/cows/${props.cow.id}`} className="grey-font">
                                            Cancel
                                        </Link>
                                        <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                            <span className="green-font">SAVE</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}