import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default function Index(){
    return(
        <div className="pages-background2 main-container">
            <Grid container>
                <Grid item md={6} xs={12} className="dashboard-left-panel">
                    <Typography variant="h5" gutterBottom className="green-font">
                        <b>Welcome to CHAPS!</b>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        As a new user, you will first need to create at least one herd.
                    </Typography>
                    <Button href="herds/new" variant="contained" color="primary" className="dashboard-button">
                        <b>Create New Herd</b>
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}