import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button
}
    from '@material-ui/core';
import ReproductionPerformance from './spa_reports/ReproductionPerformance';
import SpaCalvingDistribution from './spa_reports/SpaCalvingDistribution';
import ProductionPerformance from './spa_reports/ProductionPerformance';

export default function SpaReport(props) {
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);
    const [year, setYear] = useState(props.year);

    const rerunReport = () => {
        window.location.href = `/spa_report/${selectedHerdId}?year=${year}`
    }

    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>NCBA - IRM - SPA Summary of Herd Reproduction and Production Performance</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.business_name}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.formatted_address}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Herd/Breeding Group: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedHerdId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setSelectedHerdId(e.target.value)}
                                        >
                                            {
                                                props.herds.map((herd) => (
                                                    < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                        {herd.herd_id}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Year: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={year}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            {
                                                props.years.map((i) => (
                                                    < MenuItem key={i} value={i} style={{ padding: "5px" }}>
                                                        {i}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <Button className="btn" onClick={() => rerunReport()}>
                                            Rerun Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <ReproductionPerformance
                        herd={props.herd}
                        year={props.year}
                    />
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <SpaCalvingDistribution
                        herd={props.herd}
                        year={props.year}
                    />
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <ProductionPerformance
                        herd={props.herd}
                        year={props.year}
                    />
                </Grid>
            </Grid>
        </div>
    )
}