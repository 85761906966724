import React, { useState, useEffect } from 'react';

export default function SummaryOfCalvesBirth(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_calves_birth/?herd_id=${props.herd.id}&year=${props.year}&sex=${props.sex}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                    :
                    <table className="table">
                        <tbody>
                            <tr>
                                <th className="label-col thin-border">
                                    # of Calves Born
                                </th>
                                <th className="label-col thin-border">
                                    {data?.number_of_calves_born}
                                </th>
                            </tr>
                            <tr>
                                <th className="label-col thin-border">
                                    # of Calves Died
                                </th>
                                <th className="label-col thin-border">
                                    {data?.number_of_calves_died}
                                </th>
                            </tr>
                            <tr>
                                <th className="label-col thin-border">
                                    # of Calves Sold
                                </th>
                                <th className="label-col thin-border">
                                    {data?.number_of_calves_sold}
                                </th>
                            </tr>
                        </tbody>
                    </table>
            }
        </>
    )
}