import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Checkbox,
    Radio,
    Dialog,
    DialogActions,
    DialogContentText
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '../shared/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import { getTodayDate } from '../utils/Helper';
import Flash from "../layouts/Flash";

export default function New(props) {
    const [open, setOpen] = React.useState(false);
    const [weanDate, setWeanDate] = useState(getTodayDate);
    const [selectedCalves, setSelectedCalves] = useState(props.calves);
    const [errorMessage, setErrorMessage] = useState("");

    const [calves, setCalves] = useState(props.calves);
    const [sortValue, setSortValue] = useState({ sortBy: "calfId", order: "ascending" });

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(100);
    const [totalPages] = useState(Math.ceil(calves.length / perPage));

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const sortCalves = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    useEffect(() => {
        const arrayCalves = [...calves];
        if (sortValue.sortBy == "calfId") {
            arrayCalves.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.order == "ascending") {
            setCalves(arrayCalves);
        } else {
            setCalves(arrayCalves.reverse());
        }
    }, [sortValue]);

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.calves];
            setCalves(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const handleCalfSelection = (calf) => {
        if (calf.selected){
            calf.selected = false;
        }else{
            calf.selected = true;
        }
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }
    
    const selectedCalf = (calfId) => {
        const calf = selectedCalves.find((calf) => calf.id == calfId);
        return calf.selected;
    }

    const validateSelectedRecords = (finalSelectedCalves) => {
        const emptyWeights = finalSelectedCalves.filter((calf) => calf.weight.trim() == "" && calf.weight_type != "Not Weighed");
        if (emptyWeights.length > 0){
            const earTags = emptyWeights.map((calf) => calf.ear_tag_id);
            setErrorMessage(`Weight is empty for ${earTags.join(", ")}`);
            setOpen(true);
            return false;
        }else{
            return true;
        }
    }

    const submitForm = () => {
        event.preventDefault();
        const finalSelectedCalves = selectedCalves.filter((selectedCalf) => selectedCalf.selected == true);
        if (finalSelectedCalves.length == 0) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select a calf." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        if (validateSelectedRecords(finalSelectedCalves) == false){
            return;
        }

        fetch(`/herds/${props.herd.id}/calf_weanings`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                calf_weaning: {
                    calves: finalSelectedCalves,
                    date: weanDate,
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const toggleSell = (calf) => {
        if (calf.sell) {
            calf.sell = false;
        } else {
            calf.sell = true;
        }
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const weightInput = (weight, calf) => {
        calf.weight = weight;
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const setWeightType = (weightType, calf) => {
        calf.weight_type = weightType;
        if(weightType == "Not Weighed"){
            calf.weight = "";
        }
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const hipHeightInput = (hipHeight, calf) => {
        calf.hip_height = hipHeight;
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const scrotalCircumferenceInput = (scrotalCircumference, calf) => {
        calf.scrotal_circumference = scrotalCircumference;
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const notesInput = (notes, calf) => {
        calf.notes = notes;
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const validateWeight = (weight) => {
        if (weight.trim() == ""){
            setErrorMessage(`Weight is empty.`);
            setOpen(true);
        }
        else{
            const intWeight = parseInt(weight);
            if (intWeight < 250 || intWeight > 1200){
                setErrorMessage(`Weight is out of standard limit. Recommended >= 250lbs and <= 1200 lbs.`);
                setOpen(true);
            }
        }
    } 

    const validateHipHeight = (hipHeight) => {
        const intHipHeight = parseInt(hipHeight);
        if (intHipHeight < 15 || intHipHeight > 72) {
            setErrorMessage(`Hip Height is out of standard limit. Recommended >= 25 inches and <= 72 inches.`);
            setOpen(true);
        }
    }

    const validateScrotalCircumference = (scrotalCircumference) => {
        const intScrotalCircumference = parseInt(scrotalCircumference);
        if (intScrotalCircumference < 20 || intScrotalCircumference > 40) {
            setErrorMessage(`Scrotal Circumference is out of standard limit. Recommended >= 20 cm and <= 40 cm.`);
            setOpen(true);
        }
    }

    const handlePagecancelAndBack = () => {
        const finalSelectedCalves = selectedCalves.filter((selectedCalf) => selectedCalf.selected == true);
        if (finalSelectedCalves.length > 0){
            if (confirm("There are some unchanged changes. Are you sure?")){
                window.location.href = `/herds/${props.herd.id}`
            }
        }
        else{
            window.location.href = `/herds/${props.herd.id}`
        }
    }

    return (
        <div className="main-container herd cow content-body weaning">
            <Grid container item xs={12} className="cow-container">
                <Grid container >
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href="#" onClick={handlePagecancelAndBack}><ArrowBackIosIcon /></Link></span> Weaning Calves — {props.herd.herd_id}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <form onSubmit={submitForm}>
                                    <Grid container justify="space-between">
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Weaning Date"
                                                value={weanDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setWeanDate(e.target.value)}
                                            />
                                        </Grid>
                                       
                                        <Grid item md={6}>
                                            <Grid container justify="flex-end">
                                                <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end" style={{marginRight: "0", paddingRight: "0"}}>
                                                    <Link href="#" onClick={handlePagecancelAndBack} className="grey-font">
                                                        Cancel
                                                    </Link>
                                                    <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                        <span className="green-font">SAVE</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <TableContainer className="cull-animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" ><CheckIcon fontSize="large" align="center" /></TableCell>
                                        <TableCell align="center" onClick={() => sortCalves("calfId", sortValue.order == "ascending" ? "descending" : "ascending")}>Calf ID
                                            {
                                                sortValue.sortBy == "calfId" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "calfId" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Sex</TableCell>
                                        <TableCell align="center">Sell</TableCell>
                                        <TableCell align="center">Weight (lb)</TableCell>
                                        <TableCell align="center">Weight Type</TableCell>
                                        <TableCell align="center">Hip Height (inches)</TableCell>
                                        <TableCell align="center">Scrotal Circum. (cm)</TableCell>
                                        <TableCell align="center">Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        calves.map((calf) => {
                                            return (
                                                <TableRow key={calf.id}>
                                                    <TableCell align="center" className="check-box">
                                                        <Checkbox
                                                            checked={selectedCalf(calf.id)}
                                                            onChange={() => handleCalfSelection(calf)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/herds/${props.herd.id}/calves/${calf.id}` }} className="black-font link" >
                                                        {calf.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calf.sex}</TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={calf.sell}
                                                            onChange={() => toggleSell(calf)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" className={`${calf.weight_type == "Not Weighed" && "dark-disable"}`}>
                                                        <TextField
                                                            fullWidth
                                                            value={calf.weight}
                                                            disabled={calf.weight_type == "Not Weighed"}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => weightInput(e.target.value, calf)}
                                                            onBlur={(e) => validateWeight(e.target.value)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <span>
                                                            <Radio
                                                                checked={calf.weight_type == "Actual"}
                                                                onChange={(e) => setWeightType(e.target.value, calf) }
                                                                value="Actual"
                                                                style={{ marginRight: "-5px" }}
                                                            /> Actual
                                                        </span>
                                                        <span style={{marginLeft: "10px"}}>
                                                            <Radio
                                                                checked={calf.weight_type == "Not Weighed"}
                                                                onChange={(e) => setWeightType(e.target.value, calf)}
                                                                value="Not Weighed"
                                                                style={{ marginRight: "-5px" }}
                                                            /> Not Weighed
                                                        </span>
                                                        <span style={{ marginLeft: "10px" }}>
                                                            <Radio
                                                                checked={calf.weight_type == "Pen Average"}
                                                                onChange={(e) => setWeightType(e.target.value, calf)}
                                                                value="Pen Average"
                                                                style={{ marginRight: "-5px" }}
                                                            /> Pen Average
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="center" >
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={calf.hip_height}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => hipHeightInput(e.target.value, calf)}
                                                            onBlur={(e) => validateHipHeight(e.target.value)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" className={`${calf.sex != "Bull" && "dark-disable"}`}>
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={calf.scrotal_circumference}
                                                            disabled={calf.sex != "Bull"}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => scrotalCircumferenceInput(e.target.value, calf)}
                                                            onBlur={(e) => validateScrotalCircumference(e.target.value)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={calf.notes}
                                                            variant="standard"
                                                            size="small"
                                                            className="form-field move-to-the-right"
                                                            required
                                                            onChange={(e) => notesInput(e.target.value, calf)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContentText className="delete-dialog-content" style={{paddingTop: "25px"}}>
                        {errorMessage}
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between" style={{width: "420px"}}>
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={handleClose} className="modal-yes-button" style={{width: "150px"}}>
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}