import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem
}
    from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '../shared/Pagination';
import RemoveIcon from '@material-ui/icons/Remove';
import { calculateAge, formatStandardDate } from '../utils/Helper';

export default function Index(props) {
    const [allBulls, setAllBulls] = useState([]);
    const [bulls, setBulls] = useState(props.bulls);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState({ sortBy: "bullId", order: "ascending" });
    const [statusFilter, setStatusFilter] = useState("Both");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages, setTotalPages] = useState(Math.ceil(bulls.length / perPage));

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    useEffect(() => {
        const currentOffset = offset * perPage;
        const data = [...allBulls];
        setBulls(data.slice(currentOffset, currentOffset + perPage));
    }, [offset, allBulls]);

    const sortBulls = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    useEffect(() => {
        applyFilter();
    }, [sortValue, statusFilter, searchValue]);

    const applyFilter = () => {
        let allBulls = [...props.bulls];
        if (statusFilter != "Both") {
            allBulls = allBulls.filter((cow) => cow.status.toLowerCase() == statusFilter.toLowerCase());
        }

        if (searchValue != "") {
            allBulls = allBulls.filter((cow) => cow.ear_tag_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        }

        if (sortValue.sortBy == "cowId") {
            allBulls.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "age") {
            allBulls.sort((a, b) => (a.birth_date < b.birth_date ? 1 : -1));
        }

        if (sortValue.sortBy == "sire") {
            allBulls.sort((a, b) => (a.sire_ear_tag_id > b.sire_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "dam") {
            allBulls.sort((a, b) => (a.dam_ear_tag_id > b.dam_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "breed") {
            allBulls.sort((a, b) => (a.breed > b.breed ? 1 : -1));
        }

        if (sortValue.order == "descending") {
            allBulls.reverse();
        }

        setAllBulls(allBulls);
        setTotalPages(Math.ceil(allBulls.length / perPage));
    }
    return (
        <div className="main-container herd bull content-body">
            <Grid container item xs={12} className="bull-container">
                <Grid container>
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/dashboard`}><ArrowBackIosIcon /></Link></span> Bulls
                            </Grid>

                            <Grid item md={2} xs={12} className="bull-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/businesses/${props.provider.id}/bulls/new`} className="bull-header-button"><span > <AddIcon className="add-icon" /></span>ADD BULL</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="bull-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/businesses/${props.provider.id}/bull_cullings/new`} className="bull-header-button"><span > <RemoveIcon className="add-icon" /></span> Cull Bull(s)</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header">
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setSearchValue(e.target.value) }}
                                />
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Status"
                                    value={statusFilter}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setStatusFilter(e.target.value); }}
                                >
                                    {
                                        ["", "Active", "Culled", "Both"].map((option, index) => (
                                            < MenuItem key={index} value={option} style={{ padding: "5px" }}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <TableContainer className="animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" onClick={() => sortBulls("bullId", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire ID
                                            {
                                                sortValue.sortBy == "bullId" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "bullId" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("age", sortValue.order == "ascending" ? "descending" : "ascending")}>Age
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("sire", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire of Sire
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("dam", sortValue.order == "ascending" ? "descending" : "ascending")}>Dam of Sire
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortBulls("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>Breed
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Source</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Culled Date</TableCell>
                                        <TableCell align="center">Culled Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        bulls.map((bull) => {
                                            return (
                                                <TableRow key={bull.id}>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/businesses/${props.provider.id}/bulls/${bull.id}` }} className="black-font link" >
                                                        {bull.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calculateAge(bull.birth_date)}</TableCell>
                                                    <TableCell align="center">{bull.sire_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{bull.dam_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{bull.breed}</TableCell>
                                                    <TableCell align="center">{bull.source}</TableCell>
                                                    <TableCell align="center">{bull.status}</TableCell>
                                                    <TableCell align="center">{formatStandardDate(bull.bull_culling?.culled_date)}</TableCell>
                                                    <TableCell align="center">{bull.bull_culling?.formatted_culled_reason}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
        </div>
    )
}