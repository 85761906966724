import React from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
}
    from '@material-ui/core';

export default function Show(props) {

    return (
        <div className="admin-main-container">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item xs={3}>
                            <p className="header-text">Active Inventory</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item md={11} xs={12}>
                            <Grid container justify="space-between">
                                <Grid item md={7} xs={12}>
                                    <TableContainer className="table-container">
                                        <Table aria-label="customized table" className="table">
                                            <TableHead className="table-head">
                                                <TableRow className="table-head-row">
                                                    <TableCell>Herd ID</TableCell>
                                                    <TableCell align="left">Description</TableCell>
                                                    <TableCell align="center">BTOD</TableCell>
                                                    <TableCell align="center">Cows</TableCell>
                                                    <TableCell align="center">Calves</TableCell>
                                                    <TableCell align="center">TOTAL</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.herds.map((herd) => {
                                                        return (
                                                            <TableRow key={herd.id} className="data-row">
                                                                <TableCell onClick={() => { window.location.href = ` /admin_users/animals_file_uploads/new?herd_id=${herd.id}` }} align="left" className="link table-cell">
                                                                    {herd.herd_id}
                                                                </TableCell>
                                                                <TableCell align="left">{herd.description}</TableCell>
                                                                <TableCell align="center" className="table-cell">
                                                                    {herd.recent_btod}
                                                                    {
                                                                        herd.too_old_btod &&
                                                                        <Tooltip title="The current BTOD is more than 330 days past. Please set a new BTOD.">
                                                                            <img src={props.warning_icon} alt="warning" width="25px" style={{ display: "inline-block", verticalAlign: "bottom", marginLeft: "10px" }} />
                                                                        </Tooltip>
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center" className="table-cell">
                                                                    <b>{herd.cows_count}</b>
                                                                </TableCell>
                                                                <TableCell align="center" className="table-cell">
                                                                    <b>{herd.calves_count}</b>
                                                                </TableCell>
                                                                <TableCell align="center"><b>{herd.total_animals}</b></TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                                <TableRow>
                                                    <TableCell align="left"><b>TOTAL {props.herds.length} HERDS</b></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="center"><b>{props.total_cows}</b></TableCell>
                                                    <TableCell align="center"><b>{props.total_calves}</b></TableCell>
                                                    <TableCell align="center"><b>{props.total_cows + props.total_calves}</b></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TableContainer className="table-container">
                                        <Table aria-label="customized table" className="table">
                                            <TableHead className="table-head">
                                                <TableRow className="table-head-row">
                                                    <TableCell colSpan={2}>Bulls</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left" className="black-font herd-link"><b>TOTAL BULLS</b></TableCell>
                                                    <TableCell align="left" className="black-font herd-link"><b>{props.bulls.length}</b></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}