import React, { useState, useEffect } from 'react';

export default function SummaryOfCowsPresentlyInHerdTable(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_cows_presently_in_herd/?herd_id=${props.herd.id}&btod_id=${props.btod.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                :
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <td colSpan={2} className="data-col yellow-border">
                                    Summary of Cows Presently in Herd
                                </td>
                            </tr>
                            <tr className="secondary-header">
                                <td className="label-col darker-right">
                                    Measure
                                </td>
                                <td className="data-col">
                                    Count
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Total Cows Exposed
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_exposed}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Total Cows Kept for Calving
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_kept_for_calving}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Aborted
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_aborted}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Open*
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_open}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Calving(Live + Full Term Still Born)
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_calving}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Losing a Calf Prior to Weaning
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_losing_prior_to_weaning}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Weaning a Calf
                                </td>
                                <td className="data-col center-text">
                                    {data.cows_weaning_a_calf}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }
        </>
    )
}