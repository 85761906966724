import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem
}
    from '@material-ui/core';

export default function Index(props) {
    const [selectedHerdId, setSelectedHerdId] = useState(props.herds[0].id);

    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={11} xs={12}>
                    <Grid container justify="space-between">
                        <Grid container item xs={12} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Reports</b>
                                </Typography>
                            </Grid>
                            <Grid item md={12} style={{ marginTop: "10px" }}>
                                <TextField
                                    select
                                    style={{ width: "200px"}}
                                    label="For Herd"
                                    value={selectedHerdId}
                                    variant="outlined"
                                    size="small"
                                    required
                                    onChange={(e) => setSelectedHerdId(e.target.value)}
                                >
                                    {
                                        props.herds.map((herd) => (
                                            < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                {herd.herd_id}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item md={10}>
                                <Grid container justify="space-between">
                                    <Grid item md={5} xs={12} className="report-card" onClick={() => window.location.href = `/calf_performance/${selectedHerdId}`}>
                                        <Typography className="report-card-heading">
                                            Calf Performance
                                        </Typography>
                                        <Typography className="report-card-content">
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5} xs={12} className="report-card">
                                        <Typography className="report-card-heading" onClick={() => window.location.href = `/herd_performance_comparision/${selectedHerdId}`} >
                                            Herd Performance Comparison
                                        </Typography>
                                        <Typography className="report-card-content">
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="space-between">
                                    <Grid item md={5} xs={12} className="report-card" onClick={() => window.location.href = `/calving_distribution_table/${selectedHerdId}`}>
                                        <Typography className="report-card-heading">
                                            Calving Distribution Table
                                        </Typography>
                                        <Typography className="report-card-content">
                                            
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5} xs={12} className="report-card">
                                        <Typography className="report-card-heading" onClick={() => window.location.href = `/spa_report/${selectedHerdId}`}>
                                            NCBA - IRM - SPA Summary of Herd Production and Production Performance
                                        </Typography>
                                        <Typography className="report-card-content">
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="space-between">
                                    <Grid item md={5} xs={12} className="report-card" onClick={() => window.location.href = `/cow_lifetime_progeny/${selectedHerdId}`}>
                                        <Typography className="report-card-heading">
                                            Cow Lifetime Progeny Report
                                        </Typography>
                                        <Typography className="report-card-content">
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}