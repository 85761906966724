import React, { useState, useEffect } from 'react';
import { twoDecimalFormat, noDecimalFormat } from '../../utils/Helper';

export default function SummaryOfHerdAverages(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_herd_averages/?herd_id=${props.herd.id}&year=${props.year}&sex=${props.sex}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                    :
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <th colSpan={8} className="label-col">
                                    Herd Averages
                                </th>
                            </tr>
                            <tr className="secondary-header">
                                <th className="label-col thin-border">
                                </th>
                                <th className="label-col thin-border">
                                    Birth Weight (lb)
                                </th>
                                <th className="label-col thin-border">
                                    Wean Weight (lb)
                                </th>
                                <th className="label-col thin-border">
                                    Age (Day)
                                </th>
                                <th className="label-col thin-border">
                                    ADJ 205 (lb)
                                </th>
                                <th className="label-col thin-border">
                                    Frame Score
                                </th>
                                <th className="label-col thin-border">
                                    ADG (lb)
                                </th>
                                <th className="label-col thin-border">
                                    WDA (lb)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="data-col thin-border">
                                    Current Year Average
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.current_year_averge?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.current_year_averge?.wean_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.current_year_averge?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.current_year_averge?.adj_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.current_year_averge?.frame_score)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.current_year_averge?.adg)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.current_year_averge?.wda)}
                                </td>
                            </tr>
                            <tr>
                                <td className="data-col thin-border">
                                    Prior Year Average
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.prior_year_average?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.prior_year_average?.wean_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.prior_year_average?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.prior_year_average?.adj_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.prior_year_average?.frame_score)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.prior_year_average?.adg)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.prior_year_average?.wda)}
                                </td>
                            </tr>
                            <tr>
                                <td className="data-col thin-border">
                                    5 Year Herd Rolling Average
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_herd_rolling_average?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_herd_rolling_average?.wean_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_herd_rolling_average?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_herd_rolling_average?.adj_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_herd_rolling_average?.frame_score)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_herd_rolling_average?.adg)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_herd_rolling_average?.wda)}
                                </td>
                            </tr>
                            <tr>
                                <td className="data-col thin-border">
                                    5 Year CHAPS Rolling Average
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_chaps_average?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_chaps_average?.wean_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(data?.five_year_chaps_average?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_chaps_average?.adj_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_chaps_average?.frame_score)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_chaps_average?.adg)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {twoDecimalFormat(data?.five_year_chaps_average?.wda)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }
        </>
    )
}