import React, {useState, useEffect} from 'react';
import {
    Grid,
    Link,
    Button
}
from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BullForm from '../bulls/BullForm';
import Flash from "../layouts/Flash";

export default function Edit(props) {

    const [earTagId, setEarTagId] = useState(props.bull.ear_tag_id);
    const [birthDate, setBirthDate] = useState(props.bull.birth_date);
    const [registrationNumber, setRegistrationNumber] = useState(props.bull.registration_number || "");
    const [registrationName, setRegistrationName] = useState(props.bull.registration_name || "");
    const [electronicId, setElectronicId] = useState(props.bull.electronic_id || "");
    const [sireId, setSireId] = useState(props.bull.sire_id || "");
    const [damId, setDamId] = useState(props.bull.dam_id || "");
    const [source, setSource] = useState(props.bull.source || "");
    const [use, setUse] = useState(props.bull.use || "");
    const [status, setStatus] = useState(props.bull.status);
    const [providerPreferredBreeds, setProviderPreferredBreeds] = useState(props.providerPreferredBreeds);
    const [breed, setBreed] = useState(props.bull.breed);
    const [notes, setNotes] = useState(props.bull.notes || "");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const sourceOptions = ["Raised", "Purchased"];
    const useOptions = ["Natural Service", "Artificial Insemination"];

    useEffect(() => {
        if (!providerPreferredBreeds.includes(breed)) {
            setProviderPreferredBreeds([...providerPreferredBreeds, breed])
        }
    }, [breed]);

    const submitForm = () => {
        event.preventDefault();
        fetch(`/businesses/${props.bull.business_id}/bulls/${props.bull.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                bull: {
                    ear_tag_id: earTagId,
                    birth_date: birthDate,
                    breed: breed,
                    registration_number: registrationNumber,
                    registration_name: registrationName,
                    electronic_id: electronicId,
                    sire_id: sireId,
                    dam_id: damId,
                    source: source,
                    use: use,
                    status: status,
                    notes: notes
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/businesses/${props.bull.business_id}/bulls/${props.bull.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className="main-container herd bull content-body">
            <Grid container className="bull-container grid-container">
                <Grid item container xs={12}>
                    <Grid item md={12} xs={12} className="herd-detail-header">
                        <span className="back-arrow"><Link href={`/businesses/${props.bull.business_id}/bulls/${props.bull.id}`}><ArrowBackIosIcon /></Link></span> Bull {props.bull.ear_tag_id}
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <form onSubmit={submitForm}>
                        <Grid container>
                            <Grid item md={5} xs={12} className="bull-form-container">
                                <BullForm
                                    earTagId={earTagId}
                                    setEarTagId={setEarTagId}
                                    birthDate={birthDate}
                                    setBirthDate={setBirthDate}
                                    breed={breed}
                                    setBreed={setBreed}
                                    registrationNumber={registrationNumber}
                                    setRegistrationNumber={setRegistrationNumber}
                                    registrationName={registrationName}
                                    setRegistrationName={setRegistrationName}
                                    electronicId={electronicId}
                                    setElectronicId={setElectronicId}
                                    sireId={sireId}
                                    setSireId={setSireId}
                                    damId={damId}
                                    setDamId={setDamId}
                                    source={source}
                                    setSource={setSource}
                                    use={use}
                                    setUse={setUse}
                                    status={status}
                                    setStatus={setStatus}
                                    notes={notes}
                                    setNotes={setNotes}
                                    sourceOptions={sourceOptions}
                                    useOptions={useOptions}
                                    cows={props.cows}
                                    bulls={props.bulls}
                                    providerPreferredBreeds={providerPreferredBreeds}
                                    setProviderPreferredBreeds={setProviderPreferredBreeds}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end">
                                        <Link href={`/businesses/${props.bull.business_id}/bulls/${props.bull.id}`} className="grey-font">
                                            Cancel
                                        </Link>
                                        <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                            <span className="green-font">SAVE</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}