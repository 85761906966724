import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem
}
    from '@material-ui/core';
import Flash from "../layouts/Flash";

export default function New(props) {
    const [file, setFile] = useState("");
    const [validatedData, setValidatedData] = useState([]);
    const [falseValidatedData, setFalseValidatedData] = useState([]);
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleFileCapture = (e) => {
        setFile(e.target.files[0]);
        setValidatedData([]);
        setFalseValidatedData([]);
    }

    useEffect(() => {
        const falseRecord = validatedData.filter((data) => { return data.valid == false });
        setFalseValidatedData(falseRecord);
    }, [validatedData]);

    const validateBulkUploadCSV = () => {
        event.preventDefault();
        if (file) {
            let formData = new FormData();
            formData.append('animals_file_upload[file]', file);
            formData.append('animals_file_upload[herd_id]', selectedHerdId)
            fetch(`/admin_users/validate_bulk_upload_csv`, {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": props.authenticity_token,
                    encType: "multipart/form-data"
                },
                body: formData
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.success) {
                        setValidatedData(result.resource);
                        setFlashInfo({ flashSeverity: "success", flashMessage: `${result.resource.length} records validated.` })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    } else {
                        if (result.resource.length > 0) {
                            setValidatedData(result.resource);
                        }
                        setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    return (
        <div className="main-container herd cow content-body">
            <Grid container item xs={12} className="cow-container">
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12} className="admin-flex-container">
                                <div className="admin-main-container invite-admin">
                                    <Grid container>
                                        <Grid item md={11} xs={12}>
                                            <Grid container>
                                                <Grid item md={12} xs={12} className="herd-detail-header" style={{paddingLeft: 0}}>
                                                    Bulk File Validation For Upload
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>1. First download the <a href="/bulk_animal_upload_template.csv" target="_blank" className="download-template-link"> CSV Template</a></p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>2. Checklist for CSV upload </p>
                                            <ul>
                                                <li style={{ marginTop: "5px" }}>Order the animals by birth date, oldest animal first</li>
                                                <li style={{ marginTop: "5px" }}>Animal type should be either "cow" or "bull" </li>
                                                <li style={{ marginTop: "5px" }}>Ear tag ID must be present</li>
                                                <li style={{ marginTop: "5px" }}>Birth date must be present and in yyyy-mm-dd format</li>
                                                <li style={{ marginTop: "5px" }}>Breed must be present</li>
                                                <li style={{ marginTop: "5px" }}>Source should be either "Raised" or "Purchased"</li>
                                                <li style={{ marginTop: "5px" }}>For cows, Herd Entry Date must be present and in yyyy-mm-dd format</li>
                                                <li style={{ marginTop: "5px" }}>For bulls, Use must be present and should be either "Natural Service" or "Artificial Insemination"</li>
                                            </ul>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span style={{display: "inline-block", marginTop: "10px"}}>
                                                3. Select Herd to validate:
                                            </span>
                                            <TextField
                                                select
                                                style={{width: "200px", marginLeft: "15px"}}
                                                label="Herd"
                                                value={selectedHerdId}
                                                variant="outlined"
                                                size="small"
                                                required
                                                onChange={(e) => setSelectedHerdId(e.target.value)}
                                            >
                                                {
                                                    props.herds.map((herd) => (
                                                        < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                            {herd.herd_id}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>4. After validation, send the .CSV file to admin for upload: <span className="email-link">{props.admin_email_address}</span> </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="invite-button-container" style={{ textAlign: "left" }}>
                                                <Link href={`/dashboard`} className="cancel-link">
                                                    Cancel
                                                </Link>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    type="file"
                                                    onChange={(e) => handleFileCapture(e)}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Button style={{ marginLeft: "10px", width: "auto" }} component="span" className="upload-btn">
                                                        Select CSV to Validate
                                                    </Button>
                                                </label>

                                                {
                                                    file && falseValidatedData.length == 0 && validatedData.length == 0 ?
                                                        <Button variant="contained" onClick={validateBulkUploadCSV} className="submit-btn" style={{ marginLeft: "10px" }}>
                                                            <span className="green-font">Validate CSV</span>
                                                        </Button>
                                                        :
                                                        <Button variant="contained" className="submit-btn-disabled" style={{ marginLeft: "10px" }}>
                                                            <span >Validate CSV</span>
                                                        </Button>
                                                }

                                            </div>
                                            <div style={{ paddingBottom: "15px" }} >
                                                {file?.name}
                                            </div>
                                            <Grid item xs={6} className="invite-button-container" style={{ textAlign: "left" }}>
                                                {
                                                    falseValidatedData.length > 0 &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <TableContainer className="table-container">
                                                                <Table className="table" style={{ marginBottom: "20px" }}>
                                                                    <TableHead className="table-head">
                                                                        <TableRow className="table-head-row">
                                                                            <TableCell>
                                                                                Animal Type
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Ear Tag ID
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Valid
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Validation Message
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            falseValidatedData.map((data, index) => (
                                                                                <TableRow key={index} className="data-row">
                                                                                    <TableCell className="table-cell">
                                                                                        {data.animal_type}
                                                                                    </TableCell>
                                                                                    <TableCell className="table-cell">
                                                                                        {data.ear_tag_id}
                                                                                    </TableCell>
                                                                                    <TableCell className="table-cell">
                                                                                        {data.valid.toString()}
                                                                                    </TableCell>
                                                                                    <TableCell className="table-cell">
                                                                                        {data.validation_message}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        showFlash &&
                                        <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}