import React,{useState} from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    MenuItem

} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../layouts/Flash";

import { formatStandardDate} from "../utils/Helper"

export default function CullingRecord(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [culledDate, setCulledDate] = useState(props.calf.calf_culling.culled_date);
    const [culledReason, setCulledReason] = useState(props.calf.calf_culling.culled_reason);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShowEditFormOpen = () => {
        setShowEditForm(true);
    }

    const handleShowEditFormClose = () => {
        setShowEditForm(false);
    }

    const cullingDelete = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/calf_cullings/${props.calf.calf_culling.id}`, {
            method: "DELETE",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const cullingSave = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/calf_cullings/${props.calf.calf_culling.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                calf_culling: {
                    culled_date: culledDate,
                    culled_reason: culledReason,
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Grid container className="the-box">
            <Grid item md={12} xs={12} className="the-box-header">
                <div className="left-item">
                    Cull Record
                </div>
                <div className="right-item">
                    <span >
                        <Link href="#" onClick={handleShowEditFormOpen} className="teeth-white-icon">
                            <CreateIcon fontSize="small" />
                        </Link>
                    </span>

                    <span style={{ marginLeft: "20px" }}>
                        <Link href="#" onClick={handleClickOpen} className="teeth-white-icon">
                            <DeleteIcon fontSize="small" />
                        </Link>
                    </span>

                </div>
            </Grid>
            <Grid item md={12} xs={12} className="box-table-container">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Cull Date</TableCell>
                                <TableCell className="header-cell">Cull Reason</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="data-cell">
                                    {formatStandardDate(props.calf.calf_culling.culled_date)}
                                </TableCell>
                                <TableCell className="data-cell">
                                    {props.calf.calf_culling.formatted_culled_reason}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Un-cull calf {props.calf.ear_tag_id}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={cullingDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div className="delete-modal">
                <Dialog
                    open={showEditForm}
                    onClose={handleShowEditFormClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Grid className="edit-modal" container justify="center">
                        <Grid item md={12}>
                            <Grid container justify="center" className="edit-modal-main-container">
                                <Grid item xs={10} className="edit-modal-form-container">
                                    <div className="header-container">
                                        <span>
                                            Herd {props.herd.herd_id} — Calf {props.calf.ear_tag_id}
                                        </span>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={12} className="form-container">
                                            <span className="secondary-header">
                                                Edit Cull Record
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Cull Date"
                                                value={culledDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setCulledDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Culled Reason"
                                                value={culledReason}
                                                variant="outlined"
                                                placeholder="Search"
                                                size="small"
                                                className="form-field"
                                                onChange={(e) => { setCulledReason(e.target.value) }}
                                                select
                                            >
                                                {
                                                    props.cullReasons.map((cullReason) => (
                                                        < MenuItem key={cullReason[1]} value={cullReason[1]} style={{ padding: "5px" }}>
                                                            {cullReason[1]}: {cullReason[0]}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} className="form-container" style={{marginBottom: "10px"}}>
                                            <Grid container justify="space-between">
                                                <Grid item xs={6} className="cancel-link-container">
                                                    <Link href="#" onClick={handleShowEditFormClose} className="modal-cancel-link">
                                                        Cancel
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={6} className="modal-button-container" >
                                                    <Button onClick={cullingSave} className="modal-yes-button">
                                                        <span className="green-font">Save</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </Grid>
    )
}