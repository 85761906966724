import React, { useState } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogTitle,
}
from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { formatStandardDate } from "../utils/Helper"


export default function Show(props){
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return(
        <div className="main-container herd content-body">
            <Grid container className="grid-container">
                <Grid item xs={12} className="herd-detail-header">
                    <span className="back-arrow"><Link href="/dashboard"><ArrowBackIosIcon /></Link></span> Herd Detail: {props.herd.herd_id} 
                        {
                            <span>
                                {
                                props.herd.too_old_btod && 
                                    <span className="warning-container">
                                        <img src={props.warning_icon} alt="warning" width="25px" style={{ display: "inline-block", verticalAlign: "baseline" }} />
                                        <span className="warning-text">It’s been 330 days since the last BTOD.</span>
                                    </span>
                                }
                            </span>
                        }

                        <span className="edit-herd">
                            <Link className="edit-herd-link" href={`/herd_breeds/${props.herd.id}`}>Edit Herd </Link>
                        </span>
                </Grid>
            </Grid>
            <div className="herd-content-container">
                <Grid container className="grid-container">
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item container md={4} xs={12}>
                                <Grid item xs={12} className="herd-inventory-table">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="left-align">
                                                    Herd inventory
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td onClick={() => { window.location.href = `/herds/${props.herd.id}/cows` }} className="black-font link">
                                                    Cows
                                                </td>
                                                <td onClick={() => { window.location.href = `/herds/${props.herd.id}/cows` }} className="black-font link center-align">
                                                    <b>{props.herd.cows_count}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td onClick={() => { window.location.href = `/herds/${props.herd.id}/calves` }} className="black-font link">
                                                    Calves
                                                </td>
                                                <td onClick={() => { window.location.href = `/herds/${props.herd.id}/calves` }} className="black-font link center-align">
                                                    <b>{props.herd.calves_count}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>TOTAL</b></td>
                                                <td className="center-align"><b>{props.herd.total_animals}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-container btod-table-container">
                            <Grid item md={12}>
                                <TableContainer className="btod-table">
                                    <Table aria-label="customized table">
                                        <TableHead className="animals-table-header">
                                            <TableRow>
                                                <TableCell align="left" style={{ width: "200px" }}>Bull turn out dates </TableCell>
                                                <TableCell align="left">Sire(s)</TableCell>
                                                <TableCell align="left">Notes</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.bullTurnOutDates.map((btod) => {
                                                    return (
                                                        <TableRow key={btod.id}>
                                                            <TableCell align="left">{formatStandardDate(btod.turn_out_date)}</TableCell>
                                                            <TableCell align="left">{btod.sire_ear_tag_ids}</TableCell>
                                                            <TableCell align="left">{btod.notes}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                        {
                                            props.bullTurnOutDates.length == 0 &&
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justify="space-around">
                            <Grid item xs={5}>
                                <Grid container className="buttons-container">
                                    <Grid item xs={12}>
                                        <p className="button-container-header">
                                            Periodic Events
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/herds/${props.herd.id}/animals/new`} className="submit-btn">
                                            <span className="green-font"> <AddIcon className="add-icon" /> ADD COW/CALF TO HERD</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/businesses/${props.herd.business_id}/bulls/new`} className="submit-btn">
                                            <span className="green-font"> <AddIcon className="add-icon" /> ADD BULL</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/herds/${props.herd.id}/transfers/new`} className="submit-btn">
                                            <span className="green-font"> Transfer</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/weight_captures/new?herd_id=${props.herd.id}`} className="submit-btn">
                                            <span className="green-font"> Weight Capture</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" onClick={handleClickOpen} className="submit-btn">
                                            <span className="green-font"> Cull Animals</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container className="buttons-container">
                                    <Grid item xs={12}>
                                        <p className="button-container-header">
                                            Annual Events
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/herds/${props.herd.id}/bull_turn_out_dates/new`} className="submit-btn">
                                            <span className="green-font"> <AddIcon className="add-icon" /> ADD BULL TURN OUT</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/herds/${props.herd.id}/calf_weanings/new`}  className="submit-btn">
                                            <span className="green-font"> Wean Calves</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className="space-below">
                                        <Button variant="contained" href={`/herds/${props.herd.id}/replacements/new`}  className="submit-btn">
                                            <span className="green-font"> Select replacement</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </div>  
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title" style={{textAlign: "center"}}>Cull Animal(s)</DialogTitle>
                    <DialogActions>
                        <Grid container justify="center">
                            <Grid item xs={8} className="modal-button-container" style={{ textAlign: "center" }}>
                                <Button href={`/herds/${props.herd.id}/cow_cullings/new`} className="modal-yes-button">
                                    <span className="green-font">Cull Cow</span>
                                </Button>
                            </Grid>
                            <Grid item xs={8} className="modal-button-container" style={{ marginTop: "15px", textAlign: "center"}}>
                                <Button className="modal-yes-button" href={`/businesses/${props.herd.business_id}/bull_cullings/new`}>
                                    <span className="green-font">Cull Bull</span>
                                </Button>
                            </Grid>
                            <Grid item xs={8} className="modal-button-container" style={{ marginTop: "15px", textAlign: "center" }}>
                                <Button href={`/herds/${props.herd.id}/calf_cullings/new`} className="modal-yes-button">
                                    <span className="green-font">Cull Calf</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}