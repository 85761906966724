import React, { useState } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Radio, 

} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../layouts/Flash";
import { formatStandardDate } from "../utils/Helper"

export default function WeaningRecord(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [weaningDate, setWeaningDate] = useState(props.calf.weaning.weaning_date);
    const [weaningWeight, setWeaningWeight] = useState(props.calf.weaning.weaning_weight || "");
    const [weightType, setWeightType] = useState(props.calf.weaning.weight_type);
    const [hipHeight, setHipHeight] = useState(props.calf.weaning.hip_height || "");
    const [scrotalCircumference, setScrotalCircumference] = useState(props.calf.weaning.scrotalCircumference || "");
    const [weaningNotes, setWeaningNotes] = useState(props.calf.weaning.notes || "")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShowEditFormOpen = () => {
        setShowEditForm(true);
    }

    const handleShowEditFormClose = () => {
        setShowEditForm(false);
    }

    const weaningDelete = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/calf_weanings/${props.calf.weaning.id}`, {
            method: "DELETE",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const weaningSave = () => {
        event.preventDefault();
        if (weightType == "Not Weighed" && weaningWeight != ""){
            setFlashInfo({ flashSeverity: "error", flashMessage: "Not wight weight type cannot have a value."})
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        if (weightType != "Not Weighed" && weaningWeight == "") {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Weight cannot be empty for this weight type." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }
        fetch(`/herds/${props.herd.id}/calf_weanings/${props.calf.weaning.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                calf_weaning: {
                    weaning_date: weaningDate,
                    weaning_weight: weaningWeight,
                    weight_type: weightType,
                    hip_height: hipHeight,
                    scrotal_circumference: scrotalCircumference,
                    notes: weaningNotes
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleWeightTypeChange = (type) => {
        setWeightType(type);
        if (type == "Not Weighed" ){
            setWeaningWeight("");
        }else{
            setWeaningWeight(props.calf.weaning.weaning_weight);
        }
    }

    return (
        <Grid container className="the-box">
            <Grid item md={12} xs={12} className="the-box-header">
                <div className="left-item">
                    Weaning Record
                </div>
                <div className="right-item">
                    <span >
                        <Link onClick={handleShowEditFormOpen} className="teeth-white-icon">
                            <CreateIcon fontSize="small" />
                        </Link>
                    </span>
                    {
                        props.calf.weaning.deleteable && 
                        <span style={{ marginLeft: "20px" }}>
                            <Link onClick={handleClickOpen} href="#" className="teeth-white-icon">
                                <DeleteIcon fontSize="small" />
                            </Link>
                        </span>
                    }
                </div>
            </Grid>
            <Grid item md={12} xs={12} className="box-table-container">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Weight</TableCell>
                                <TableCell className="header-cell">Date</TableCell>
                                <TableCell className="header-cell">Weight Type</TableCell>
                                <TableCell className="header-cell">Hip Height</TableCell>
                                {
                                    props.calf.sex == "Bull" &&
                                        < TableCell className="header-cell">Scrotal Circumference</TableCell>
                                }
                                <TableCell className="header-cell">Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="data-cell">
                                    {props.calf.weaning.weaning_weight && `${props.calf.weaning.weaning_weight} lbs.`}
                                </TableCell>
                                <TableCell className="data-cell">{formatStandardDate(props.calf.weaning.weaning_date)}</TableCell>
                                <TableCell className="data-cell">{props.calf.weaning.weight_type}</TableCell>
                                <TableCell className="data-cell">
                                    {
                                        props.calf.weaning.hip_height &&
                                        <span>
                                            {props.calf.weaning.hip_height} inches
                                        </span>
                                    }
                                </TableCell>
                                {
                                    props.calf.sex == "Bull" &&
                                        <TableCell className="data-cell">
                                            {
                                                props.calf.weaning.scrotal_circumference &&
                                                <span>
                                                    {props.calf.weaning.scrotal_circumference} cm
                                                </span>
                                            }
                                        </TableCell>
                                }
                                <TableCell className="data-cell">{props.calf.weaning.notes}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Delete weaning for calf {props.calf.ear_tag_id}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={weaningDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>

            <div className="delete-modal">
                <Dialog
                    open={showEditForm}
                    onClose={handleShowEditFormClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Grid className="edit-modal" container justify="center">
                        <Grid item md={12}>
                            <Grid container justify="center" className="edit-modal-main-container">
                                <Grid item xs={10} className="edit-modal-form-container">
                                    <div className="header-container">
                                        <span>
                                            Herd {props.herd.herd_id} — Calf {props.calf.ear_tag_id}
                                        </span>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={12} className="form-container">
                                            <span className="secondary-header">
                                                Edit Weaning Record
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Weaning Date"
                                                value={weaningDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                onChange={(e) => setWeaningDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Weaning Weight (lbs.)"
                                                value={weaningWeight}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                className="form-field"
                                                required
                                                onChange={(e) => setWeaningWeight(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <span>
                                                <Radio
                                                    checked={weightType == "Actual"}
                                                    onChange={(e) => setWeightType(e.target.value)}
                                                    value="Actual"
                                                /> Actual
                                            </span>
                                            <span>
                                                <Radio
                                                    checked={weightType == "Not Weighed"}
                                                    onChange={(e) => handleWeightTypeChange(e.target.value)}
                                                    value="Not Weighed"
                                                /> Not Weighed
                                            </span>
                                            <span>
                                                <Radio
                                                    checked={weightType == "Pen Average"}
                                                    onChange={(e) => setWeightType(e.target.value)}
                                                    value="Pen Average"
                                                /> Pen Average
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Hip Height (inches)"
                                                value={hipHeight}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                onChange={(e) => setHipHeight(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} className="form-container">
                                            {
                                                props.calf.sex == "Bull" &&
                                                    <TextField
                                                        fullWidth
                                                        label="Scrotal Circumference (cm)"
                                                        value={scrotalCircumference}
                                                        variant="outlined"
                                                        size="small"
                                                        className="form-field"
                                                        onChange={(e) => setScrotalCircumference(e.target.value)}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <TextField
                                                fullWidth
                                                label="Notes"
                                                value={weaningNotes}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                multiline
                                                rows={4}
                                                onChange={(e) => setWeaningNotes(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="form-container">
                                            <Grid container justify="space-between">
                                                <Grid item xs={6} className="cancel-link-container">
                                                    <Link href="#" onClick={handleShowEditFormClose} className="modal-cancel-link">
                                                        Cancel
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={6} className="modal-button-container" >
                                                    <Button onClick={weaningSave} className="modal-yes-button">
                                                        <span className="green-font">Save</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </Grid>
    )
}