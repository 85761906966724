import React from 'react';
import {
    Grid,
    Button,
    Link,
    TextField,
    Radio,
    FormLabel
}
    from '@material-ui/core';

export default function BullWeightFrom(props) {
    return (
        <Grid container >
            <Grid item md={12}>
                <form onSubmit={props.submitForm}>
                    <Grid container justify="space-around">
                        <Grid item xs={5} >
                            <TextField
                                fullWidth
                                label="Date"
                                value={props.date}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                type="date"
                                required
                                onChange={(e) => props.setDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-around">
                        <Grid item xs={5} >
                            <TextField
                                fullWidth
                                label="Weight (lbs.)"
                                value={props.weight}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                required
                                onChange={(e) => props.setWeight(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>
                                <legend className="custom-radio-label">
                                    Weight Type
                                </legend>
                            </FormLabel>
                            <div>
                                <span>
                                    <Radio
                                        checked={props.weightType == "Actual"}
                                        onChange={(e) => { props.setWeightType(e.target.value) }}
                                        value="Actual"
                                    /> Actual
                                </span>
                                <span>
                                    <Radio
                                        checked={props.weightType == "Estimated"}
                                        onChange={(e) => { props.setWeightType(e.target.value) }}
                                        value="Estimated"
                                    /> Estimated
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end">
                            <Link href={`/herds/${props.herd.id}`} className="grey-font">
                                Cancel
                            </Link>
                            <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                <span className="green-font">SAVE</span>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>            
        </Grid>
    )
}