import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button
}
    from '@material-ui/core';
import SummaryOfCowsTable from './lifetime_progeny_reports/SummaryOfCowsTable';

export default function CowLifetimeProgeny(props) {
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);
    const [asOfDate, setAsOfDate] = useState(props.as_of_date);
    const [status, setStatus] = useState(props.status);
    const statuses = ["All", "Active", "Culled"];

    const rerunReport = () => {
        window.location.href = `/cow_lifetime_progeny/${selectedHerdId}?as_of_date=${asOfDate}&status=${status}`
    }

    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Cow Lifetime Progeny Report</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.business_name}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.formatted_address}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Herd/Breeding Group: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedHerdId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setSelectedHerdId(e.target.value)}
                                        >
                                            {
                                                props.herds.map((herd) => (
                                                    < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                        {herd.herd_id}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>As of Date: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            value={asOfDate}
                                            style={{ width: "200px" }}
                                            variant="standard"
                                            size="small"
                                            type="date"
                                            required
                                            onChange={(e) => setAsOfDate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Status: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={status}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            {
                                                statuses.map((status, index) => (
                                                    < MenuItem key={index} value={status} style={{ padding: "5px" }}>
                                                        {status}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <Button className="btn" onClick={()=> rerunReport()}>
                                            Rerun Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12} xs={12} className="row">
                    <SummaryOfCowsTable 
                        herd={props.herd}
                        as_of_date={props.as_of_date}
                        status={props.status}
                    />
                </Grid>
            </Grid>
        </div>
    )
}