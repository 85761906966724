import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
}
    from '@material-ui/core';
import Pagination from '../../shared/Pagination';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../../layouts/Flash";

export default function Index(props) {
    const [producerInvites, setProducerInvites] = useState(props.producerInvites);
    const [searchValue, setSearchValue] = useState("");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(producerInvites.length / perPage));

    //flash states
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value.toLowerCase();
        const filteredBusinesses = props.producerInvites.filter((producerInvite) => producerInvite.first_name.toLowerCase().includes(searchParam) || producerInvite.last_name.toLowerCase().includes(searchParam) || producerInvite.email.toLowerCase().includes(searchParam));
        setSearchValue(searchParam);
        setProducerInvites(filteredBusinesses);
    }

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.producerInvites];
            setProducerInvites(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const deleteProducerInvite = (producerInviteId) => {
        event.preventDefault();
        if (confirm("Are you sure?")) {
            fetch(`/admin_users/producer_invites/${producerInviteId}`, {
                method: "DELETE",
                credentials: "same-origin",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    "X-CSRF-Token": props.authenticity_token,
                },
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.success) {
                        window.location.href = `/admin_users/producer_invites`
                    } else {
                        setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    const resendProducerInvite = (producerId) => {
        event.preventDefault();
        fetch(`/admin_users/resend_producer_invite/${producerId}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    setFlashInfo({ flashSeverity: "success", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className="admin-main-container">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item xs={3}>
                            <p className="header-text">Producer Invites</p>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                value={searchValue}
                                variant="outlined"
                                placeholder="Search"
                                size="small"
                                className="search-form-field"
                                onChange={(e) => { search(e) }}
                            />
                        </Grid>
                        <Grid item xs={3} className="invite-user-btn-container">
                            <Grid container justify="flex-end">
                                <Grid item >
                                    <Button href={`/admin_users/producer_invites/new`} className="invite-user-btn">
                                        Invite
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className="table-container">
                        <Table className="table">
                            <TableHead className="table-head">
                                <TableRow className="table-head-row">
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell>
                                        Email
                                    </TableCell>
                                    <TableCell>
                                        Sent
                                    </TableCell>
                                    <TableCell>
                                        Valid Until
                                    </TableCell>
                                    <TableCell>
                                        Invitation
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    producerInvites.map((producerInvite) => (
                                        <TableRow key={producerInvite.id} className="data-row">
                                            <TableCell className="table-cell">
                                                {producerInvite.first_name} {producerInvite.last_name}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {producerInvite.invitation_status}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {producerInvite.email}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {producerInvite.invitation_sent_at}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {producerInvite.valid_until}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Button onClick={() => resendProducerInvite(producerInvite.id)}   className="become-user-btn">
                                                    Resend Invite
                                                </Button>
                                                <CreateIcon onClick={() => { window.location.href = `/admin_users/producer_invites/${producerInvite.id}/edit` }} className="edit-and-del-icons" />
                                                {
                                                    producerInvite.invitation_status != "Confirmed" && 
                                                    <DeleteIcon onClick={() => deleteProducerInvite(producerInvite.id)} className="edit-and-del-icons" />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Pagination
                offset={offset}
                totalPages={totalPages}
                handlePaginationPageChange={handlePaginationPageChange} />
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}