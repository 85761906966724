import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
}
    from '@material-ui/core';
import Pagination from '../../shared/Pagination';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../../layouts/Flash";

export default function Index(props) {
    const [adminUsers, setAdminUsers] = useState(props.adminUsers);
    const [searchValue, setSearchValue] = useState("");

    //pagination states
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(adminUsers.length / perPage));

    //flash states
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value.toLowerCase();
        const filteredAdminUsers = props.adminUsers.filter((adminUser) => adminUser.full_name.toLowerCase().includes(searchParam) || adminUser.email.toLowerCase().includes(searchParam));
        setSearchValue(searchParam);
        setAdminUsers(filteredAdminUsers);
    }

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.adminUsers];
            setAdminUsers(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const deleteAdminUser = (adminUserID) => {
        event.preventDefault();
        if (confirm("Are you sure?")) {
            fetch(`/admin_users/admin_users/${adminUserID}`, {
                method: "DELETE",
                credentials: "same-origin",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    "X-CSRF-Token": props.authenticity_token,
                },
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.success) {
                        window.location.href = `/admin_users/admin_users`
                    } else {
                        setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                        setShowFlash(true);
                        setTimeout(() => { setShowFlash(false) }, 6000);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    const resendInvite = (adminUserId) => {
        event.preventDefault();
        fetch(`/admin_users/resend_invite/${adminUserId}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    setFlashInfo({ flashSeverity: "success", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className="admin-main-container">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item xs={3}>
                            <p className="header-text">Admin Users</p>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                value={searchValue}
                                variant="outlined"
                                placeholder="Search"
                                size="small"
                                className="search-form-field"
                                onChange={(e) => { search(e) }}
                            />
                        </Grid>
                        <Grid item xs={3} className="invite-user-btn-container">
                            <Grid container justify="flex-end">
                                <Grid item >
                                    <Button href={`/admin_users/admin_users/new`} className="invite-user-btn">
                                        Invite
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className="table-container">
                        <Table className="table">
                            <TableHead className="table-head2">
                                <TableRow className="table-head-row">
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell>
                                        Email
                                    </TableCell>
                                    <TableCell>
                                        Sent
                                    </TableCell>
                                    <TableCell>
                                        Invitation
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    adminUsers.map((adminUser) => (
                                        <TableRow key={adminUser.id} className="data-row">
                                            <TableCell className="table-cell">
                                                {adminUser.full_name}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {adminUser.invitation_status}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {adminUser.email}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {adminUser.formatted_invitation_sent_at}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Button onClick={() => resendInvite(adminUser.id)}  className="become-user-btn">
                                                    Resend Invite
                                                </Button>
                                                <CreateIcon onClick={() => { window.location.href = `/admin_users/admin_users/${adminUser.id}/edit`}} className="edit-and-del-icons" />
                                                <DeleteIcon onClick={() => deleteAdminUser(adminUser.id)} className="edit-and-del-icons" />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Pagination
                offset={offset}
                totalPages={totalPages}
                handlePaginationPageChange={handlePaginationPageChange} />
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}