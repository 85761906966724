import React from 'react';
import {
    Grid,
    TextField,
}
    from '@material-ui/core';

export default function ProducerInviteForm(props) {
    return (

        <Grid item xs={8}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="First Name"
                        value={props.firstName}
                        variant="outlined"
                        size="small"
                        className="search-form-field"
                        required
                        onChange={(e) => { props.setFirstName(e.target.value) }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Last Name"
                        value={props.lastName}
                        variant="outlined"
                        size="small"
                        className="search-form-field"
                        required
                        onChange={(e) => { props.setLastName(e.target.value) }}
                    />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={props.email}
                        variant="outlined"
                        size="small"
                        className="search-form-field"
                        required
                        onChange={(e) => { props.setEmail(e.target.value) }}
                    />
                </Grid>
            </Grid>
            {
                props.tbybEnvironment && 

                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Valid Until"
                            value={props.validUntil}
                            variant="outlined"
                            size="small"
                            className="search-form-field"
                            type="date"
                            required
                            onChange={(e) => props.setValidUntil(e.target.value)}
                        />
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}