import React, { useState, useEffect } from 'react';
import { formatStandardDate } from "../../utils/Helper";

export default function SummaryOfCowsLeavingTable(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_cows_leaving/?herd_id=${props.herd.id}&btod_id=${props.btod.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                : 
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <td colSpan={2} className="data-col yellow-border yellow-border-top">
                                    Summary of Cows Leaving Since {formatStandardDate(props.btod?.turn_out_date)}
                                </td>
                            </tr>
                            <tr className="secondary-header">
                                <td className="label-col  darker-right">
                                    Culled Cows Since {formatStandardDate(props.btod?.turn_out_date)}
                                </td>
                                <td className="data-col">
                                    Count
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Died
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_died}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold Due to Old Age
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_age}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold Due to Physical Defects
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_physical_defect}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold Due to Poor Fertility or Open
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_poor_fertility}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold Due to Inferior Calves
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_inferior_calved}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold Due to Replacement of Stock
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_replacement_stock}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="label-col darker-right">
                                    Cows Sold for Unknown Reason
                                </td>
                                <td className="data-col center-text">
                                    {data.cow_sold_unknown_reason}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }
        </>
    )
}