import React from 'react'
import Flash from "../layouts/Flash";
import UserInformationForm from "./UserInformationForm";
import BusinessInformationForm from "./BusinessInformationForm"
import { 
    Divider,
    Typography,
    Grid,
} from "@material-ui/core";

class New extends React.Component{

    constructor(props){
        super(props);
        let phoneNumberFields = [{ phone_number: null, phone_type: "Mobile" }];
        if (props.default_phone_numbers){
            phoneNumberFields = props.default_phone_numbers
        }

        this.state = {
            phoneNumberFields: phoneNumberFields,
            country: "United States",
            state: props.default_business_params?.state ||"AL"
        }
    }

    render(){
        return(
            <div className="pages-background2 new-user main-container">
                <form action="/users" method="post" >
                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                    <Grid container justify="flex-start">
                        <Grid item md={7} xs={12} className="white-background">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className="ml-30" gutterBottom>
                                        <b>Create your account</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <UserInformationForm default_user_params={this.props.default_user_params} />
                                        <Divider orientation="vertical" flexItem  className="user-vertical-divider"/>
                                        <BusinessInformationForm default_phone_numbers={this.props.default_phone_numbers} default_business_params={this.props.default_business_params} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Flash severity={this.props.flash_severity} message={this.props.flash_message} />
            </div>
        )
    }
}
export default New