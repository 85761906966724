import React,{useEffect} from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Typography
}
from '@material-ui/core';

export default function BullForm(props) {

    useEffect(() => {
        if (props.sireId && props.damId) {
            const sireBreedCode = props.bulls.find(bull => bull.id == props.sireId)?.breed;
            const damBreedCode = props.cows.find(cow => cow.id == props.damId)?.breed;
            props.setProviderPreferredBreeds([...props.providerPreferredBreeds, `${sireBreedCode}/${damBreedCode}`]);
            props.setBreed(`${sireBreedCode}/${damBreedCode}`)
        }
    }, [props.sireId, props.damId]);
    return(
        <Grid container>
            <Grid item md={6} xs={12} className="animal-form-column">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Sire ID"
                            value={props.earTagId}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            required
                            onChange={(e) => props.setEarTagId(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Sire Birth Date"
                            value={props.birthDate}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            type="date"
                            required
                            onChange={(e) => props.setBirthDate(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            select
                            label="Sire Breed"
                            value={props.breed}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            required
                            onChange={(e) => props.setBreed(e.target.value)}
                        >
                            {
                                props.providerPreferredBreeds.map((breed, index) => (
                                    < MenuItem key={index} value={breed} style={{ padding: "5px" }}>
                                        {breed}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                        <Typography variant="caption" display="block" gutterBottom>
                            *Breed is dependent on Sire and Dam breed, if known
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Registration Number"
                            value={props.registrationNumber}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            onChange={(e) => props.setRegistrationNumber(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Registration Name"
                            value={props.registrationName}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            onChange={(e) => props.setRegistrationName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Electronic ID Number"
                            value={props.electronicId}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            type="number"
                            step="1"
                            onChange={(e) => props.setElectronicId(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12} className="animal-form-column">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            select
                            label="Sire of Sire"
                            value={props.sireId}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            onChange={(e) => props.setSireId(e.target.value)}
                        >
                            {
                                props.bulls.map((bull) => (
                                    < MenuItem key={bull.id} value={bull.id} style={{ padding: "5px" }}>
                                        {bull.ear_tag_id}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            select
                            label="Dam of Sire"
                            value={props.damId}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            onChange={(e) => props.setDamId(e.target.value)}
                        >
                            {
                                props.cows.map((cow) => (
                                    < MenuItem key={cow.id} value={cow.id} style={{ padding: "5px" }}>
                                        {cow.ear_tag_id}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Source"
                            select
                            value={props.source}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            required
                            onChange={(e) => props.setSource(e.target.value)}
                        >
                            {
                                props.sourceOptions.map((sourceOption, index) => (
                                    < MenuItem key={index} value={sourceOption} style={{ padding: "5px" }}>
                                        {sourceOption}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Use"
                            select
                            value={props.use}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            required
                            onChange={(e) => props.setUse(e.target.value)}
                        >
                            {
                                props.useOptions.map((useOption, index) => (
                                    < MenuItem key={index} value={useOption} style={{ padding: "5px" }}>
                                        {useOption}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Status"
                            value={props.status}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            disabled
                            onChange={(e) => props.setStatus(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12} xs={12} className="animal-form-column">
                    <Grid container>
                        <TextField
                            fullWidth
                            label="Notes"
                            multiline
                            rows={4}
                            value={props.notes}
                            variant="outlined"
                            size="small"
                            className="form-field"
                            onChange={(e) => props.setNotes(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}