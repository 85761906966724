import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem,
    Checkbox
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '../shared/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import { getTodayDate, calculateAge } from '../utils/Helper';
import Flash from "../layouts/Flash";

export default function New(props) {
    const [cullDate, setCullDate] = useState(getTodayDate);
    const [cullReason, setCullReason] = useState("H");
    const [selectedCows, setSelectedCows] = useState([]);

    const [cows, setCows] = useState(props.cows);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState({ sortBy: "cowId", order: "ascending" });

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(cows.length / perPage));

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value;
        const filteredCows = props.cows.filter((cow) => cow.ear_tag_id.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1);
        setSearchValue(searchParam);
        setCows(filteredCows);
    }

    const sortCows = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    useEffect(() => {
        const arrayCows = [...cows];
        if (sortValue.sortBy == "cowId") {
            arrayCows.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "age") {
            arrayCows.sort((a, b) => (a.birth_date < b.birth_date ? 1 : -1));
        }

        if (sortValue.sortBy == "sire") {
            arrayCows.sort((a, b) => (a.sire_ear_tag_id > b.sire_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "dam") {
            arrayCows.sort((a, b) => (a.dam_ear_tag_id > b.dam_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "breed") {
            arrayCows.sort((a, b) => (a.breed > b.breed ? 1 : -1));
        }

        if (sortValue.order == "ascending") {
            setCows(arrayCows);
        } else {
            setCows(arrayCows.reverse());
        }
    }, [sortValue]);

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.cows];
            setCows(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const handleCheckboxClick = (cowId) => {
        if (selectedCow(cowId)) {
            const restOfCowIds = selectedCows.filter((id) => id != cowId);
            setSelectedCows(restOfCowIds);
        } else {
            setSelectedCows([...selectedCows, cowId])
        }
    }

    const selectedCow = (cowId) => {
        return selectedCows.findIndex((id) => id == cowId) != -1;
    }

    const submitForm = () => {
        event.preventDefault();

        if (selectedCows.length == 0) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select a cow." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        fetch(`/herds/${props.herd.id}/cow_cullings`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                cow_culling: {
                    culled_date: cullDate,
                    culled_reason: cullReason,
                    animal_ids: selectedCows,
                    animal_type: "Cow"
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/herds/${props.herd.id}/cows`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });

    }

    return (
        <div className="main-container herd cow content-body">
            <Grid container item xs={12} className="cow-container">
                <Grid container >
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/herds/${props.herd.id}/cows`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Cows
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { search(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <form onSubmit={submitForm}>
                                    <Grid container justify="space-between">
                                        <div>
                                            <p>Select Animals to Cull</p>
                                        </div>
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Culled Date"
                                                value={cullDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setCullDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Culled Reason"
                                                value={cullReason}
                                                variant="outlined"
                                                placeholder="Search"
                                                size="small"
                                                className="form-field"
                                                onChange={(e) => { setCullReason(e.target.value) }}
                                                select
                                            >
                                                {
                                                    props.cullReasons.map((cullReason) => (
                                                        < MenuItem key={cullReason[1]} value={cullReason[1]} style={{ padding: "5px" }}>
                                                            {cullReason[1]}: {cullReason[0]}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Grid container justify="flex-end" >
                                                <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end" style={{ paddingRight: "0", marginRight: "0" }}>
                                                    <Link href={`/herds/${props.herd.id}/cows`} className="grey-font">
                                                        Cancel
                                                    </Link>
                                                    <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                        <span className="green-font">SAVE</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <TableContainer className="cull-animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" ><CheckIcon fontSize="large" align="center" /></TableCell>
                                        <TableCell align="center" onClick={() => sortCows("cowId", sortValue.order == "ascending" ? "descending" : "ascending")}>Cow ID
                                            {
                                                sortValue.sortBy == "cowId" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "cowId" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("age", sortValue.order == "ascending" ? "descending" : "ascending")}>Age
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("sire", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire of Cow
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("dam", sortValue.order == "ascending" ? "descending" : "ascending")}>Dam of Cow
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>Breed
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Source</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Culled Date</TableCell>
                                        <TableCell align="center">Culled Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        cows.map((cow) => {
                                            return (
                                                <TableRow key={cow.id}>
                                                    <TableCell align="center" className="check-box">
                                                        <Checkbox
                                                            checked={selectedCow(cow.id)}
                                                            onChange={() => handleCheckboxClick(cow.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/herds/${props.herd.id}/cows/${cow.id}` }} className="black-font link" >
                                                        {cow.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calculateAge(cow.birth_date)}</TableCell>
                                                    <TableCell align="center">{cow.sire_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{cow.dam_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{cow.breed}</TableCell>
                                                    <TableCell align="center">{cow.source}</TableCell>
                                                    <TableCell align="center">{cow.status}</TableCell>
                                                    <TableCell align="center">{cow.culled_date}</TableCell>
                                                    <TableCell align="center">{cow.formatted_culled_reason}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}