import React, { useState } from 'react';
import {
    Grid,
    Button,
    Link
}
    from '@material-ui/core';
import Flash from "../../layouts/Flash";
import AdminInviteForm from './AdminInviteForm';

export default function Edit(props) {
    const [firstName, setFirstName] = useState(props.adminUser.first_name);
    const [lastName, setLastName] = useState(props.adminUser.last_name);
    const [email, setEmail] = useState(props.adminUser.email);

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const submitForm = () => {
        event.preventDefault();
        fetch(`/admin_users/admin_users/${props.adminUser.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                admin_user: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/admin_users/admin_users`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <div className="admin-main-container invite-admin">
            <form onSubmit={submitForm}>
                <Grid container justify="center" >
                    <Grid item xs={4} className="invite-admin-form-container">
                        <Grid container justify="center">
                            <Grid item xs={12} className="admin-invite-header yellow-background-header">
                                Update Admin Information
                            </Grid>
                            <AdminInviteForm
                                firstName={firstName}
                                setFirstName={setFirstName}
                                lastName={lastName}
                                setLastName={setLastName}
                                email={email}
                                setEmail={setEmail}
                            />
                            <Grid item xs={12} className="invite-button-container">
                                <Link href={`/admin_users/admin_users`} className="cancel-link">
                                    Cancel
                                </Link>
                                <Button variant="contained" type="submit" className="submit-btn">
                                    <span className="green-font">SAVE</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}