import React from 'react';
import {
    Typography,
    Grid,
    Link,
    Button,
    List,
    Checkbox,
    ListItem,
    ListItemText,
}
    from '@material-ui/core';

export default function New(props) {
    const handleCancel = () => {
        //handling single breed calcel still save the checked item when the single breed form is open next time
        //the saved item wont be converted into finalselected breeds
        props.setSelectedSingleBreedList([...props.finalSingleBreedList])
        props.setShowSingleBreedsForm(false);
    }

    const handleToggle = (breed) => () => {
        //handles check and uncheck of singleBreed checklist
        if (includesSingleBreed(breed)) {
            const newSelectedSingleBreedList = props.selectedSingleBreedList.filter((item) => item.id != breed.id);
            props.setSelectedSingleBreedList(newSelectedSingleBreedList);
        } else {
            props.setSelectedSingleBreedList([...props.selectedSingleBreedList, breed]);
        }
    }

    const includesSingleBreed = (breed) => {
        return props.selectedSingleBreedList.findIndex(selectedBreed => selectedBreed.id == breed.id) != -1
    }
    return(
        <Grid item md={8} xs={12} className="location-form-container">
            <Grid container>
                <Grid item xs={12} className="location-form-header-container">
                    <Typography variant="h6" className="location-form-header" gutterBottom>
                        Add breed(s)
                    </Typography>
                </Grid>
                <Grid container className="breed-options">
                    <Grid item xs={12}>
                        <p className="content-container">
                            Select breed(s) for Herd <b>{props.herd.herd_id}</b>. These will be the basis for <b>single and crossbreed</b> options.
                        </p>
                    </Grid>
                    <Grid item container xs={12} className="content-container">
                        <Grid item xs={2} >
                            <Link className="green-font underline-el" href="#" onClick={props.filterKeys("AG")} >
                                <b>A—G</b>
                            </Link>
                        </Grid>
                        <Grid item xs={2}>
                            <Link className="green-font underline-el" href="#" onClick={props.filterKeys("HP")} >
                                <b>H—P</b>
                            </Link>
                        </Grid>
                        <Grid item xs={2}>
                            <Link className="green-font underline-el" href="#" onClick={props.filterKeys("RZ")}>
                                <b>R—Z</b>
                            </Link>
                        </Grid>
                        <Grid item xs={2}>
                            <Link className="green-font underline-el" href="#" onClick={props.filterKeys("all")} >
                                <b>Show All</b>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    {
                                        props.allBreeds.map((breeds, index) => {
                                            return (
                                                <Grid key={index} item md={4} xs={12} className="nml-5">
                                                    <List>
                                                        {
                                                            breeds.map((breed) => {
                                                                return (
                                                                    <ListItem key={breed.id} role={undefined} dense button onClick={handleToggle(breed)}>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={includesSingleBreed(breed)}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                        />
                                                                        <ListItemText id={breed.id} >
                                                                            <b>{breed.breed_code}</b>  {breed.breed_name}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="actions">
                        <Grid container >
                            <Grid item xs={12} container alignItems="center" justify="flex-end">
                                <Link href="#" className="grey-font" onClick={handleCancel} >
                                    Cancel
                                </Link>
                                <Button variant="contained" className="submit-btn" onClick={props.updateFinalBreedList}>
                                    <span className="green-font">SAVE</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}