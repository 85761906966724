import React, { useState } from 'react';
import { twoDecimalFormat, formatStandardDate } from '../../utils/Helper';
export default function SummaryOfCalves(props) {
    const [calves] = useState(props.calves);

    return (
        <>
            <tr className="primary-header">
                <td colSpan={5} style={{ backgroundColor: "white" }}></td>
                <td colSpan={6} className="label-col darker-right"> Calf Birth Data</td>
                <td colSpan={8} className="label-col"> Calf Weaning Data</td>
            </tr>
            <tr className="secondary-header" style={{ border: "0px" }}>
                <td colSpan={5} style={{ backgroundColor: "white" }}></td>
                <td className="label-col thin-border">
                    Calf ID
                </td>
                <td className="label-col thin-border">
                    Date
                </td>
                <td className="label-col thin-border">
                    Sex
                </td>
                <td className="label-col thin-border">
                    Circumstance
                </td>
                <td className="label-col thin-border">
                    Calving Interval
                </td>
                <td className="label-col thin-border darker-right">
                    Calving Dist
                </td>
                <td className="label-col thin-border">
                    Birth Weight(lb)
                </td>
                <td className="label-col thin-border">
                    Wean Weight(lb)
                </td>
                <td className="label-col thin-border">
                    Age (Days)
                </td>
                <td className="label-col thin-border">
                    ADJ 205(lb)
                </td>
                <td className="label-col thin-border">
                    ADJ 205 Ratio
                </td>
                <td className="label-col thin-border">
                    Frame Score
                </td>
                <td className="label-col thin-border">
                    ADG (lb)
                </td>
                <td className="label-col thin-border">
                    WDA (lb)
                </td>
            </tr>
            {
                calves.length > 0 && 
                    calves.map((calf) => {
                        return(
                            <tr key={calf.id}>
                                <td colSpan={5}></td>
                                <td className="label-col thin-border">
                                    {calf.ear_tag_id}
                                </td>
                                <td className="label-col thin-border">
                                    {formatStandardDate(calf.birth_date)}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.sex}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.birth_circumstance}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.calving_interval_days}
                                </td>
                                <td className="label-col darker-right thin-border">
                                    {calf.calving_distribution}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.birth_weight}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.weaning?.weaning_weight}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.weaning?.weaning_age_in_days}
                                </td>
                                <td className="label-col thin-border">
                                    {calf.weaning?.adjusted_205}
                                </td>
                                <td className="label-col thin-border">
                                    {twoDecimalFormat(calf.weaning?.adjusted_205_ratio)}
                                </td>
                                <td className="label-col thin-border">
                                    {twoDecimalFormat(calf.weaning?.frame_score)}
                                </td>
                                <td className="label-col thin-border">
                                    {twoDecimalFormat(calf.weaning?.adg)}
                                </td>
                                <td className="label-col thin-border">
                                    {twoDecimalFormat(calf.weaning?.wda)}
                                </td>
                            </tr>
                        )
                    })
            }
        </>
    )
}