import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


export default function Footer(props){
    return(
        <div className="footer">
            <Grid container className="primary-footer-container">
                <Grid item md={5} xs={12} className="NDSU-extension-container">
                    <Typography variant="body1" style={{display:'flex', alignItems: "center"}} gutterBottom className="white-font">
                        Site developed and maintained by <img src={props.footer_ndsu_extension_white}  alt="NDSU Extension White" style={{display: "inline-block", padding: "5px"}} />
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12} container justify="flex-end" alignItems="center" >
                    <Grid container justify="flex-end" >
                        
                        <Grid container justify="center" item md={3} xs={12} >
                            <a
                                href="https://www.facebook.com/NDSUextlivestock"
                                target="_blank" rel="noreferrer"
                            >
                                <img src={props.footer_facebook} alt="Facebook" width="33px" />
                            </a>
                        </Grid>
                        <Grid container justify="center" item md={3} xs={12} style={{marginTop: "7px"}}>
                            <a
                                href="https://www.youtube.com/user/NDSUExtension/videos"
                                target="_blank" rel="noreferrer"
                            >
                                <img src={props.footer_youtube} alt="Youtube" width="33px" />
                            </a>
                        </Grid>
                        <Grid container justify="center" item md={3} xs={12} style={{ marginTop: "10px" }}>
                            <a
                                href="/contact_us"
                                style={{
                                    textDecoration: "none",
                                    color: "white"
                                }}
                            >
                                Contact
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />

            <Grid container className="footer-seconday-content">
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom className="white-font footer-copy">
                        ©2021 North Dakota State University, an equal opportunity, affirmative action institution.
                        </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom className="white-font footer-copy">
                        Contact NDSU Agricultural Affairs at (701) 231-7655 | Privacy Statement | <span><a
                            href="https://www.ndsu.edu/equity/about/required_non_discrimination_statement/"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                color: "white"
                            }}
                        >
                            Non-Discrimination Statement
                        </a></span> 
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
