import React, { useState, useEffect } from 'react';

export default function SummaryOfOpenCowsTable(props) {
    const [data, setData] = useState(null);
    const [chunkyData, setChunkyData] = useState(null);
    const [defaultColSpan, setDefaultColSpan] = useState(16);
    
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_open_cows/?herd_id=${props.herd.id}&btod_id=${props.btod.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if(data){
            const chunks = sliceIntoChunks(data);
            if (data?.length < 8) {
                setDefaultColSpan(data.length * 2);
            }
            setChunkyData(chunks);
        }
    }, [data]);

    const sliceIntoChunks = (arr) => {
        const chunkSize = 8;
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    return (
        <>
            {
                chunkyData == null ?
                    <p>
                        Loading
                    </p>
                :
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <th colSpan={defaultColSpan} className="label-col">
                                    Cows Yet to Calve or Open <br />
                                    (Out of cows kept for calving)
                                </th>
                            </tr>
                            <tr className="secondary-header">
                                    {
                                        chunkyData[0]?.map((data, index) => {
                                            return(
                                                <>
                                                    <th key={`${index}-cow-id`} className="label-col thin-border">
                                                        Cow ID
                                                    </th>
                                                    <th key={`${index}-age`} className="label-col thin-border">
                                                        Age(yrs)
                                                    </th>
                                                </>
                                            )
                                        })
                                    }  
                            </tr>
                        </thead>
                        <tbody>
                            {
                                chunkyData.map((rowArray, index)=>{
                                    return(
                                            <tr key={index} className="thin-border">
                                                { 
                                                    rowArray.map((cow) => {
                                                        return(
                                                            <>
                                                                <td key={cow.ear_tag_id} className="data-col thin-border">
                                                                    {cow?.ear_tag_id}
                                                                </td>
                                                                <td key={`${cow.ear_tag_id}-age`} className="data-col thin-border">
                                                                    {cow.age_in_btod}
                                                                </td>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    } 
                                )
                            }
                        </tbody>
                    </table>
            }
        </>
    )
}