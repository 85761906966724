import React from 'react'
import states from "../utils/states";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InputMask from 'react-input-mask';
import {
    MenuItem,
    Link,
    Typography,
    Grid,
    Button,
    TextField
} from "@material-ui/core";

class BusinessInformationForm extends React.Component{
    constructor(props){
        super(props);
        let phoneNumberFields = [{ phone_number: null, phone_type: "Mobile" }];
        if (props.default_phone_numbers) {
            phoneNumberFields = props.default_phone_numbers
        }

        this.state = {
            phoneNumberFields: phoneNumberFields,
            country: "United States",
            state: props.default_business_params?.state || "AL"
        }
    }

    handleAddInputFiled = (event) => {
        this.setState({
            phoneNumberFields: this.state.phoneNumberFields.concat({ phone_number: null, phone_type: "Mobile" })
        })
    }

    handleRemoveInputFiled = (event) => {
        let oldPhoneNumberFieldList = [...this.state.phoneNumberFields];
        oldPhoneNumberFieldList.splice(-1, 1);
        this.setState({
            phoneNumberFields: oldPhoneNumberFieldList
        })
    }

    handleCountryChange = (event) => {
        this.setState({
            country: event.target.value
        })
    }

    handleStateChange = (event) => {
        this.setState({
            state: event.target.value
        })
    }

    render(){
        const countries = ["United States"];
        const phoneNumberTypes = ["Mobile", "Land Line"];
        return(
            <Grid item md={5} xs={12} className="form-panel">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="ml-10" gutterBottom>
                            Business information:
                        </Typography>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Business Name"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.business_name}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[business_name]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address 1"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.address_1}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[address_1]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address 2"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.address_2}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[address_2]"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="City"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.city}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[city]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label="State"
                                id="outlined-size-small"
                                value={this.state.state}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[state]"
                                onClick={this.handleStateChange}
                                required
                            >
                                {
                                    states.map((state) => (
                                        <MenuItem key={state} value={state} style={{ padding: "5px" }}>
                                            {state}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>

                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Zip"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.zip}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[zip]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label="Country"
                                id="outlined-size-small"
                                value={this.state.country}
                                onChange={this.handleCountryChange}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[country]"
                                required
                            >
                                {
                                    countries.map((country) => (
                                        <MenuItem key={country} value={country} style={{ padding: "5px" }}>
                                            {country}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>

                    {
                        this.state.phoneNumberFields.map((phoneNumberField, index) => (
                            <div key={index} style={{ width: "100%" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <InputMask
                                            mask="999-999-9999"
                                            disabled={false}
                                            maskChar=" "
                                            value={phoneNumberField.phone_number}
                                        >
                                            {() => <TextField
                                                fullWidth
                                                label="Phone"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                required
                                                name={`business[phone_numbers_attributes[${index}][phone_number]]`}
                                            />}
                                        </InputMask>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Phone Type"
                                            id="outlined-size-small"
                                            value={this.state.phoneNumberFields[index].phone_type}
                                            variant="outlined"
                                            size="small"
                                            className="form-field"
                                            name={`business[phone_numbers_attributes[${index}][phone_type]]`}
                                            onChange={(event) => {
                                                let phoneNumberFields = [...this.state.phoneNumberFields];
                                                let phoneNumberField = { ...phoneNumberFields[index] };
                                                phoneNumberField.phone_type = event.target.value;
                                                phoneNumberFields[index] = phoneNumberField;
                                                this.setState({
                                                    phoneNumberFields: phoneNumberFields
                                                })
                                            }}
                                            required
                                        >
                                            {
                                                phoneNumberTypes.map((phoneNumberType) => (
                                                    <MenuItem key={phoneNumberType} value={phoneNumberType} style={{ padding: "5px" }}>
                                                        {phoneNumberType}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </div>
                        ))
                    }
                    <Grid container>
                        <Grid item xs={12}>
                            <Link
                                onClick={this.handleAddInputFiled}
                                style={{ marginLeft: "5px" }}
                            >
                                <AddIcon className="green-font add-icon"/> <span className="grey-font"> Add Phone</span>
                            </Link>
                            {this.state.phoneNumberFields.length > 1 &&
                                <Link
                                    onClick={this.handleRemoveInputFiled}
                                    style={{ color: "red",
                                        marginLeft: "30px"
                                    }}
                                >
                                    <RemoveIcon className="remove-icon" /> Remove
                                </Link>
                            }
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Beef Quality Assurance Number"
                                id="outlined-size-small"
                                defaultValue={this.props.default_business_params?.bqa_number}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="business[bqa_number]"
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="actions" >
                        <Grid item xs={12} container alignItems="center" justify="flex-end">
                            <Link href="/" className="grey-font cancel-link">
                                Cancel
                            </Link>
                            <Button variant="contained" type="submit" className="submit-btn">
                                <span className="green-font">Save</span>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default BusinessInformationForm