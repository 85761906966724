import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Checkbox,
    MenuItem
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '../shared/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import { getTodayDate, calculateAge } from '../utils/Helper';
import Flash from "../layouts/Flash";

export default function New(props) {
    const [transferDate, setTransferDate] = useState(getTodayDate);
    const [selectedAnimals, setSelectedAnimals] = useState(props.animals);
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);

    const [animals, setAnimals] = useState(props.animals);

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(animals.length / perPage));

    const [searchValue, setSearchValue] = useState("");

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value;
        const filteredCalves = props.animals.filter((animal) => animal.ear_tag_id.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1);
        setSearchValue(searchParam);
        setAnimals(filteredCalves);
    }

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.animals];
            setAnimals(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const handleAnimalSelection = (animal) => {
        if (animal.selected) {
            animal.selected = false;
        } else {
            animal.selected = true;
        }
        reassingnCalf(animal);
    }

    const selectedAnimal = (animalId, animalType) => {
        const animal = selectedAnimals.find((animal) => animal.id == animalId && animal.type == animalType);
        return animal.selected;
    }

    const submitForm = () => {
        event.preventDefault();
        const finalSelectedAnimals = selectedAnimals.filter((selectedCalf) => selectedCalf.selected == true);
        if (finalSelectedAnimals.length == 0) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select an animal." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        if (selectedHerdId == props.herd.id) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Error current herd and destination herd is same." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        fetch(`/herds/${props.herd.id}/transfers`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                transfer: {
                    animals: finalSelectedAnimals,
                    date: transferDate,
                    destination_herd_id: selectedHerdId
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }  

    const notesInput = (notes, animal) => {
        animal.notes = notes;
        reassingnCalf(animal);
    }

    const reassingnCalf = (animal) => {
        const restOfAnimals = selectedAnimals.filter((selectedAnimal) => !(selectedAnimal.id == animal.id && selectedAnimal.type == animal.type));
        setSelectedAnimals([...restOfAnimals, animal])
    }

    const handlePagecancelAndBack = () => {
        const finalSelectedAnimals = selectedAnimals.filter((selectedAnimal) => selectedAnimal.selected == true);
        if (finalSelectedAnimals.length > 0) {
            if (confirm("There are some unchanged changes. Are you sure?")) {
                window.location.href = `/herds/${props.herd.id}`
            }
        }
        else {
            window.location.href = `/herds/${props.herd.id}`
        }
    }

    return (
        <div className="main-container herd cow content-body weaning">
            <Grid container item xs={12} className="cow-container">
                <Grid container >
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href="#" onClick={handlePagecancelAndBack}><ArrowBackIosIcon /></Link></span>Herd {props.herd.herd_id} — Transfer Animals
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { search(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <form onSubmit={submitForm}>
                                    <Grid container justify="space-between">
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Herd Transfer Date"
                                                value={transferDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setTransferDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Transfer to Herd"
                                                value={selectedHerdId}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                required
                                                select
                                                onChange={(e) => setSelectedHerdId(e.target.value)}
                                            >
                                                {
                                                    props.herds.map((herd) => (
                                                        <MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                            {herd.herd_id}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Grid container justify="flex-end">
                                                <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end" style={{ marginRight: "0", paddingRight: "0" }}>
                                                    <Link href="#" onClick={handlePagecancelAndBack} className="grey-font">
                                                        Cancel
                                                    </Link>
                                                    <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                        <span className="green-font">SAVE</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <TableContainer className="cull-animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" ><CheckIcon fontSize="large" align="center" /></TableCell>
                                        <TableCell align="center">Animal ID</TableCell>
                                        <TableCell align="center">Type</TableCell>
                                        <TableCell align="center">Sex</TableCell>
                                        <TableCell align="center">Age</TableCell>
                                        <TableCell align="center">Electronic ID</TableCell>
                                        <TableCell align="center">Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        animals.map((animal) => {
                                            return (
                                                <TableRow key={`${animal.type}-${animal.id}`}>
                                                    <TableCell align="center" className="check-box">
                                                        <Checkbox
                                                            checked={selectedAnimal(animal.id, animal.type)}
                                                            onChange={() => handleAnimalSelection(animal)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {animal.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {animal.type}
                                                    </TableCell>
                                                    <TableCell align="center">{animal.sex}</TableCell>
                                                    <TableCell align="center">{calculateAge(animal.birth_date)}</TableCell>
                                                    <TableCell align="center">
                                                        {animal.electronic_id}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={animal.notes}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => notesInput(e.target.value, animal)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}