import React from 'react';
import {
    Grid,
}
from '@material-ui/core';

export default function HerdDetail(props){
    return(
        <Grid item container md={2} xs={12}>
            <Grid item xs={12} className="herd-inventory-table">
                <table >
                    <thead>
                        <tr>
                            <th colSpan="2" className="left-align">
                                Herd inventory
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td onClick={() => { window.location.href = `/herds/${props.herd.id}/cows` }} className="black-font link">
                                Cows
                            </td>
                            <td onClick={() => { window.location.href = `/herds/${props.herd.id}/cows` }} className="black-font link center-align">
                                <b>{props.herd.cows_count}</b>
                            </td>
                        </tr>
                        <tr>
                            <td onClick={() => { window.location.href = `/herds/${props.herd.id}/calves` }} className="black-font link">
                                Calves
                            </td>
                            <td onClick={() => { window.location.href = `/herds/${props.herd.id}/calves` }} className="black-font link center-align">
                                <b>{props.herd.calves_count}</b>
                            </td>
                        </tr>
                        <tr>
                            <td><b>TOTAL</b></td>
                            <td className="center-align"><b>{props.herd.total_animals}</b></td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
        </Grid>
    )
    
}