import React, { useState, useEffect } from 'react';
import { twoDecimalFormat } from '../../utils/Helper';
export default function ReproductionPerformance(props) {

    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/reproduction_performance/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <table className="table">
            <thead>
                <tr className="primary-header">
                    <th colSpan={3} className="label-col">
                        Reproduction Performance — Based on Exposed Females
                    </th>
                </tr>
                <tr className="secondary-header">
                    <th className="label-col thin-border darker-right">
                        Critical Success Factors
                    </th>
                    <th className="label-col thin-border darker-right">
                        Herd/Group Performance
                    </th>
                    <th className="label-col thin-border">
                        5 Year CHAPS Benchmark
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Pregnancy Percentage**
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.pregnancy_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.pregnancy_percentage)}                    
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Pregnancy Loss Percentage**
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.pregnancy_loss_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.pregnancy_loss_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calving Percentage*
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calving_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calving_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calf Death Loss Percentage*
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calf_death_loss_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calf_death_loss_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calf Crop or Weaning Percentage*
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.weaning_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.weaning_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Female Replacement Rate Percentage**
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.replacement_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.replacement_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calf Death Loss Based on # of Calves Born Percentage*
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calf_loss_on_number_born_percentage)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calf_loss_on_number_born_percentage)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Pounds Weaned Per Exposed Female*
                    </td>
                    <td className="data-col darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.pounds_weaned_per_cows_exposed)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.pounds_weaned_per_cows_exposed)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}