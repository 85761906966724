import React from 'react';
import {
    Typography,
    Grid,
    TextField
} from "@material-ui/core";
class UserInformationForm extends React.Component{

    constructor(props){
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            params_first_name: urlParams.get("first_name"),
            params_last_name: urlParams.get("last_name"),
            params_email: urlParams.get("email"),
        }
    }

    render(){
        return(
            <Grid item md={5} xs={12} className="form-panel">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="ml-10" gutterBottom>
                            Primary user information:
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="First Name"
                                id="outlined-size-small"
                                defaultValue={this.props.default_user_params?.first_name || this.state.params_first_name}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[first_name]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Middle"
                                id="outlined-size-small"
                                defaultValue={this.props.default_user_params?.middle_name}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[middle_name]"
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                id="outlined-size-small"
                                defaultValue={this.props.default_user_params?.last_name || this.state.params_last_name}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[last_name]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Email"
                                id="outlined-size-small"
                                defaultValue={this.props.default_user_params?.email || this.state.params_email}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[email]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                id="outlined-size-small"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[password]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Re-enter Password"
                                type="password"
                                id="outlined-size-small"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className="form-field"
                                name="user[password_confirmation]"
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className="ml-10">
                            <p>Password:</p>
                            <ul>
                                <li>Must be at least 8 characters in length </li>
                                <li>Must contain at least one capital letter </li>
                                <li>Must contain at least one lowercase letter</li>
                                <li>Must contain at least one number</li>
                                <li>May optionally contain special characters</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default UserInformationForm