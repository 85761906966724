import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button
}
    from '@material-ui/core';

import { noDecimalFormat } from '../utils/Helper';

export default function HerdPerformanceComparision(props) {
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);
    const [year, setYear] = useState(props.year);

    //there are 5 data pulls from the server
    //on first pull, we do calves related data
    //on second pull we do we do calving distribution related data
    //on third pull we do weaning weight relate data pull
    //on 4t pull we pull the cow related data
    //on last pull we do we pull chaps benchmark data
    const [calvesData, setCalvesData] = useState(null);
    const [distributionData, setDistributionData] = useState(null);
    const [weaningData, setWeaningData] = useState(null);
    const [cowsData, setCowsData] = useState(null);
    const [chapsBenchmark, setChapsBenchmar] = useState(null);

    //first part of calves data
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_herd_comparision_calves/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setCalvesData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    //pulling distribution data
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_herd_comparision_distribtion/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setDistributionData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    //pulling data by weaning record by sex
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_weaning_data_by_sex/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setWeaningData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    //pulling data by cow data
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_herd_cows/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setCowsData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    //chaps benchmarks data
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_chaps_benchmarks/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setChapsBenchmar(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const rerunReport = () => {
        window.location.href = `/herd_performance_comparision/${selectedHerdId}?year=${year}`
    }

    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Herd Performance Comparision</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.business_name}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.formatted_address}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Herd/Breeding Group: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedHerdId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setSelectedHerdId(e.target.value)}
                                        >
                                            {
                                                props.herds.map((herd) => (
                                                    < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                        {herd.herd_id}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Year: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={year}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            {
                                                props.years.map((i) => (
                                                    < MenuItem key={i} value={i} style={{ padding: "5px" }}>
                                                        {i}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <Button className="btn" onClick={() => rerunReport()}>
                                            Rerun Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <th rowSpan={2} className="label-col darker-right">
                                    Critical Success Factors
                                </th>
                                <th colSpan={3} className="label-col darker-right">
                                    Herd/Group Performance
                                </th>
                                <th rowSpan={2} className="label-col">
                                    5 Year CHAPS Benchmark
                                </th>
                            </tr>
                            <tr className="secondary-header">
                                <th className="label-col thin-border">
                                    This period
                                </th>
                                <th className="label-col thin-border">
                                    Prior Period
                                </th>
                                <th className="label-col thin-border darker-right">
                                    5 Year Avg
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  className="thin-border">
                                <td className="data-col darker-right">
                                    Days of Age at Weaning
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.this_period?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.prior_period?.age_in_days)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(calvesData?.five_year_data?.age_in_days)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.age_in_days)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Weight Per Day of Age (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.this_period?.wda}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.prior_period?.wda}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {calvesData?.five_year_data?.wda}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.wda}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Birth Weight (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.this_period?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.prior_period?.birth_weight)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(calvesData?.five_year_data?.birth_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.birth_weight)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Average Daily Gain (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.this_period?.adg}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.prior_period?.adg}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {calvesData?.five_year_data?.adg}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.adg}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Heifers Calving Early (%)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.this_period?.heifer_early_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.prior_period?.heifer_early_calving_percentage}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {distributionData?.five_year_data?.heifer_early_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.heifer_early_calving_percentage}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Heifers Calving Within 21 Days (%)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.this_period?.heifer_21_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.prior_period?.heifer_21_calving_percentage}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {distributionData?.five_year_data?.heifer_21_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.heifer_21_days_calving_percentage}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Heifers Calving Within 42 Days (%)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.this_period?.heifer_42_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.prior_period?.heifer_42_calving_percentage}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {distributionData?.five_year_data?.heifer_42_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.heifer_42_days_calving_percentage}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Mature Cows Calving Within 21 Days (%)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.this_period?.cow_21_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.prior_period?.cow_21_calving_percentage}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {distributionData?.five_year_data?.cow_21_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.cows_21_days_calving_percentage}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Mature Cows Calving Within 42 Days (%)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.this_period?.cow_42_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {distributionData?.prior_period?.cow_42_calving_percentage}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {distributionData?.five_year_data?.cow_42_calving_percentage}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.cows_42_days_calving_psercentage}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Cow Age (yr)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.this_period?.dam_age}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.prior_period?.dam_age}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {calvesData?.five_year_data?.dam_age}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.cow_age}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Cow Weight (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(cowsData?.this_period?.average_cow_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(cowsData?.prior_period?.average_cow_weight)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(cowsData?.five_year_data?.average_cow_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.cow_weight)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Cow Condition Score
                                </td>
                                <td className="data-col thin-border center-text">
                                    {cowsData?.this_period?.average_cow_bcs}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {cowsData?.prior_period?.average_cow_bcs}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {cowsData?.five_year_data?.average_cow_bcs}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.cow_body_condition_score}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    # Replacements Kept to Calve
                                </td>
                                <td className="data-col thin-border center-text">
                                    {cowsData?.this_period?.total_heifers_kept_to_calve}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {cowsData?.prior_period?.total_heifers_kept_to_calve}
                                </td>
                                <td className="data-col darker-right center-text">
                                    --
                                </td>
                                <td className="data-col thin-border center-text">
                                    --
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Adjusted 205 Day Weight (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.this_period?.adjusted_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(calvesData?.prior_period?.adjusted_205)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(calvesData?.five_year_data?.adjusted_205)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.adj_205)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Weaning Weight for Steer Calves (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.this_period?.steer_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.prior_period?.steer_weaning_weight)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(weaningData?.five_year_data?.steer_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.weaning_steer_weight)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Weaning Weight for Heifer Calves (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.this_period?.heifers_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.prior_period?.heifers_weaning_weight)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(weaningData?.five_year_data?.heifers_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.weaning_heifer_weight)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Weaning Weight for Bull Calves (lb)
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.this_period?.bull_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(weaningData?.prior_period?.bull_weaning_weight)}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {noDecimalFormat(weaningData?.five_year_data?.bull_weaning_weight)}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {noDecimalFormat(chapsBenchmark?.weaning_bull_weight)}
                                </td>
                            </tr>
                            <tr className="thin-border">
                                <td className="data-col darker-right">
                                    Frame Score
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.this_period?.frame_score}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {calvesData?.prior_period?.frame_score}
                                </td>
                                <td className="data-col darker-right center-text">
                                    {calvesData?.five_year_data?.frame_score}
                                </td>
                                <td className="data-col thin-border center-text">
                                    {chapsBenchmark?.frame_score}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        </div>
    )
}