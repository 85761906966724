import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button
}
    from '@material-ui/core';
import SummaryOfCalvesBirth from './calf_performance_reports/SummaryOfCalvesBirth';
import SummaryOfHerdAverages from './calf_performance_reports/SummaryOfHerdAverages';
import SummaryOfCalvesData from './calf_performance_reports/SummaryOfCalvesData';
export default function CalfPerformance(props) {
    const [selectedHerdId, setSelectedHerdId] = useState(props.herd.id);
    const [year, setYear] = useState(props.year);
    const [sex, setSex] = useState(props.sex);

    const rerunReport = () => {
        window.location.href = `/calf_performance/${selectedHerdId}?year=${year}&sex=${sex}`
    }   

    return (
        <div className="main-container content-body2">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Calf Performance</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.business_name}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.formatted_address}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Herd/Breeding Group: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedHerdId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setSelectedHerdId(e.target.value)}
                                        >
                                            {
                                                props.herds.map((herd) => (
                                                    < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                        {herd.herd_id}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Year: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={year}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            {
                                                props.years.map((i) => (
                                                    < MenuItem key={i} value={i} style={{ padding: "5px" }}>
                                                        {i}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Sex: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={sex}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => setSex(e.target.value)}
                                        >
                                            {
                                                props.sex_options.map((option) => (
                                                    < MenuItem key={option} value={option} style={{ padding: "5px" }}>
                                                        {option}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <Button className="btn" onClick={() => rerunReport()}>
                                            Rerun Report
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Grid container justify="space-between">
                                <Grid item xs={3}>
                                    <SummaryOfCalvesBirth 
                                        herd={props.herd}
                                        year={props.year}
                                        sex={props.sex}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <SummaryOfHerdAverages
                                        herd={props.herd}
                                        year={props.year}
                                        sex={props.sex}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <SummaryOfCalvesData
                        herd={props.herd}
                        year={props.year}
                        sex={props.sex}
                    />
                </Grid>
            </Grid>
        </div>
    )
}