import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

class Flash extends React.Component{
    constructor(props){
        super(props);
        let open = false;
        if(props.message && props.severity){
            open = true;
        }
        this.state = {
            open: open
        }
    }

    handleClose = (event) => {
        this.setState({
            open: false
        })
    };

    render(){

        return(
            <div>
                <Snackbar 
                    open={this.state.open} 
                    autoHideDuration={5000} 
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MuiAlert onClose={this.handleClose} severity={this.props.severity}>
                        {this.props.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        )
    }
}
export default Flash