import React, {useState, useEffect} from 'react';
import {Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import Pagination from '../shared/Pagination';
import { formatStandardDate} from "../utils/Helper"

export default function IndexWithHerd(props) {
    const [herds, setHerds] = useState(props.herds);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [totalPages] = useState(Math.ceil(herds.length/perPage));
    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    useEffect(() => {
        if(totalPages > 1){
            const currentOffset = offset * perPage;
            const data = [...props.herds];
            setHerds(data.slice(currentOffset, currentOffset + perPage));
        }
    },[offset]);
    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="dashboard-container">
                <Grid item md={11} xs={12}>
                    <Grid container justify="space-between">
                        <Grid container item xs={12} className="dashboard-title-header">
                            <Grid item md={4} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Active Inventory</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <TableContainer className="dashboard-table">
                                <Table aria-label="customized table">
                                    <TableHead className="dashboard-table-header dashboard-bulls-table">
                                        <TableRow>
                                            <TableCell>Herd ID</TableCell>
                                            <TableCell align="left">Description</TableCell>
                                            <TableCell align="center" >Bull Turn Out Date</TableCell>
                                            <TableCell align="center">Cows</TableCell>
                                            <TableCell align="center">Calves</TableCell>
                                            <TableCell align="center">TOTAL</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            herds.map((herd) => {
                                                return(
                                                    <TableRow key={herd.id}>
                                                        <TableCell onClick={() => { window.location.href = `/herds/${herd.id}`}} align="left" className="black-font link herd-link">
                                                            {herd.herd_id}
                                                        </TableCell>
                                                        <TableCell align="left">{herd.description}</TableCell>
                                                        <TableCell align="center" onClick={() => { window.location.href = `/herds/${herd.id}` }} className="black-font link herd-link">
                                                            {formatStandardDate(herd.recent_btod)} 
                                                            {
                                                                herd.too_old_btod && 
                                                                <Tooltip title="The current BTOD is more than 330 days past. Please set a new BTOD.">
                                                                    <img src={props.warning_icon} alt="warning" width="25px" style={{ display: "inline-block", verticalAlign:"bottom", marginLeft: "10px"}} />
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center" onClick={() => { window.location.href = `/herds/${herd.id}/cows` }} className="black-font link">
                                                            <b>{herd.cows_count}</b>
                                                        </TableCell>
                                                        <TableCell align="center" onClick={() => { window.location.href = `/herds/${herd.id}/calves` }} className="black-font link">
                                                            <b>{herd.calves_count}</b>
                                                        </TableCell>
                                                        <TableCell align="center"><b>{herd.total_animals}</b></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        <TableRow>
                                            <TableCell align="left"><b>TOTAL {props.herds.length} HERDS</b></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="center"><b>{props.total_cows}</b></TableCell>
                                            <TableCell align="center"><b>{props.total_calves}</b></TableCell>
                                            <TableCell align="center"><b>{props.total_cows + props.total_calves}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination
                                offset={offset}
                                totalPages={totalPages}
                                handlePaginationPageChange={handlePaginationPageChange} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TableContainer className="dashboard-table">
                                <Table aria-label="customized table">
                                    <TableHead className="dashboard-table-header">
                                        <TableRow>
                                            <TableCell colSpan={2}>Bulls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell onClick={() => { window.location.href = `/businesses/${props.provider.id}/bulls` }} align="left" className="black-font link herd-link"><b>TOTAL BULLS</b></TableCell>
                                            <TableCell align="left" onClick={() => { window.location.href = `/businesses/${props.provider.id}/bulls` }} className="black-font link herd-link"><b>{props.bulls.length}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}