import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContentText,
    MenuItem
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '../shared/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import { getTodayDate, calculateAge } from '../utils/Helper';
import Flash from "../layouts/Flash";
import { formatStandardDate } from "../utils/Helper"


export default function New(props) {
    //How it works: the calf object has selected attribute for boolen, when checked/unchecked the attribute is toggled and reassigned to 
    //selectedCalves state, calves state us used only for filter and pagination purpose, rest of the value action happen in selectedCalves state
    const [open, setOpen] = React.useState(false);
    const [replaceDate, setReplaceDate] = useState(getTodayDate);
    
    //calves are used for iteration, filter and selectedCalves are used for value change, and selection of animals
    const [calves, setCalves] = useState(props.calves?.sort(compare));
    const [selectedCalves, setSelectedCalves] = useState(props.calves?.sort(compare));

    //pagination stuffs
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(100);
    const [totalPages] = useState(Math.ceil(calves.length / perPage));

    const [searchValue, setSearchValue] = useState("");

    //this is modal error message that says Adult id is empty onBlur
    const [errorMessage, setErrorMessage] = useState("");

    //flash error message triggers after submission
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.calves];
            setCalves(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    const search = (e) => {
        const searchParam = e.target.value;
        const filteredCalves = props.calves.filter((calf) => calf.ear_tag_id.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1);
        setSearchValue(searchParam);
        setCalves(filteredCalves);
    }

    const handleCalfSelection = (calf) => {
        if (calf.selected) {
            calf.selected = false;
        } else {
            calf.selected = true;
        }
        reassingnCalf(calf);
    }

    const selectedCalf = (calfId) => {
        const calf = selectedCalves.find((calf) => calf.id == calfId);
        return calf.selected;
    }

    const validateSelectedRecords = (finalSelectedCalves) => {
        const emptyEarTags = finalSelectedCalves.filter((calf) => calf.adult_id.trim() == "");
        if (emptyEarTags.length > 0) {
            setErrorMessage(`Some Adult ID are empty.`);
            setOpen(true);
            return false;
        } else {
            return true;
        }
    }

    const submitForm = () => {
        event.preventDefault();
        const finalSelectedCalves = selectedCalves.filter((selectedCalf) => selectedCalf.selected == true);
        if (finalSelectedCalves.length == 0) {
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select a calf." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        if (validateSelectedRecords(finalSelectedCalves) == false) {
            return;
        }

        fetch(`/herds/${props.herd.id}/replacements`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                replacement: {
                    calves: finalSelectedCalves,
                    date: replaceDate,
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const adultEarTagInput = (earTagId, calf) => {
        calf.adult_id = earTagId;
        reassingnCalf(calf);
    }

    const herdInput = (herdId, calf) => {
        calf.herd_id = herdId;
        reassingnCalf(calf);
    }

    const electronicIdInput = (electronicId, calf) => {
        calf.electronic_id = electronicId;
        reassingnCalf(calf);
    }

    const notesInput = (notes, calf) => {
        calf.notes = notes;
        reassingnCalf(calf);
    }

    const reassingnCalf = (calf) => {
        const restOfCalves = selectedCalves.filter((selectedCalf) => selectedCalf.id != calf.id);
        setSelectedCalves([...restOfCalves, calf])
    }

    const validateEarTagId = (earTagId) => {
        if (earTagId.trim() == "") {
            setErrorMessage(`Adult ID tag is empty.`);
            setOpen(true);
        }
    }

    const handlePagecancelAndBack = () => {
        const finalSelectedCalves = selectedCalves.filter((selectedCalf) => selectedCalf.selected == true);
        if (finalSelectedCalves.length > 0) {
            if (confirm("There are some unchanged changes. Are you sure?")) {
                window.location.href = `/herds/${props.herd.id}`
            }
        }
        else {
            window.location.href = `/herds/${props.herd.id}`
        }
    }

    const handleCopyCalfIdToAdultId = () => {
        let tempCalves = [];
        selectedCalves.forEach(calf => {
            if(calf.selected && calf.adult_id == ""){
                calf.adult_id = calf.ear_tag_id
            }
            tempCalves.push(calf);
        })
        setCalves([...tempCalves.sort(compare)]);
    }

    return (
        <div className="main-container herd cow content-body weaning">
            <Grid container item xs={12} className="cow-container">
                <Grid container >
                    <Grid item md={11} xs={12}>
                        <Grid container justify="space-between">
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href="#" onClick={handlePagecancelAndBack}><ArrowBackIosIcon /></Link></span> Replacement Calves — {props.herd.herd_id}
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { search(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <form onSubmit={submitForm}>
                                    <Grid container justify="space-between">
                                        <Grid item md={2}>
                                            <TextField
                                                fullWidth
                                                label="Replace Date"
                                                value={replaceDate}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                type="date"
                                                required
                                                onChange={(e) => setReplaceDate(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Grid container justify="flex-end">
                                                <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end" style={{ marginRight: "0", paddingRight: "0" }}>
                                                    <Link href="#" onClick={handlePagecancelAndBack} className="grey-font">
                                                        Cancel
                                                    </Link>
                                                    <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                        <span className="green-font">SAVE</span>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Grid item md={6}>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Button variant="contained" onClick={handleCopyCalfIdToAdultId} className="submit-btn animal-submit-btn" style={{ 
                                                marginLeft: "0", 
                                                marginBottom: "20px",
                                                marginTop: "15px",
                                                marginRight: "20px"
                                            }}
                                            >
                                                <span className="green-font">Copy Calf IDs to Adult IDs</span>
                                            </Button>

                                            <span className="grey-font">
                                                Adult IDs can also be edited individually
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <TableContainer className="cull-animals-table">
                            <Table aria-label="customized table" className="no-border">
                                <TableHead className="animals-table-header">
                                    <TableRow className="yellow-background">
                                        <TableCell align="center" className="right-thick-border no-left-border-th" colSpan={5}>
                                            Calf Information
                                        </TableCell>
                                        <TableCell align="center" className="no-right-border-th" colSpan={4}>
                                            Adult Information
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" ><CheckIcon fontSize="large" align="center" /></TableCell>
                                        <TableCell align="center">Calf ID</TableCell>
                                        <TableCell align="center">Sex</TableCell>
                                        <TableCell align="center">Age</TableCell>
                                        <TableCell align="center" className="right-thick-border">Wean Date</TableCell>
                                        <TableCell align="center">Adult ID</TableCell>
                                        <TableCell align="center">Destination Herd</TableCell>
                                        <TableCell align="center">Electronic ID</TableCell>
                                        <TableCell align="center">Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        calves.map((calf) => {
                                            return (
                                                <TableRow key={calf.id}>
                                                    <TableCell align="center" className="check-box">
                                                        <Checkbox
                                                            checked={selectedCalf(calf.id)}
                                                            onChange={() => handleCalfSelection(calf)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {calf.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calf.sex}</TableCell>
                                                    <TableCell align="center">{calculateAge(calf.birth_date)}</TableCell>
                                                    <TableCell align="center" className="right-thick-border">{formatStandardDate(calf.weaning_date)}</TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            value={calf.adult_id}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => adultEarTagInput(e.target.value, calf)}
                                                            onBlur={(e) => validateEarTagId(e.target.value)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            value={calf.herd_id}
                                                            variant="standard"
                                                            size="small"
                                                            disabled={calf.sex != "Heifer"}
                                                            className="move-to-the-right"
                                                            required
                                                            select
                                                            onChange={(e) => herdInput(e.target.value, calf)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        >
                                                            {
                                                                props.herds.map((herd) => (
                                                                    <MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                                        {herd.herd_id}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={calf.electronic_id}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => electronicIdInput(e.target.value, calf)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={calf.notes}
                                                            variant="standard"
                                                            size="small"
                                                            className="move-to-the-right"
                                                            required
                                                            onChange={(e) => notesInput(e.target.value, calf)}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContentText className="delete-dialog-content" style={{ paddingTop: "25px" }}>
                        {errorMessage}
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between" style={{ width: "420px" }}>
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={handleClose} className="modal-yes-button" style={{ width: "150px" }}>
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

const compare = (val1, val2) =>{
    let id1 = val1.ear_tag_id?.toUpperCase();
    let id2 = val2.ear_tag_id?.toUpperCase();
    if (id1 < id2) {
        return -1;
    }
    if (id1 > id2) {
        return 1;
    }
    return 0;
}