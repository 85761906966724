import React, { useState, useEffect } from 'react';
import { formatStandardDate } from "../../utils/Helper"

export default function SummaryOfCalvingDistributionTable(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_calving_distribution/?herd_id=${props.herd.id}&btod_id=${props.btod.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
                console.log(result.resource)
            })
            .catch(error => {
                console.error(error);
            });
    }, []);


    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                :
                    <table className="table">
                        <thead>
                            <tr className="primary-header">
                                <th colSpan={5} className="label-col darker-right">
                                    Cow Data
                                </th>
                                <th colSpan={6} className="label-col darker-right">
                                    # of Calves Born Each Period
                                </th>
                                <th colSpan={2} className="label-col">
                                    Averages
                                </th>
                            </tr>
                            <tr className="secondary-header">
                                <th className="label-col thin-border">
                                    Cow Age(yr)
                                </th>
                                <th className="label-col thin-border">
                                    Kept for Calving
                                </th>
                                <th className="label-col thin-border">
                                    Aborted
                                </th>
                                <th className="label-col thin-border">
                                    Open
                                </th>
                                <th className="label-col darker-right thin-border">
                                    Calved
                                </th>
                                <th className="label-col thin-border">
                                    Early
                                </th>
                                <th className="label-col thin-border">
                                    First 21 Days
                                </th>
                                <th className="label-col thin-border">
                                    Second 21 Days
                                </th>
                                <th className="label-col thin-border">
                                    Third 21 Days
                                </th>
                                <th className="label-col thin-border">
                                    Late or Over 63 Days
                                </th>
                                <th className="label-col darker-right">
                                    Total
                                </th>
                                <th className="label-col thin-border">
                                    Average Birth Date
                                </th>
                                <th className="label-col thin-border">
                                    Wean Weight(lb)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.distribution_data?.map((item, index)=>{
                                    if (item?.data?.calves?.length > 0){
                                        return(
                                            <tr key={index} className="thin-border">
                                                <td className="data-col thin-border center-text">
                                                    {item.age_group}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.kept_for_calving}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.aborted}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.open}
                                                </td>
                                                <td className="data-col darker-right center-text">
                                                    {item?.data?.calved}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item.age_group == "2" ? item?.data?.distribution?.early : "N/A"}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.distribution?.first}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.distribution?.second}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.distribution?.third}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.distribution?.late}
                                                </td>
                                                <td className="data-col darker-right center-text">
                                                    {item?.data?.distribution?.total_distribution}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {formatStandardDate(item?.data?.average_birth_date)}
                                                </td>
                                                <td className="data-col thin-border center-text">
                                                    {item?.data?.average_wean_weight}
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                            <tr className="darker-top">
                                <td colSpan={3}></td>
                                <td colSpan={2} className="data-col thin-border darker-bottom green-back">
                                    Totals
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_distribution_total?.early_calves}
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_distribution_total?.first_calves}
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_distribution_total?.second_calves}
                                </td>
                                <td className="data-col darker-right darker-bottom center-text">
                                    {data?.vertical_distribution_total?.third_calves}
                                </td>
                                <td className="data-col darker-right darker-bottom center-text">
                                    {data?.vertical_distribution_total?.late_calves}
                                </td>
                                <td className="data-col thin-border darker-bottom darker-right center-text">
                                    {data?.vertical_distribution_total?.total_calves}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}></td>
                                <td colSpan={2} className="data-col thin-border darker-bottom green-back">
                                    Average Wean Weight(lb)
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_weaning_weight_average?.early_calf_average_weaning_weight}
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_weaning_weight_average?.first_calf_average_weaning_weight}
                                </td>
                                <td className="data-col thin-border darker-bottom center-text">
                                    {data?.vertical_weaning_weight_average?.second_calf_average_weaning_weight}
                                </td>
                                <td className="data-col darker-right darker-bottom center-text">
                                    {data?.vertical_weaning_weight_average?.third_calf_average_weaning_weight}
                                </td>
                                <td className="data-col thin-border darker-bottom darker-right center-text">
                                    {data?.vertical_weaning_weight_average?.late_calf_average_weaning_weight}
                                </td>
                            </tr>
                        </tbody>
                    </table>
            }
        </>
    )
}