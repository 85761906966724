import React, {useState} from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
    Link,
    MenuItem
}
from '@material-ui/core';
import HerdDetail from '../shared/HerdDetail';
import CowForm from './CowForm';
import CalfForm from './CalfForm';
import Flash from "../layouts/Flash";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getTodayDate } from "../utils/Helper";

export default function New(props) {

    const [animalType, setAnimalType] = useState("Cow");
    const [earTagId, setEarTagId] = useState("");
    const [birthDate, setBirthDate] = useState(getTodayDate);
    const [breed, setBreed] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [registrationName, setRegistrationName] = useState("");
    const [electronicId, setElectronicId] = useState("");
    const [sireId, setSireId] = useState("");
    const [damId, setDamId] = useState("");
    const [herdEntryDate, setHerdEntryDate] = useState(getTodayDate);
    const [source, setSource] = useState("Raised");
    const [status, setStatus] = useState("Active");
    const [preferredBreeds, setPreferredBreeds] = useState(props.preferredBreeds);
    const [notes, setNotes] = useState("");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    //cow specefic option
    const [bangsId, setBangsId] = useState("");

    //calf specefic options
    const [sex, setSex] = useState("");
    const [birthCircumstance, setBirthCircumstance] = useState("");
    const [birthWeight, setBirthWeight] = useState("");
    const [calvingEase, setCalvingEase] = useState("");
    const [raisedByFoster, setRaisedByFoster] = useState(false);
    const [embryoTransplant, setEmbryoTransplant] = useState(false);
    const [twinCalf, setTwinCalf] = useState(props.twinCalfOptions[0][1]);
    const [freeMartin, setFreeMartin] = useState(false);
    const [additionalCertificateId, setAdditionalCertificateId] = useState("");
    
    const animalTypes = props.animalTypes;
    const sourceOptions = ["Raised", "Purchased"];

    const submitNewAnimalForm = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/animals`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                animal: {
                    animal_type: animalType,
                    ear_tag_id: earTagId,
                    birth_date: birthDate,
                    breed: breed,
                    registration_number: registrationNumber,
                    registration_name: registrationName,
                    electronic_id: electronicId,
                    sire_id: sireId,
                    dam_id: damId,
                    herd_entry_date: herdEntryDate,
                    source: source,
                    status: status,
                    notes: notes,
                    //calf specefic params
                    sex: sex,
                    birth_circumstance: birthCircumstance,
                    birth_weight: birthWeight,
                    calving_ease: calvingEase,
                    raised_by_foster: raisedByFoster,
                    embryo_transplant: embryoTransplant,
                    twin_calf: twinCalf,
                    freemartin: freeMartin,
                    additional_certificate_id: additionalCertificateId,
                    //cow specefic params
                    bangs_id: bangsId
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/herds/${props.herd.id}`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="main-container herd animal cow content-body">
            <Grid container className="grid-container">
                <Grid item xs={12} className="herd-detail-header">
                    <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> Herd Detail: {props.herd.herd_id}
                </Grid>
            </Grid>
            <div className="table-container">
                <Grid container className="grid-container">
                    <HerdDetail herd={props.herd} />
                    <Grid item container md={5} xs={12} className="button-container" >
                        <Grid container className="animal-form-container">
                            <Grid item xs={12} className="animal-form-header-container">
                                <Typography variant="h6" gutterBottom className="animal-form-header">
                                    Add animal to herd
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="animal-form-field-container">
                                <form onSubmit={submitNewAnimalForm}>
                                    <Grid container>
                                        <Grid item md={6} xs={12} className="animal-form-column">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Add Animal"
                                                        select
                                                        value={animalType}
                                                        variant="outlined"
                                                        size="small"
                                                        className="form-field"
                                                        onChange={(e)=> {setAnimalType(e.target.value)}}
                                                        required
                                                    >
                                                        {
                                                            animalTypes.map((animal, index) => (
                                                                < MenuItem key={index} value ={animal} style = {{ padding: "5px" }}>
                                                                    {animal}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        animalType == "Cow" ?
                                            <CowForm
                                                herd={props.herd}
                                                earTagId={earTagId}
                                                setEarTagId={setEarTagId}
                                                birthDate={birthDate}
                                                setBirthDate={setBirthDate}
                                                breed={breed}
                                                setBreed={setBreed}
                                                registrationNumber={registrationNumber}
                                                setRegistrationNumber={setRegistrationNumber}
                                                registrationName={registrationName}
                                                setRegistrationName={setRegistrationName}
                                                electronicId={electronicId}
                                                setElectronicId={setElectronicId}
                                                sireId={sireId}
                                                setSireId={setSireId}
                                                damId={damId}
                                                setDamId={setDamId}
                                                herdEntryDate={herdEntryDate}
                                                setHerdEntryDate={setHerdEntryDate}
                                                source={source}
                                                setSource={setSource}
                                                status={status}
                                                setStatus={setStatus}
                                                notes={notes}
                                                setNotes={setNotes}
                                                sourceOptions={sourceOptions}
                                                cows={props.cows}
                                                bulls={props.bulls}
                                                preferredBreeds={preferredBreeds}
                                                setPreferredBreeds={setPreferredBreeds}
                                                bangsId={bangsId}
                                                setBangsId={setBangsId}
                                            />
                                        : 
                                            <CalfForm
                                                herd={props.herd}
                                                earTagId={earTagId}
                                                setEarTagId={setEarTagId}
                                                birthDate={birthDate}
                                                setBirthDate={setBirthDate}
                                                breed={breed}
                                                setBreed={setBreed}
                                                registrationNumber={registrationNumber}
                                                setRegistrationNumber={setRegistrationNumber}
                                                registrationName={registrationName}
                                                setRegistrationName={setRegistrationName}
                                                electronicId={electronicId}
                                                setElectronicId={setElectronicId}
                                                sireId={sireId}
                                                setSireId={setSireId}
                                                damId={damId}
                                                setDamId={setDamId}
                                                notes={notes}
                                                sexOptions={props.sexOptions}
                                                sex={sex}
                                                setSex={setSex}
                                                calvingEaseOptions={props.calvingEaseOptions}
                                                calvingEase={calvingEase}
                                                setCalvingEase={setCalvingEase}
                                                setNotes={setNotes}
                                                cows={props.cows}
                                                bulls={props.bulls}
                                                preferredBreeds={preferredBreeds}
                                                setPreferredBreeds={setPreferredBreeds}
                                                birthCircumstanceOptions={props.birthCircumstanceOptions}
                                                birthCircumstance={birthCircumstance}
                                                setBirthCircumstance={setBirthCircumstance}
                                                birthWeight={birthWeight}
                                                setBirthWeight={setBirthWeight}
                                                raisedByFoster={raisedByFoster}
                                                setRaisedByFoster={setRaisedByFoster}
                                                embryoTransplant={embryoTransplant}
                                                setEmbryoTransplant={setEmbryoTransplant}
                                                twinCalf={twinCalf}
                                                setTwinCalf={setTwinCalf}
                                                freeMartin={freeMartin}
                                                setFreeMartin={setFreeMartin}
                                                twinCalfOptions={props.twinCalfOptions}
                                                status={status}
                                                setStatus={setStatus}
                                                additionalCertificateId={additionalCertificateId}
                                                setAdditionalCertificateId={setAdditionalCertificateId}
                                            />
                                    }
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} className="small-container" container alignItems="center" justify="flex-end">
                                                <Link href={`/herds/${props.herd.id}`} className="grey-font">
                                                    Cancel
                                                </Link>
                                                <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                                    <span className="green-font">SAVE</span>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}></Grid>
                </Grid>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}