import React from 'react';
import {
    Typography,
    Grid,
    Link,
    Button,
    List,
    Checkbox,
    ListItem,
    ListItemText,
}
    from '@material-ui/core';

export default function CrossBreedForm(props) {
    const handleCancel = () => {
        //cancel will clears the saved things
        props.setSelectedCrossBreedList([...props.finalCrossBreedList])
        props.setShowCrossBreedsForm(false);
    }

    const toggleCrossBreedSelection = (crossBreed) => () => {
        //onClick for crossbreed checkbox item
        if (includesCrossBreed(crossBreed)) {
            const newSelectedCrossBreedList = props.selectedCrossBreedList.filter((cb => !(cb.sire.id == crossBreed.sire.id && cb.dam.id == crossBreed.dam.id)));
            props.setSelectedCrossBreedList(newSelectedCrossBreedList);
        } else {
            props.setSelectedCrossBreedList([...props.selectedCrossBreedList, crossBreed])
        }
    }

    const includesCrossBreed = (crossBreed) => {
        return props.selectedCrossBreedList.findIndex(selectedCrossBreed => selectedCrossBreed.dam.id == crossBreed.dam.id && selectedCrossBreed.sire.id == crossBreed.sire.id) != -1
    }
    return(
        <Grid item md={7} xs={12} className="location-form-container">
            <Grid container>
                <Grid item xs={12} className="location-form-header-container">
                    <Typography variant="h6" className="location-form-header" gutterBottom>
                        Add crossbreed(s)
                    </Typography>
                </Grid>
                <Grid container className="cross-breeds-options">
                    <Grid container>
                        <Grid item xs={12} className="content-container">
                            Select crossbreed(s) for herd <b>{props.herd.herd_id}</b>
                        </Grid>
                        <Grid item xs={12} container className="content-container">
                            {
                                props.finalSingleBreedList.map((breed) => {
                                    return (
                                        <Grid key={breed.id} item md={3} xs={6}>
                                            <b>{breed.breed_code}</b> {breed.breed_name}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <hr className="horizontal-line" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12} >
                                <Grid container>
                                    {
                                        props.allCrossBreeds.map((crossBreeds, index) => {
                                            return (
                                                <Grid key={index} item md={2} xs={12} className="cb-container">
                                                    <Typography className="ml-15" variant="body1" gutterBottom>
                                                        <b>SIRE/DAM</b>
                                                    </Typography>
                                                    <List>
                                                        {
                                                            crossBreeds.map((crossBreed, index2) => {
                                                                return (
                                                                    <ListItem key={index2} role={undefined} dense button onClick={toggleCrossBreedSelection(crossBreed)}>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={includesCrossBreed(crossBreed)}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                        />
                                                                        <ListItemText id={crossBreed.sire.breed_code}>
                                                                            {crossBreed.sire.breed_code}/{crossBreed.dam.breed_code}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="actions">
                        <Grid container>
                            <Grid item xs={12} container alignItems="center" justify="flex-end">
                                <Link href="#" className="grey-font" onClick={handleCancel} >
                                    Cancel
                                </Link>
                                <Button variant="contained" className="submit-btn" onClick={props.updateFinalCrossBreedList}>
                                    <span className="green-font">SAVE</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}