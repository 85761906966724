import React, { useState, useEffect } from 'react';
import { twoDecimalFormat } from '../../utils/Helper';
export default function SpaCalvingDistribution(props) {

    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/spa_calving_distribution/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <table className="table">
            <thead>
                <tr className="primary-header">
                    <th colSpan={3} className="label-col">
                        Calving Distribution Beginning Calving Date**
                    </th>
                </tr>
                <tr className="secondary-header">
                    <th className="label-col thin-border darker-right">
                        Critical Success Factors
                    </th>
                    <th className="label-col thin-border darker-right">
                        Herd/Group Performance
                    </th>
                    <th className="label-col thin-border">
                        5 Year CHAPS Benchmark
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calves Born During First 21 Days (%)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calving_percentage_21_day)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calving_percentage_21_day)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calves Born During First 42 Days (%)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calving_percentage_42_day)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calving_percentage_42_day)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calves Born During First 63 Days (%)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calving_percentage_63_day)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calving_percentage_63_day)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Calves Born After 63 Days (%)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {twoDecimalFormat(data?.this_period?.calving_percentage_after_63_day)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {twoDecimalFormat(data?.chaps_benchmarks?.calving_percentage_after_63_day)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}