import React from 'react';
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody
}from '@material-ui/core';
import { formatStandardDate } from "../utils/Helper"


export default function TransferRecord(props) {
    return (
        <Grid container className="the-box">
            <Grid item md={12} xs={12} className="the-box-header">
                <div className="left-item">
                    Transfer Record
                </div>
            </Grid>
            <Grid item md={12} xs={12} className="box-table-container">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Transfer Date</TableCell>
                                <TableCell className="header-cell">From Herd</TableCell>
                                <TableCell className="header-cell">To Herd</TableCell>
                                <TableCell className="header-cell">Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.transfers?.map((transfer) => (
                                    <TableRow key={transfer.id}>
                                        <TableCell className="data-cell">
                                            {formatStandardDate(transfer.date)}
                                        </TableCell>
                                        <TableCell className="data-cell">
                                            {transfer.formatted_from_herd}
                                        </TableCell>
                                        <TableCell className="data-cell">
                                            {transfer.formatted_to_herd}
                                        </TableCell>
                                        <TableCell className="data-cell">
                                            {transfer.notes}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }   
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}