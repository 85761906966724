import React, { useState, useEffect } from 'react';
import {
    Grid,
    Link,
    Button
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CalfForm from '../animals/CalfForm';
import Flash from "../layouts/Flash";

export default function Edit(props) {

    const [earTagId, setEarTagId] = useState(props.calf.ear_tag_id);
    const [birthDate, setBirthDate] = useState(props.calf.birth_date);
    const [breed, setBreed] = useState(props.calf.breed);
    const [registrationNumber, setRegistrationNumber] = useState(props.calf.registration_number || "");
    const [registrationName, setRegistrationName] = useState(props.calf.registration_name || "");
    const [electronicId, setElectronicId] = useState(props.calf.electronic_id || "");
    const [sireId, setSireId] = useState(props.calf.sire_id || "");
    const [damId, setDamId] = useState(props.calf.dam_id || "");
    const [preferredBreeds, setPreferredBreeds] = useState(props.preferredBreeds);
    const [notes, setNotes] = useState(props.calf.notes || "");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const [sex, setSex] = useState(props.calf.sex_to_enum);
    const [birthCircumstance, setBirthCircumstance] = useState(props.calf.birth_circumstance || "");
    const [birthWeight, setBirthWeight] = useState(props.calf.birth_weight || "");
    const [calvingEase, setCalvingEase] = useState(props.calf.calving_ease_to_enum || "");
    const [raisedByFoster, setRaisedByFoster] = useState(props.calf.raised_by_foster );
    const [embryoTransplant, setEmbryoTransplant] = useState(props.calf.embryo_transplant);
    const [twinCalf, setTwinCalf] = useState(props.calf.twin_calf_to_enum || "");
    const [freeMartin, setFreeMartin] = useState(props.calf.freemartin);
    const [status, setStatus] = useState(props.calf.status);
    const [additionalCertificateId, setAdditionalCertificateId] = useState(props.calf.additional_certificate_id);


    useEffect(() => {
        if (!preferredBreeds.includes(breed)) {
            setPreferredBreeds([...preferredBreeds, breed])
        }
    }, [breed]);

    const submitForm = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/calves/${props.calf.id}`, {
            method: "PATCH",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                calf: {
                    ear_tag_id: earTagId,
                    birth_date: birthDate,
                    breed: breed,
                    registration_number: registrationNumber,
                    registration_name: registrationName,
                    electronic_id: electronicId,
                    sire_id: sireId,
                    dam_id: damId,
                    notes: notes,
                    sex: sex,
                    birth_circumstance: birthCircumstance,
                    birth_weight: birthWeight,
                    calving_ease: calvingEase,
                    raised_by_foster: raisedByFoster,
                    embryo_transplant: embryoTransplant,
                    twin_calf: twinCalf,
                    freemartin: freeMartin,
                    status: status,
                    additional_certificate_id: additionalCertificateId
                },
            })
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <div className="main-container herd cow content-body">
            <Grid container className="cow-container grid-container">
                <Grid item container xs={12}>
                    <Grid item md={4} xs={12} className="herd-detail-header">
                        <span className="back-arrow"><Link href={`/herds/${props.herd.id}/calves/${props.calf.id}`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Calf {props.calf.ear_tag_id}
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <form onSubmit={submitForm}>
                        <Grid container>
                            <Grid item md={5} xs={12} className="cow-form-container">
                                <CalfForm
                                    herd={props.herd}
                                    earTagId={earTagId}
                                    setEarTagId={setEarTagId}
                                    birthDate={birthDate}
                                    setBirthDate={setBirthDate}
                                    breed={breed}
                                    setBreed={setBreed}
                                    registrationNumber={registrationNumber}
                                    setRegistrationNumber={setRegistrationNumber}
                                    registrationName={registrationName}
                                    setRegistrationName={setRegistrationName}
                                    electronicId={electronicId}
                                    setElectronicId={setElectronicId}
                                    sireId={sireId}
                                    setSireId={setSireId}
                                    damId={damId}
                                    setDamId={setDamId}
                                    notes={notes}
                                    sexOptions={props.sexOptions}
                                    sex={sex}
                                    setSex={setSex}
                                    calvingEaseOptions={props.calvingEaseOptions}
                                    calvingEase={calvingEase}
                                    setCalvingEase={setCalvingEase}
                                    setNotes={setNotes}
                                    cows={props.cows}
                                    bulls={props.bulls}
                                    preferredBreeds={preferredBreeds}
                                    setPreferredBreeds={setPreferredBreeds}
                                    birthCircumstanceOptions={props.birthCircumstanceOptions}
                                    birthCircumstance={birthCircumstance}
                                    setBirthCircumstance={setBirthCircumstance}
                                    birthWeight={birthWeight}
                                    setBirthWeight={setBirthWeight}
                                    raisedByFoster={raisedByFoster}
                                    setRaisedByFoster={setRaisedByFoster}
                                    embryoTransplant={embryoTransplant}
                                    setEmbryoTransplant={setEmbryoTransplant}
                                    twinCalf={twinCalf}
                                    setTwinCalf={setTwinCalf}
                                    freeMartin={freeMartin}
                                    setFreeMartin={setFreeMartin}
                                    twinCalfOptions={props.twinCalfOptions}
                                    status={status}
                                    additionalCertificateId={additionalCertificateId}
                                    setAdditionalCertificateId={setAdditionalCertificateId}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                        <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end">
                                        <Link href={`/herds/${props.herd.id}/calves/${props.calf.id}`} className="grey-font">
                                            Cancel
                                        </Link>
                                        <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                            <span className="green-font">SAVE</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}