import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SummaryOfCalves from './SummaryOfCalves';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { twoDecimalFormat, noDecimalFormat } from '../../utils/Helper';

export default function SummaryOfCowsTable(props) {
    const [data, setData] = useState(null);
    const [sortValue, setSortValue] = useState({ sortBy: "earTagId", order: "ascending" });

    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_cows/?herd_id=${props.herd.id}&as_of_date=${props.as_of_date}&status=${props.status}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                sortCowsAndUpdateState(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (data != null){
            sortCowsAndUpdateState(data);
        }
    }, [sortValue])

    const getCalves = (cow) => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_calves?cow_id=${cow.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                cow.expanded = true;
                cow.calves = result.resource;
                const restOfTheCows = data.filter((cowObj) => cowObj.id != cow.id);
                sortCowsAndUpdateState([...restOfTheCows, cow]);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const shrinkCalves = (cow) => {
        event.preventDefault();
        cow.expanded = false;
        const restOfTheCows = data.filter((cowObj) => cowObj.id != cow.id);
        sortCowsAndUpdateState([...restOfTheCows, cow]);
    }

    const sortCowsAndUpdateState = (cows) => {
        const sortedCows = sortDataBeforeUpdatingState(cows);
        setData(sortedCows);
    }

    const sortDataBeforeUpdatingState = (cowsArray) => {
        let cows = [...cowsArray]
        cows.sort((a, b) => (a.id - b.id));

        if (sortValue.sortBy == "earTagId") {
            cows.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1))
        }
            
        if (sortValue.sortBy == "age") {
            cows.sort((a, b) => (a.age > b.age ? 1 : -1))
        }

        if (sortValue.sortBy == "breed") {
            cows.sort((a, b) => (a.breed > b.breed ? 1 : -1))
        }

        if (sortValue.sortBy == "weight") {
            cows.sort((a, b) => {
                let va = (a.weight === null) ? Infinity : parseInt(a.weight),
                    vb = (b.weight === null) ? Infinity : parseInt(b.weight);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "bcs") {
            cows.sort((a, b) => {
                let va = (a.body_condition_score === null) ? Infinity : parseInt(a.body_condition_score),
                    vb = (b.body_condition_score === null) ? Infinity : parseInt(b.body_condition_score);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "mppa") {
            cows.sort((a, b) => {
                let va = (a.mppa === null) ? Infinity :  parseFloat(a.mppa),
                 vb = (b.mppa === null) ? Infinity : parseFloat(b.mppa);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "noOfCalves") {
            cows.sort((a, b) => (a.calves_born > b.calves_born ? 1 : -1))
        }

        if (sortValue.sortBy == "noOfCalvesWeaned") {
            cows = cows.sort((a, b) => {
                let va = (a.calves_weaned === null) ? Infinity : parseInt(a.calves_weaned),
                    vb = (b.calves_weaned === null) ? Infinity : parseInt(b.calves_weaned);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "avgCalvingInterval") {
            cows.sort((a, b) => {
                let va = (a.average_calving_interval === null) ? Infinity : parseInt(a.average_calving_interval),
                    vb = (b.average_calving_interval === null) ? Infinity : parseInt(b.average_calving_interval);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "calvingDistribution") {
            cows.sort((a, b) => {
                let va = (a.last_calving_distribution === null) ? "" : "" + a.last_calving_distribution,
                    vb = (b.last_calving_distribution === null) ? "" : "" + b.last_calving_distribution;
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "birthWeight") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_birth_weight === null) ? Infinity : parseInt(a.avg_calf_birth_weight),
                    vb = (b.avg_calf_birth_weight === null) ? Infinity : parseInt(b.avg_calf_birth_weight);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "weanWeight") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_weaning_weight === null) ? Infinity : parseInt(a.avg_calf_weaning_weight),
                    vb = (b.avg_calf_weaning_weight === null) ? Infinity : parseInt(b.avg_calf_weaning_weight);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "ageInDays") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_age_in_days === null) ? Infinity : parseInt(a.avg_calf_age_in_days),
                    vb = (b.avg_calf_age_in_days === null) ? Infinity : parseInt(b.avg_calf_age_in_days);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adj205") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_adj_205 === null) ? Infinity : parseInt(a.avg_calf_adj_205),
                    vb = (b.avg_calf_adj_205 === null) ? Infinity : parseInt(b.avg_calf_adj_205);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adj205Ratio") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_adj_205_ratio === null) ? Infinity : parseFloat(a.avg_calf_adj_205_ratio),
                    vb = (b.avg_calf_adj_205_ratio === null) ? Infinity : parseFloat(b.avg_calf_adj_205_ratio);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "frameScore") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_frame_score === null) ? Infinity : parseFloat(a.avg_calf_frame_score),
                    vb = (b.avg_calf_frame_score === null) ? Infinity : parseFloat(b.avg_calf_frame_score);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adg") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_adg === null) ? Infinity : parseInt(a.avg_calf_adg),
                    vb = (b.avg_calf_adg === null) ? Infinity : parseInt(b.avg_calf_adg);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "wda") {
            cows.sort((a, b) => {
                let va = (a.avg_calf_wda === null) ? Infinity : parseInt(a.avg_calf_wda),
                    vb = (b.avg_calf_wda === null) ? Infinity : parseInt(b.avg_calf_wda);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.order == "descending") {
            cows.reverse();
        }

        return cows;
    }

    const updateSortValue = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }
    
    return (
             <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                    :
            <table className="table">
                <thead>
                    <tr className="primary-header">
                        <th colSpan={11} className="label-col darker-right">
                            Cow Data
                        </th>
                        <th colSpan={8} className="label-col">
                            Average Calf Performance Data
                        </th>
                    </tr>
                    <tr className="secondary-header">
                        <th className="label-col thin-border">
                            <AddIcon fontSize="large" />
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("earTagId", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Cow ID
                            {
                                sortValue.sortBy == "earTagId" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "earTagId" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "earTagId" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("age", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Age
                            {
                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "age" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Breed
                            {
                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "breed" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("weight", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Weight (lb)
                            {
                                sortValue.sortBy == "weight" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "weight" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "weight" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("bcs", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Body Condition Score
                            {
                                sortValue.sortBy == "bcs" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "bcs" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "bcs" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("mppa", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            MPPA
                            {
                                sortValue.sortBy == "mppa" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "mppa" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "mppa" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("noOfCalves", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Calves Born
                            {
                                sortValue.sortBy == "noOfCalves" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "noOfCalves" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "noOfCalves" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("noOfCalvesWeaned", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Calves Weaned
                            {
                                sortValue.sortBy == "noOfCalvesWeaned" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "noOfCalvesWeaned" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "noOfCalvesWeaned" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("avgCalvingInterval", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Avg Calving Interval
                            {
                                sortValue.sortBy == "avgCalvingInterval" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "avgCalvingInterval" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "avgCalvingInterval" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col darker-right" onClick={() => updateSortValue("calvingDistribution", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Last Calving Dist
                            {
                                sortValue.sortBy == "calvingDistribution" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "calvingDistribution" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "calvingDistribution" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("birthWeight", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Birth Weight(lb)
                            {
                                sortValue.sortBy == "birthWeight" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "birthWeight" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "birthWeight" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("weanWeight", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Wean Weight(lb)
                            {
                                sortValue.sortBy == "weanWeight" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "weanWeight" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "weanWeight" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("ageInDays", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Age(Days)
                            {
                                sortValue.sortBy == "ageInDays" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "ageInDays" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "ageInDays" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("adj205", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            ADJ 205(lb)
                            {
                                sortValue.sortBy == "adj205" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "adj205" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "adj205" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("adj205Ratio", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            ADJ 205 Ratio
                            {
                                sortValue.sortBy == "adj205Ratio" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "adj205Ratio" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "adj205Ratio" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("frameScore", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            Frame Score
                            {
                                sortValue.sortBy == "frameScore" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "frameScore" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "frameScore" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("adg", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            ADG (lb)
                            {
                                sortValue.sortBy == "adg" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "adg" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "adg" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                        <th className="label-col thin-border" onClick={() => updateSortValue("wda", sortValue.order == "ascending" ? "descending" : "ascending")}>
                            WDA (lb)
                            {
                                sortValue.sortBy == "wda" && sortValue.order == "ascending" &&
                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy == "wda" && sortValue.order == "descending" &&
                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                            }
                            {
                                sortValue.sortBy != "wda" &&
                                <span>
                                    <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                    <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                </span>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map ((cow) => {
                            return(
                                <>
                                    <tr key={cow.id} className="thin-border">
                                        <td className="data-col thin-border">
                                            {
                                                cow.expanded ?
                                                    <RemoveIcon onClick={()=> shrinkCalves(cow) } fontSize="large" />
                                                :
                                                    <AddIcon onClick={() => getCalves(cow)} fontSize="large" />
                                            }
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.ear_tag_id}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.age}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.breed}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.weight}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.body_condition_score}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.mppa)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.calves_born}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.calves_weaned}
                                        </td>
                                        <td className="data-col thin-border">
                                            {cow.average_calving_interval}
                                        </td>
                                        <td className="data-col darker-right">
                                            {cow.last_calving_distribution}
                                        </td>
                                        <td className="data-col thin-border">
                                            {noDecimalFormat(cow.avg_calf_birth_weight)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {noDecimalFormat(cow.avg_calf_weaning_weight)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {noDecimalFormat(cow.avg_calf_age_in_days)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.avg_calf_adj_205)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.avg_calf_adj_205_ratio)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.avg_calf_frame_score)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.avg_calf_adg)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(cow.avg_calf_wda)}
                                        </td>
                                    </tr>
                                    {
                                        cow.expanded && cow.calves.length > 0 && 
                                        <SummaryOfCalves 
                                            calves={cow.calves}
                                        />
                                    }
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
            }
        </>
    )
}