import React from 'react';
import {
    Grid,
    Button,
    Link,
    TextField,
    Radio,
    FormLabel,
    Checkbox
}
    from '@material-ui/core';

export default function CalfWeightForm(props) {
    return (
        <Grid container >
            <Grid item md={12}>
                <form onSubmit={props.submitForm}>
                    <Grid container justify="space-around">
                        <Grid item xs={5} >
                            <TextField
                                fullWidth
                                label="Date"
                                value={props.date}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                type="date"
                                required
                                onChange={(e) => props.setDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label="Hip Height (inches)"
                                type="number"
                                value={props.hipHeight}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                onChange={(e) => props.setHipHeight(e.target.value)}
                            />
                        </Grid>
                        <Grid container justify="space-around">
                            <Grid item xs={5} >
                            </Grid>
                            <Grid item xs={5}>
                                {
                                    props.sex == "Bull" &&
                                    <TextField
                                        fullWidth
                                        label="Scrotal Circumference (cm)"
                                        type="number"
                                        value={props.scrotalCircumference}
                                        variant="outlined"
                                        size="small"
                                        className="form-field"
                                        onChange={(e) => props.setScrotalCircumference(e.target.value)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-around">
                        <Grid item xs={5} >
                            <TextField
                                fullWidth
                                label="Weight (lbs.)"
                                type="number"
                                value={props.weight}
                                variant="outlined"
                                size="small"
                                className="form-field"
                                required
                                onChange={(e) => props.setWeight(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>
                                <legend className="custom-radio-label">
                                    Weight Type
                                </legend>
                            </FormLabel>
                            <div>
                                <span>
                                    <Radio
                                        checked={props.weightType == "Actual"}
                                        onChange={(e) => { props.setWeightType(e.target.value) }}
                                        value="Actual"
                                    /> Actual
                                </span>
                                <span>
                                    <Radio
                                        checked={props.weightType == "Pen Average"}
                                        onChange={(e) => { props.setWeightType(e.target.value) }}
                                        value="Pen Average"
                                    /> Pen Average
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {
                            !props.weaned && 
                            <Grid item style={{ marginLeft: "2.5%" }}>
                                <Checkbox
                                    value={props.wean}
                                    onChange={props.toggleWean}
                                /> Use above date as weaning date
                            </Grid>
                        }
                        
                        {
                            props.wean && 
                            <Grid item style={{ marginLeft: "3.5%" }}>
                                <Checkbox
                                    value={props.sell}
                                    onChange={props.toggleSell}
                                /> Sell
                            </Grid>
                        }
                        
                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item xs={10} className="small-container" container alignItems="center" justify="flex-end">
                            <Link href={`/herds/${props.herd.id}`} className="grey-font">
                                Cancel
                            </Link>
                            <Button variant="contained" type="submit" className="submit-btn animal-submit-btn">
                                <span className="green-font">SAVE</span>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}