import React, { useState, useEffect } from 'react';
import { noDecimalFormat, twoDecimalFormat } from '../../utils/Helper';
export default function ProductionPerformance(props) {

    const [data, setData] = useState(null);

    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/production_performance/?herd_id=${props.herd.id}&year=${props.year}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);


    return (
        <table className="table">
            <thead>
                <tr className="primary-header">
                    <th colSpan={3} className="label-col">
                        Production Performance Measures
                    </th>
                </tr>
                <tr className="secondary-header">
                    <th className="label-col thin-border darker-right">
                        Critical Success Factors
                    </th>
                    <th className="label-col thin-border darker-right">
                        Herd/Group Performance
                    </th>
                    <th className="label-col thin-border">
                        5 Year CHAPS Benchmark
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Average Age at Weaning (days)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {noDecimalFormat(data?.this_period?.average_age_in_days)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {noDecimalFormat(data?.chaps_benchmarks?.average_age_in_days)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Wean Weight - Steers* (lb)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {noDecimalFormat(data?.this_period?.steer_weaning_weight)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {noDecimalFormat(data?.chaps_benchmarks?.steer_weaning_weight)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Wean Weight - Heifers* (lb)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {noDecimalFormat(data?.this_period?.heifers_weaning_weight)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {noDecimalFormat(data?.chaps_benchmarks?.heifers_weaning_weight)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Wean Weight - Bulls* (lb)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {noDecimalFormat(data?.this_period?.bull_weaning_weight)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {noDecimalFormat(data?.chaps_benchmarks?.bull_weaning_weight)}
                    </td>
                </tr>
                <tr className="thin-border">
                    <td className="data-col darker-right">
                        Average Wean Weight (lb)
                    </td>
                    <td className="data-col thin-border darker-right center-text">
                        {noDecimalFormat(data?.this_period?.average_weaning_weight)}
                    </td>
                    <td className="data-col thin-border center-text">
                        {noDecimalFormat(data?.chaps_benchmarks?.average_weaning_weight)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}