import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem
}
    from '@material-ui/core';
import SummaryOfCowsPresentlyInHerdTable from './calving_distribution_reports/SummaryOfCowsPresentlyInHerdTable';
import SummaryOfCowsLeavingTable from './calving_distribution_reports/SummaryOfCowsLeavingTable';
import SummaryOfCalvingDistributionTable from './calving_distribution_reports/SummaryOfCalvingDistributionTable';
import SummaryOfOpenCowsTable from './calving_distribution_reports/SummaryOfOpenCowsTable';
import SummaryOfCalfBirthDateRange from './calving_distribution_reports/SummaryOfCalfBirthDateRange';
import { formatStandardDate } from "../utils/Helper"


export default function CalvingDistributionTable(props) {
    const [selectedHerdId] = useState(props.herd.id);
    const [selectedBtodId] = useState(props.btod.id);
    const [gestationPeriod, setGestationPeriod] = useState(props.default_gestation_period);

    const changeHerd = (herdId) => {
        window.location.href = `/calving_distribution_table/${herdId}`
    }

    const changeBtod = (btodId) => {
        window.location.href = `/calving_distribution_table/${selectedHerdId}?btod_id=${btodId}`
    }
    return (
        <div className="main-container content-body">
            <Grid container justify="space-between" className="report-container">
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    <b>Calving Distribution Table</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.business_name}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>{props.producer.formatted_address}</b>
                                </Typography>
                                <Typography variant="body1" gutterBottom >
                                    <b>Based on Gestation Period of {gestationPeriod} Days</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} className="report-title-header">
                            <Grid item md={12} xs={12}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Herd/Breeding Group: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign: "right"}}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedHerdId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => changeHerd(e.target.value)}
                                        >
                                            {
                                                props.herds.map((herd) => (
                                                    < MenuItem key={herd.id} value={herd.id} style={{ padding: "5px" }}>
                                                        {herd.herd_id}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                            <b>Bull Turn Out Date: </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                        <TextField
                                            select
                                            style={{ width: "200px" }}
                                            value={selectedBtodId}
                                            variant="standard"
                                            size="small"
                                            required
                                            onChange={(e) => changeBtod(e.target.value)}
                                        >
                                            {
                                                props.btods.map((btod) => (
                                                    < MenuItem key={btod.id} value={btod.id} style={{ padding: "5px" }}>
                                                        {formatStandardDate(btod.turn_out_date)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <SummaryOfCalfBirthDateRange 
                                    authenticity_token={props.authenticity_token}
                                    herd={props.herd}
                                    btod={props.btod}
                                    setGestationPeriod={setGestationPeriod}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <Grid container justify="center">
                        <Grid item xs={8}>
                            <Grid container justify="space-between">
                                <Grid item xs={5}>
                                   <SummaryOfCowsPresentlyInHerdTable 
                                        authenticity_token={props.authenticity_token}
                                        herd={props.herd}
                                        btod={props.btod}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <SummaryOfCowsLeavingTable
                                        authenticity_token={props.authenticity_token}
                                        herd={props.herd}
                                        btod={props.btod}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    <SummaryOfCalvingDistributionTable 
                        authenticity_token={props.authenticity_token}
                        herd={props.herd}
                        btod={props.btod}
                    />
                </Grid>
                <Grid item md={12} xs={12} className="row">
                    < SummaryOfOpenCowsTable 
                        authenticity_token={props.authenticity_token}
                        herd={props.herd}
                        btod={props.btod}
                    />
                </Grid>
            </Grid>
        </div>
    )
}