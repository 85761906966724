import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
}
    from '@material-ui/core';
import { formatStandardDate } from "../../utils/Helper"

export default function SummaryOfCalfBirthDateRange(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_calf_birth_date_range/?herd_id=${props.herd.id}&btod_id=${props.btod.id}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
                props.setGestationPeriod(result?.resource?.gestation_period);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                    :
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                <b>Calf Birth Date Range: </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                            <Typography paragraph={false} variant="body1" gutterBottom className="make-right">
                                <b>{formatStandardDate(data.first_birth_date)} - {formatStandardDate(data.last_birth_date)} </b>
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </>
    )
}