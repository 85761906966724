export function getTodayDate(){
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return (
        yyyy + "-" + mm + "-" + dd
    );
}

export function calculateAge (dateOfBirth){
    const diffDate = new Date(Date.now() - Date.parse(dateOfBirth));
    const age = Math.abs(diffDate.getUTCFullYear() - 1970) > 0 ? Math.abs(diffDate.getUTCFullYear() - 1970) : "<1";
    return `${formatStandardDate(dateOfBirth)} (${age})`;
}

export function formateDateObject(date) {
    const formattedDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    return formattedDate;
}

export function twoDecimalFormat(data){
    if (parseFloat(data) >= 0){
        return (Math.round(data * 100) / 100).toFixed(2);
    }else{
        return data;
    }
}

export function oneDecimaFormat(data){
    if (parseFloat(data) >= 0) {
        return (Math.round(data * 100) / 100).toFixed(1);
    } else {
        return data;
    }
}

export function noDecimalFormat(data) {
    if (parseFloat(data) >= 0) {
        return Math.round(data);
    } else {
        return data;
    }
}

export function formatStandardDate(date) {
    const modifiedDate = new Date(date?.replace(/-/g, '/'));
    if (modifiedDate.getFullYear()){
        const formatedDate = ("0" + (modifiedDate.getMonth() + 1)).slice(-2) + "/" +("0" + modifiedDate.getDate()).slice(-2) + "/" + modifiedDate.getFullYear()   ;
        return formatedDate;
    }else{
        return null;
    }
    
}
