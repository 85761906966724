import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem
}
    from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '../shared/Pagination';
import RemoveIcon from '@material-ui/icons/Remove';
import { calculateAge, formatStandardDate } from '../utils/Helper';

export default function Index(props) {
    const [allCalves, setAllCalves] = useState([]);
    const [calves, setCalves] = useState(props.calves);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState({ sortBy: "calfId", order: "ascending" });
    const [statusFilter, setStatusFilter] = useState("All");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages, setTotalPages] = useState(Math.ceil(calves.length / perPage));

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    useEffect(() => {
        const currentOffset = offset * perPage;
        const data = [...allCalves];
        setCalves(data.slice(currentOffset, currentOffset + perPage));
    }, [offset, allCalves]);

    const sortCalves = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    useEffect(() => {
        applyFilter();
    }, [sortValue, statusFilter, searchValue]);

    const applyFilter = () => {
        let allCalves = [...props.calves];
        if(statusFilter != "All"){
            allCalves = allCalves.filter((calf) => calf.status.toLowerCase() == statusFilter.toLowerCase());
        }

        if(searchValue != ""){
            allCalves = allCalves.filter((calf) => calf.ear_tag_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        }

        if (sortValue.sortBy == "calfId") {
            allCalves.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "age") {
            allCalves.sort((a, b) => (a.birth_date < b.birth_date ? 1 : -1));
        }

        if (sortValue.sortBy == "sire") {
            allCalves.sort((a, b) => (a.sire_ear_tag_id > b.sire_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "dam") {
            allCalves.sort((a, b) => (a.dam_ear_tag_id > b.dam_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "breed") {
            allCalves.sort((a, b) => (a.breed > b.breed ? 1 : -1));
        }

        if (sortValue.order == "descending"){
            allCalves.reverse();
        }

        setAllCalves(allCalves);
        setTotalPages(Math.ceil(allCalves.length / perPage));
    }

    return (
        <div className="main-container herd cow content-body">
            <Grid container item xs={12} className="cow-container">
                <Grid container>
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Calves
                            </Grid>

                            <Grid item md={2} xs={12} className="cow-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/herds/${props.herd.id}/animals/new`} className="cow-header-button"><span > <AddIcon className="add-icon" /></span >Add Animal to herd</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="cow-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/herds/${props.herd.id}/calf_cullings/new`} className="cow-header-button"><span > <RemoveIcon className="add-icon" /></span>Cull Animal(s)</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header">
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setSearchValue(e.target.value) }}
                                />
                            </Grid>
                            <Grid item md={2} xs={12} className="herd-detail-header" style={{ paddingRight: "0" }}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Status"
                                    value={statusFilter}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setStatusFilter(e.target.value); }}
                                >
                                    {
                                        ["", "Active", "Culled", "Inactive", "All"].map((option, index) => (
                                            < MenuItem key={index} value={option} style={{ padding: "5px" }}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <TableContainer className="animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" onClick={() => sortCalves("calfId", sortValue.order == "ascending" ? "descending" : "ascending")}>Calf ID
                                            {
                                                sortValue.sortBy == "calfId" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "calfId" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCalves("age", sortValue.order == "ascending" ? "descending" : "ascending")}>Age
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Sex</TableCell>
                                        <TableCell align="center">Birth Weight</TableCell>
                                        <TableCell align="center">Calving Ease</TableCell>
                                        <TableCell align="center" onClick={() => sortCalves("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>Breed
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Birth Circumstance</TableCell>
                                        <TableCell align="center" onClick={() => sortCalves("sire", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire of Calf
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCalves("dam", sortValue.order == "ascending" ? "descending" : "ascending")}>Dam of Calf
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Culled Date</TableCell>
                                        <TableCell align="center">Culled Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        calves.map((calf) => {
                                            return (
                                                <TableRow key={calf.id}>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/herds/${props.herd.id}/calves/${calf.id}` }} className="black-font link" >
                                                        {calf.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calculateAge(calf.birth_date)}</TableCell>
                                                    <TableCell align="center">{calf.sex}</TableCell>
                                                    <TableCell align="center">{calf.birth_weight} lbs.</TableCell>
                                                    <TableCell align="center">{calf.calving_ease}</TableCell>
                                                    <TableCell align="center">{calf.breed}</TableCell>
                                                    <TableCell align="center">{calf.birth_circumstance}</TableCell>
                                                    <TableCell align="center">{calf.sire_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{calf.dam_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{calf.status}</TableCell>
                                                    <TableCell align="center">{formatStandardDate(calf.calf_culling?.culled_date)}</TableCell>
                                                    <TableCell align="center">{calf.calf_culling?.formatted_culled_reason}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                    offset={offset}
                    totalPages={totalPages}
                    handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
        </div>
    )
}