import React, { useState } from 'react';
import {
    Grid,
    Button,
    Link
}
    from '@material-ui/core';
import Flash from "../../layouts/Flash";
import ProducerInviteForm from './ProducerInviteForm';
import { getTodayDate } from "../../utils/Helper";

export default function Index(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [validUntil, setValidUntil] = useState(props.validUntil);
    const [tbybEnvironment] = useState(props.tbybEnvironment);
    const [invitationSentAt] = useState(getTodayDate);

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const submitForm = () => {
        event.preventDefault();
        fetch(`/admin_users/producer_invites`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                producer_invite: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    valid_until: validUntil,
                    invitation_sent_at: invitationSentAt,
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/admin_users/producer_invites`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }
    return (
        <div className="admin-main-container invite-admin">
            <form onSubmit={submitForm}>
                <Grid container justify="center" >
                    <Grid item xs={4} className="invite-admin-form-container">
                        <Grid container justify="center">
                            <Grid item xs={12} className="admin-invite-header green-background-header">
                                Invite Producer to CHAPS
                            </Grid>
                            <ProducerInviteForm
                                firstName={firstName}
                                setFirstName={setFirstName}
                                lastName={lastName}
                                setLastName={setLastName}
                                email={email}
                                setEmail={setEmail}
                                validUntil={validUntil}
                                setValidUntil={setValidUntil}
                                tbybEnvironment={tbybEnvironment}
                            />
                            <Grid item xs={12} className="invite-button-container">
                                <Link href={`/admin_users/producer_invites`} className="cancel-link">
                                    Cancel
                                </Link>
                                <Button variant="contained" type="submit" className="submit-btn">
                                    <span className="green-font">SAVE</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}