import React from 'react';
import {
    Grid,
    Typography
}
    from '@material-ui/core';

export default function HomeInfo() {
    return (
        <Grid item md={6} xs={12}>
            <Grid
                container
                justify="center">
                <Grid item md={8} xs={12} className="summary-box">
                    <Typography variant="h6" gutterBottom className="white-font">
                        Keeping Good Records Using CHAPS
                    </Typography>
                    <div className="yellow-box">
                    </div>
                    <Typography variant="body1" gutterBottom className="white-font">
                        Successful beef production begins with good records. CHAPS (Cow Herd Appraisal Performance Software) is a web-based record-keeping and analysis application for cow-calf operations. CHAPS can help producers track inventories, evaluate cow profitability, and monitor herd reproduction and calf performance.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )

}