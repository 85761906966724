import React from 'react';
import { Link, TextField, Button, Grid } from "@material-ui/core";
import Flash from "../../layouts/Flash";
import HomeInfo from '../../shared/HomeInfo';

export default function Edit(props) {
    return (
        <div className="devise main-container">
            <div className="pages-background sessions">
                <form action="/admin_users/password" method="post">
                    <input
                        type="hidden"
                        name="authenticity_token"
                        value={props.authenticity_token}
                    />
                    <input
                        type="hidden"
                        name="_method"
                        value="put"
                    />
                    <input
                        type="hidden"
                        name="admin_user[reset_password_token]"
                        value={props.reset_password_token}
                    />
                    <Grid container justify="flex-end" >
                        <Grid item md={3} xs={12}>
                            <Grid container className="form-container">
                                <Grid item xs={12} className="header">
                                    ADMIN — RESET YOUR PASSWORD
                                </Grid>
                                <Grid item xs={12} className="field-container">
                                    <TextField
                                        label="Password"
                                        type="password"
                                        required
                                        name="admin_user[password]"
                                        variant="outlined"
                                        className="form-field"
                                    />
                                </Grid>
                                <Grid item xs={12} className="field-container">
                                    <TextField
                                        label="Password Confirmation"
                                        type="password"
                                        required
                                        name="admin_user[password_confirmation]"
                                        variant="outlined"
                                        className="form-field"
                                    />
                                </Grid>
                                <Grid item xs={12} className="submit-btn-container">
                                    <Button variant="contained" className="submit-btn" fullWidth type="submit">Reset Password</Button>
                                </Grid>
                                <Grid item xs={12} className="cancel-link-container">
                                    <Link href="/" color="inherit">
                                        Cancel
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Grid container alignItems="flex-end" className="container">
                    <HomeInfo />
                </Grid>
            </div>
            <Flash severity={props.flash_severity} message={props.flash_message} />
        </div>
    )
}
