import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function ContactUs() {
    return (
        <div className="pages-background2 new-user main-container">
            <Grid container justify="flex-start">
                <Grid item md={7} xs={12} className="white-background">
                    <Grid container className="what-is-chaps">
                        <div>
                            <Typography className="header">
                                Get in touch with us
                            </Typography>
                            <Typography className="content secondary-header">
                                NDSU Extension
                            </Typography>
                            <Typography className="content">
                                1230 Albrecht Blvd, Fargo ND  58102
                            </Typography>
                            <Typography className="content">
                                Mailing address:  NDSU Dept. 7520, PO Box 6050, Fargo, ND  58108-6050
                            </Typography>
                            <Typography className="content secondary-header">
                                Phone Number
                            </Typography>
                            <Typography className="content">
                                701-231-1884
                            </Typography>
                            <Typography className="content secondary-header">
                                Email
                            </Typography>
                            <Typography className="content">
                                ndsu.chaps.support@ndsu.edu
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}