import React, { useState } from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TextField
}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import Flash from "../layouts/Flash";
import {formateDateObject} from "../utils/Helper";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function New(props) {

    const [selectedBulls, setSelectedBulls] = useState([]);
    const [bullTurnOutDate, setBullTurnOutDate] = useState(null);
    const [confirmBullTurnOutDate, setConfirmBullTurnOutDate] = useState(null);
    const [notes, setNotes] = useState("");
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    
    const handleCheckboxClick = (bullId) =>{
        if(selectedBull(bullId)){
            const restOfBullIds = selectedBulls.filter((id) => id != bullId);
            setSelectedBulls(restOfBullIds);
        }else{
            setSelectedBulls([...selectedBulls, bullId])
        }
    }

    const selectedBull = (bullId) => {
        return selectedBulls.findIndex((id) => id == bullId) != -1;
    }

    const submitForm = () => {
        event.preventDefault();
        if (bullTurnOutDate == null || confirmBullTurnOutDate == null){
            setFlashInfo({ flashSeverity: "error", flashMessage: "Dates cannot be null" })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }
        if(selectedBulls.length == 0){
            setFlashInfo({ flashSeverity: "error", flashMessage: "Please select a bull." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        const stringBTOD = formateDateObject(bullTurnOutDate);
        const stringConfirmBTOD = formateDateObject(confirmBullTurnOutDate);

        if(stringBTOD != stringConfirmBTOD){
            setFlashInfo({ flashSeverity: "error", flashMessage: "Bull turn out date and confirm bull turn out date did not match." })
            setShowFlash(true);
            setTimeout(() => { setShowFlash(false) }, 6000);
            return;
        }

        fetch(`/herds/${props.herd.id}/bull_turn_out_dates`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                bull_turn_out_dates: {
                    turn_out_date: stringBTOD,
                    confirm_turn_out_date: stringConfirmBTOD,
                    notes: notes,
                    bull_ids: selectedBulls,
                },
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/herds/${props.herd.id}/`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    const toggleSelectAllBulls = () => {
        if (selectedBulls.length == props.bulls?.length){
            setSelectedBulls([]);
        }else{
            let allBullsIds = props.bulls.map((item) => item.id);
            setSelectedBulls(allBullsIds);
        }
    }

    return (
        <div className="main-container btod content-body">
            <form onSubmit={submitForm}>
                <Grid container item xs={12} className="btod-container">
                    <Grid container>
                        <Grid item md={12} xs={12} className="herd-detail-header">
                            <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> Bull Turn Out Date Herd {props.herd.herd_id}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className="btod-form-el">
                        <Grid item md={3} xs={12} >
                            <Grid container>
                                <Grid item xs={10}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Bull Turn Out Date"
                                            value={bullTurnOutDate}
                                            onChange={(newValue) => {
                                                setBullTurnOutDate(newValue)
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                required
                                                fullWidth
                                            />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={10}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Confirm BTOD"
                                            value={confirmBullTurnOutDate}
                                            onChange={(newValue) => {
                                                setConfirmBullTurnOutDate(newValue)
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                className="form-field"
                                                required
                                                fullWidth
                                            />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Notes"
                                        value={notes}
                                        variant="outlined"
                                        size="small"
                                        className="form-field"
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container >
                                <Grid item md={6} xs={12}>
                                    <TableContainer className="btod-form-table">
                                        <Table aria-label="customized table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" onClick={() => toggleSelectAllBulls()}><CheckIcon fontSize="large" align="center" /></TableCell>
                                                    <TableCell align="center" className="content">Sire ID</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    props.bulls.map((bull) => {
                                                        return (
                                                            <TableRow key={bull.id}>
                                                                <TableCell align="center">
                                                                    <Checkbox
                                                                        checked={selectedBull(bull.id)}
                                                                        onChange={() => handleCheckboxClick(bull.id)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center" >
                                                                    {bull.ear_tag_id}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <Grid container justify="flex-end">
                                <Grid item md={2} xs={12} className="cancel-link">
                                    <Link href={`/herds/${props.herd.id}`} className="grey-font">
                                        Cancel
                                    </Link>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Button variant="contained" type="submit" className="submit-btn">
                                        <span className="green-font">SAVE</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </form>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}