import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function WhatIsChaps(props) {
    return (
        <div className="pages-background2 new-user main-container">
            <Grid container justify="flex-start">
                <Grid item md={7} xs={12} className="white-background">
                    <Grid container className="what-is-chaps">
                        <Typography className="header">
                            What is CHAPS?
                        </Typography>
                        <Typography className="content">
                            The Cow Herd Appraisal Performance Software (CHAPS) is a management tool producers use to collect, store, and evaluate beef production data to make informed management decisions. The program was developed in 1985 by NDSU Extension and the North Dakota Beef Cattle Improvement Association. CHAPS calculates individual herd data according to Beef Improvement Federation guidelines. The data calculated include calving distribution, reproductive percentages (pregnancy, pregnancy loss, calving, calf death loss, weaning, and replacement percentages), calf growth (birth weight, weaning weight, weight per day of age, average daily gain, age at weaning, and pounds weaned per cow exposed) and production data (frame score, cow age, cow weight, and cow body condition). CHAPS data specialists compile herd data to calculate yearly averages and CHAPS benchmarks, calculated as 5-year rolling averages of the yearly herd averages. As industry standards, the CHAPS benchmarks allow producers to examine numerous reproduction and production traits to evaluate herd performance, set goals, and make necessary changes to achieve these goals.
                        </Typography>
                        <Typography className="content">
                            See the following NDSU publications for more information:
                        </Typography>
                        <Typography className="content">
                            Ramsay, J. M., L. Tisor, L. L. Hulsman Hanna, and K. A. Ringwall. 2017. Cow Herd Appraisal Performance Software (CHAPS): 15
                            years of beef production benchmarks. North Dakota Beef Report. AS1862. Pages 21 – 24.
                            <Link className="link" href="https://www.ag.ndsu.edu/publications/livestock/2017-north-dakota-beef-report#section-31">https://www.ag.ndsu.edu/publications/livestock/2017-north-dakota-beef-report#section-31</Link>
                        </Typography>
                        <Typography className="content">
                            Ramsay, J. M., L. L. Hulsman Hanna, and K. A. Ringwall. 2014. Enhancing the identification of error checking in the Cow Herd
                            Appraisal Performance Software (CHAPS) benchmarks using SAS procedures. North Dakota Beef Report. Pages 33 – 36.
                            <Link className="link" href={props.ramsay_et_al}m target="_blank">PDF</Link>
                        </Typography>
                        <Typography className="content">
                            Ramsay, J. M., Z. E. Carlson, L. Tisor, L. L. Hulsman Hanna, K. A. Ringwall, and C. Stoltenow. 2021. Managing what you measure:
                            Current and historical benchmarks the Cow Herd Appraisal Performance Software (CHAPS) provides reliable data for making herd management decisions. 
                            <Link className="link" href="https://www.ndsu.edu/agriculture/ag-hub/publications/managing-what-you-measure-current-and-historical-cow-herd-appraisal-performance">North Dakota Livestock Report (AS2040, September 2021)</Link>
                        </Typography>
                        <Typography className="content">
                            Ringwall, K. A. 2004. BeefTalk 215: <Link className="link" href={props.beef_talk_215} target="_blank">CHAPS: basic information and decision-making power </Link>
                        </Typography>
                        
                        <Grid item xs={12}>
                            <Typography className="content">
                                <Link className="link" target="_blank" href="https://docs.google.com/document/d/1zEGYrSsNAS5ycwpbgJps96AmN9EJDgm-vu3TD-Volk8/edit#">
                                    User Guide
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="content">
                                <Link className="link" target="_blank" href="https://docs.google.com/document/d/1AMZtdOe6b51elChNnvZtCMyG8oUWJHJ0/edit#">
                                    Codes and Equations
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}