import React,{useState} from 'react';
import {
    Grid,
    Link,
    InputLabel,
    Typography,
    Dialog,
    DialogTitle,
    Button,
    DialogActions,
    DialogContentText
}
from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Flash from "../layouts/Flash";
import { calculateAge } from '../utils/Helper';
import BullWeightRecord from './BullWeightRecord';
import BullCullingRecord from './BullCulling';

export default function Show(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        event.preventDefault();
        fetch(`/businesses/${props.bull.business_id}/bulls/${props.bull.id}`, {
            method: "DELETE",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/businesses/${props.bull.business_id}/bulls/`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
        setOpen(false);
    }

    return (
        <div className="main-container herd bull content-body">
            <Grid container className="bull-container grid-container">
                <Grid item container xs={12}>
                    <Grid item md={4} xs={12} className="herd-detail-header">
                        <span className="back-arrow">
                            <Link href={`/businesses/${props.bull.business_id}/bulls/`}><ArrowBackIosIcon /></Link>
                        </span> Bull {props.bull.ear_tag_id}
                        <span className="show-status">
                            {
                                props.bull.status == "Active" ?
                                    <span className="active">Active</span>
                                    :
                                    <span className="culled-or-inactive">{props.bull.status}</span>
                            }
                        </span>
                    </Grid>
                </Grid>
                <Grid item md={10} xs={12} className="table-container bull-show-container">
                    <Grid container>
                        <Grid item xs={5}>
                            <Grid container className="the-box">
                                <Grid item md={12} xs={12} className="the-box-header">
                                    <div className="left-item">
                                        Bull Record
                                    </div>
                                    <div className="right-item">
                                        <span >
                                            <Link href={`/businesses/${props.bull.business_id}/bulls/${props.bull.id}/edit`} className="teeth-white-icon">
                                                <CreateIcon fontSize="small" />
                                            </Link>
                                        </span>
                                        {
                                            props.bull.deleteable &&
                                            <span style={{ marginLeft: "20px" }}>
                                                <Link href="#" onClick={handleClickOpen} className="teeth-white-icon">
                                                    <DeleteIcon fontSize="small" />
                                                </Link>
                                            </span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item md={5} xs={12} className="the-box-content">
                                    {bullInfoSection("Bull ID", props.bull.ear_tag_id)}
                                    {bullInfoSection("Bull Birth Date", calculateAge(props.bull.birth_date))}
                                    {bullInfoSection("Bull Breed", props.bull.breed)}
                                    {bullInfoSection("Registration Number", props.bull.registration_number)}
                                    {bullInfoSection("Registration Name", props.bull.registration_name)}
                                    {bullInfoSection("Electronic ID Number", props.bull.electronic_id)}
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    {bullInfoSection("Sire of Sire", props.bull.sire_ear_tag_id)}
                                    {bullInfoSection("Dam of Sire", props.bull.dam_ear_tag_id)}
                                    {bullInfoSection("Source", props.bull.source)}
                                    {bullInfoSection("Use", props.bull.use)}
                                    {bullInfoSection("Status", props.bull.status)}
                                </Grid>
                                <Grid item xs={12} className="the-box-content">
                                    <InputLabel className="show-label">
                                        Notes
                                    </InputLabel>
                                    <Typography variant="body1" gutterBottom className="show-value">
                                        {props.bull.notes}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className="the-right-box">
                            {
                                props.bull.bull_weights?.length > 0 &&
                                    BullWeightRecord(props)
                            }
                            {
                                props.bull.bull_culling &&
                                    BullCullingRecord(props)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div className="delete-modal">
                <Dialog 
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Delete Bull {props.bull.ear_tag_id}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={handleDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}

const bullInfoSection = (label, value) => {
    return (
        <Grid container className="bull-info-section">
            <Grid item xs={12}>
                <InputLabel className="show-label">
                    {label}
                </InputLabel>
                <Typography variant="body1" gutterBottom className="show-value">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}