import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
}
    from '@material-ui/core';
import Pagination from '../../shared/Pagination';

export default function Index(props) {
    const [businesses, setBusinesses] = useState(props.businesses);
    const [searchValue, setSearchValue] = useState("");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages] = useState(Math.ceil(businesses.length / perPage));

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    const search = (e) => {
        const searchParam = e.target.value.toLowerCase();
        const filteredBusinesses = props.businesses.filter((business) => business.business_name.toLowerCase().includes(searchParam) || business.owner_names.toLowerCase().includes(searchParam) || business.formatted_address.toLowerCase().includes(searchParam));
        setSearchValue(searchParam);
        setBusinesses(filteredBusinesses);
    }

    useEffect(() => {
        if (totalPages > 1) {
            const currentOffset = offset * perPage;
            const data = [...props.businesses];
            setBusinesses(data.slice(currentOffset, currentOffset + perPage));
        }
    }, [offset]);

    return (
        <div className="admin-main-container">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item xs={3}>
                            <p className="header-text">Producers</p>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="search-form-field"
                                    onChange={(e) => { search(e) }}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className="table-container">
                        <Table className="table">
                            <TableHead className="table-head">
                                <TableRow className="table-head-row">
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Business Name
                                    </TableCell>
                                    <TableCell>
                                        Address
                                    </TableCell>
                                    <TableCell>
                                        Impersonate User
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    businesses.map((business) => (
                                        <TableRow key={business.id} className="data-row">
                                            <TableCell onClick={() => { window.location.href = `/admin_users/producers/${business.id}` }} className="table-cell link">
                                                {business.owner_names}
                                            </TableCell>
                                            <TableCell onClick={() => { window.location.href = `/admin_users/producers/${business.id}` }} className="table-cell link" >
                                                {business.business_name}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                {business.formatted_address}
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                <Button href={`/admin_users/become/${business.first_owner_id}`} className="become-user-btn">
                                                    Become User
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Pagination
                offset={offset}
                totalPages={totalPages}
                handlePaginationPageChange={handlePaginationPageChange} />
        </div>
    )
}