import React, { useState } from 'react';
import {
    Grid,
    Link,
    InputLabel,
    Typography,
    Dialog,
    DialogTitle,
    Button,
    DialogActions,
    Divider,
    DialogContentText

}
    from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../layouts/Flash";
import { calculateAge } from '../utils/Helper';
import WeightRecord from './WeightRecord';
import WeaningRecord from './WeaningRecord';
import CullingRecord from './CullingRecord';
import ReplacementRecord from './ReplacementRecord'
import TransferRecord from '../shared/TransferRecord';

export default function Show(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/calves/${props.calf.id}`, {
            method: "DELETE",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/herds/${props.herd.id}/calves/`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
        setOpen(false);
    }

    return (
        <div className="main-container herd cow content-body">
            <Grid container className="cow-container grid-container">
                <Grid item container xs={12}>
                    <Grid item xs={12} className="herd-detail-header">
                        <span className="back-arrow"><Link href={`/herds/${props.herd.id}/calves`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Calf {props.calf.ear_tag_id} 
                        <span className="show-status">
                            {
                                props.calf.status == "Active" ?
                                    <span className="active">Active</span>
                                    :
                                    <span className="culled-or-inactive">{props.calf.status}</span>
                            }
                        </span>
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className="calf-show-container table-container">
                    <Grid container>
                        <Grid item xs={5}>
                            <Grid container className="the-box">
                                <Grid item md={12} xs={12} className="the-box-header">
                                    <div className="left-item">
                                        Birth Record
                                    </div>
                                    <div className="right-item">
                                        <span >
                                            <Link href={`/herds/${props.herd.id}/calves/${props.calf.id}/edit`} className="teeth-white-icon">
                                                <CreateIcon fontSize="small" />
                                            </Link>
                                        </span>
                                        {
                                            props.calf.deleteable && 
                                            <span style={{marginLeft: "20px"}}>
                                                <Link href="#" onClick={handleClickOpen} className="teeth-white-icon">
                                                    <DeleteIcon fontSize="small"/>
                                                </Link>
                                            </span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12} className="the-box-content">
                                    {calInfoSection("Birth Circumstance", props.calf.birth_circumstance)}
                                    {calInfoSection("Calf ID", props.calf.ear_tag_id)}
                                    {calInfoSection("Calf Birth Date", calculateAge(props.calf.birth_date))}
                                    {calInfoSection("Calf Birth Weight", props.calf.birth_weight && `${props.calf.birth_weight} lbs.`)}
                                    {calInfoSection("Sex of Calf", props.calf.sex)}
                                    {calInfoSection("Calving Ease", props.calf.calving_ease)}
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {calInfoSection("Sire of Calf", props.calf.sire_ear_tag_id)}
                                    {calInfoSection("Dam of Calf", props.calf.dam_ear_tag_id)}
                                    {calInfoSection("Calf Breed", props.calf.breed)}
                                    {calInfoSection("Registration Name", props.calf.registration_name)}
                                    {calInfoSection("Registration Number", props.calf.registration_number)}
                                    {calInfoSection("Electronic ID Number", props.calf.electronic_id)}
                                    {calInfoSection("Additional Ceritficate ID", props.calf.additional_certificate_id)}
                                </Grid>
                                <Divider orientation="vertical" className="vertical-divider" flexItem />
                                <Grid item md={3} xs={12}>
                                    <Grid container className="calf-info-section">
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className="green-font" gutterBottom>
                                                <b>Uncommon Situations</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {calInfoSection("Calf raised by foster cow", props.calf.raised_by_foster ? "Yes" : "No")}
                                    {calInfoSection("Embryo Transplant", props.calf.embryo_transplant ? "Yes" : "No")}
                                    {calInfoSection("Twin", props.calf.twin_calf)}
                                    {calInfoSection("Freemartin", props.calf.freemartin ? "Yes" : "No")}
                                </Grid>
                                <Grid item xs={12} className="the-box-content">
                                    <InputLabel className="show-label">
                                        Notes
                                    </InputLabel>
                                    <Typography variant="body1" gutterBottom>
                                        {props.calf.notes}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className="the-right-box">
                            { 
                                props.calf.calf_weights?.length > 0 &&
                                    WeightRecord(props)
                            }
                            {
                                props.calf.weaning &&
                                    WeaningRecord(props)
                            }
                            {
                                props.calf.replacement &&
                                    ReplacementRecord(props)
                            }
                            {
                                props.calf.calf_culling &&
                                    CullingRecord(props)
                            }
                            {
                                props.calf.calf_transfers.length > 0 &&
                                <TransferRecord
                                    transfers={props.calf.calf_transfers}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Delete Calf {props.calf.ear_tag_id}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={handleDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}

const calInfoSection = (label, value) => {
    return(
        <Grid container className="calf-info-section">
            <Grid item xs={12}>
                <InputLabel className="show-label">
                    {label}
                </InputLabel>
                <Typography variant="body1" gutterBottom className="show-value">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}