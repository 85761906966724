import React, {useState, useEffect} from 'react';
import {
    Grid,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem
}
from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import Pagination from '../shared/Pagination';
import { calculateAge, formatStandardDate } from '../utils/Helper';

export default function Index(props) {
    const [allCows, setAllCows] = useState([]);
    const [cows, setCows] = useState(props.cows);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState({sortBy: "cowId", order: "ascending"});
    const [statusFilter, setStatusFilter] = useState("Both");

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);
    const [totalPages, setTotalPages] = useState(Math.ceil(cows.length / perPage));

    const handlePaginationPageChange = (offsetVal) => {
        setOffset(offsetVal);
    }

    useEffect(() => {
        const currentOffset = offset * perPage;
        const data = [...allCows];
        setCows(data.slice(currentOffset, currentOffset + perPage));
    }, [offset, allCows]);

    const sortCows = (sortBy, order) => {
        setSortValue({sortBy: sortBy, order: order})
    }

    useEffect(() => {
        applyFilter();       
    }, [sortValue, statusFilter, searchValue]);

    const applyFilter = () => {
        let allCows = [...props.cows];
        if (statusFilter != "Both") {
            allCows = allCows.filter((cow) => cow.status.toLowerCase() == statusFilter.toLowerCase());
        }

        if (searchValue != "") {
            allCows = allCows.filter((cow) => cow.ear_tag_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        }

        if (sortValue.sortBy == "cowId") {
            allCows.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "age") {
            allCows.sort((a, b) => (a.birth_date < b.birth_date ? 1 : -1));
        }

        if (sortValue.sortBy == "sire") {
            allCows.sort((a, b) => (a.sire_ear_tag_id > b.sire_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "dam") {
            allCows.sort((a, b) => (a.dam_ear_tag_id > b.dam_ear_tag_id ? 1 : -1));
        }

        if (sortValue.sortBy == "breed") {
            allCows.sort((a, b) => (a.breed > b.breed ? 1 : -1));
        }

        if (sortValue.order == "descending") {
            allCows.reverse();
        }

        setAllCows(allCows);
        setTotalPages(Math.ceil(allCows.length / perPage));
    }

    return (
        <div className="main-container herd cow content-body">
            <Grid container item xs={12} className="cow-container">
                <Grid container>
                    <Grid item md={11} xs={12}>
                        <Grid container>
                            <Grid item md={4} xs={12} className="herd-detail-header">
                                <span className="back-arrow"><Link href={`/herds/${props.herd.id}`}><ArrowBackIosIcon /></Link></span> Herd {props.herd.herd_id} — Cows
                            </Grid>

                            <Grid item md={2} xs={12} className="cow-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/herds/${props.herd.id}/animals/new`} className="cow-header-button"><span > <AddIcon className="add-icon" /></span>Add Animal to herd</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="cow-button-container" >
                                <Grid container justify="flex-end" >
                                    <Button href={`/herds/${props.herd.id}/bull_turn_out_dates/new`} className="cow-header-button"><span > <AddIcon className="add-icon" /></span>Add Bull Turn Out</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={2} xs={12} className="cow-button-container" >
                                <Grid container justify="flex-end">
                                    <Button href={`/herds/${props.herd.id}/cow_cullings/new`} className="cow-header-button"><span > <RemoveIcon className="add-icon" /></span>Cull Animal(s)</Button>
                                </Grid>
                            </Grid>
                            <Grid item md={1} xs={12} className="herd-detail-header">
                                <TextField
                                    fullWidth
                                    value={searchValue}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setSearchValue(e.target.value) }}
                                />
                            </Grid>
                            <Grid item md={1} xs={12} className="herd-detail-header" style={{paddingRight: "0"}}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Status"
                                    value={statusFilter}
                                    variant="outlined"
                                    placeholder="Search"
                                    size="small"
                                    className="form-field"
                                    onChange={(e) => { setStatusFilter(e.target.value); }}
                                >
                                    {
                                        ["", "Active", "Culled", "Both"].map((option, index) => (
                                            < MenuItem key={index} value={option} style={{ padding: "5px" }}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item md={11} xs={12}>
                        <TableContainer className="animals-table">
                            <Table aria-label="customized table">
                                <TableHead className="animals-table-header">
                                    <TableRow>
                                        <TableCell align="center" onClick={() =>sortCows("cowId", sortValue.order == "ascending" ? "descending" : "ascending")}>Cow ID
                                            {
                                                sortValue.sortBy == "cowId" && sortValue.order == "ascending" &&
                                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "cowId" && sortValue.order == "descending" &&
                                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("age", sortValue.order == "ascending" ? "descending" : "ascending")}>Age
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "age" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("sire", sortValue.order == "ascending" ? "descending" : "ascending")}>Sire of Cow
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "sire" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("dam", sortValue.order == "ascending" ? "descending" : "ascending")}>Dam of Cow
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "dam" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center" onClick={() => sortCows("breed", sortValue.order == "ascending" ? "descending" : "ascending")}>Breed
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "ascending" &&
                                                <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                            {
                                                sortValue.sortBy == "breed" && sortValue.order == "descending" &&
                                                <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                            }
                                        </TableCell>
                                        <TableCell align="center">Source</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Culled Date</TableCell>
                                        <TableCell align="center">Culled Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        cows.map((cow) => {
                                            return (
                                                <TableRow key={cow.id}>
                                                    <TableCell align="center" onClick={() => { window.location.href = `/herds/${props.herd.id}/cows/${cow.id}` }} className="black-font link" >
                                                        {cow.ear_tag_id}
                                                    </TableCell>
                                                    <TableCell align="center">{calculateAge(cow.birth_date)}</TableCell>
                                                    <TableCell align="center">{cow.sire_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{cow.dam_ear_tag_id}</TableCell>
                                                    <TableCell align="center">{cow.breed}</TableCell>
                                                    <TableCell align="center">{cow.source}</TableCell>
                                                    <TableCell align="center">{cow.status}</TableCell>
                                                    <TableCell align="center">{formatStandardDate(cow.cow_culling?.culled_date)}</TableCell>
                                                    <TableCell align="center">{cow.cow_culling?.formatted_culled_reason}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Pagination
                offset={offset}
                totalPages={totalPages}
                handlePaginationPageChange={handlePaginationPageChange} />
            </Grid>
        </div>
    )
}