import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { twoDecimalFormat, formatStandardDate } from '../../utils/Helper';


export default function SummaryOfCalvesData(props) {
    const [data, setData] = useState(null);
    const [sortValue, setSortValue] = useState({ sortBy: "earTagId", order: "ascending" });

    useEffect(() => {
        event.preventDefault();
        fetch(`/reports/table/summary_of_calves_data/?herd_id=${props.herd.id}&year=${props.year}&sex=${props.sex}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                setData(result.resource);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (data != null) {
            sortCalvesAndUpdateState(data);
        }
    }, [sortValue])


    const updateSortValue = (sortBy, order) => {
        setSortValue({ sortBy: sortBy, order: order })
    }

    const sortCalvesAndUpdateState = (calves) => {
        const sortedCalves = sortDataBeforeUpdatingState(calves);
        setData(sortedCalves);
    }

    const sortDataBeforeUpdatingState = (calvesArray) => {
        let calves = [...calvesArray]
        calves.sort((a, b) => (a.id - b.id));

        if (sortValue.sortBy == "earTagId") {
            calves.sort((a, b) => (a.ear_tag_id > b.ear_tag_id ? 1 : -1))
        }

        if (sortValue.sortBy == "birthDate") {
            calves.sort((a, b) => (a.birth_date > b.birth_date ? 1 : -1))
        }

        if (sortValue.sortBy == "CalvingEase") {
            calves.sort((a, b) => (a.calving_ease > b.calving_ease ? 1 : -1))
        }

        if (sortValue.sortBy == "sex") {
            calves.sort((a, b) => (a.sex > b.sex ? 1 : -1))
        }

        if (sortValue.sortBy == "birthCircumstance") {
            calves.sort((a, b) => (a.birth_circumstance > b.birth_circumstance ? 1 : -1))
        }

        if (sortValue.sortBy == "birthWeight") {
            calves.sort((a, b) => {
                let va = (a.birth_weight === null) ? Infinity : parseInt(a.birth_weight),
                    vb = (b.birth_weight === null) ? Infinity : parseInt(b.birth_weight);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "weanWeight") {
            calves.sort((a, b) => {
                let va = (a.weaning_weight === null) ? Infinity : parseInt(a.weaning_weight),
                    vb = (b.weaning_weight === null) ? Infinity : parseInt(b.weaning_weight);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "ageInDays") {
            calves.sort((a, b) => {
                let va = (a.weaning_age_in_days === null) ? Infinity : parseInt(a.weaning_age_in_days),
                    vb = (b.weaning_age_in_days === null) ? Infinity : parseInt(b.weaning_age_in_days);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adjusted205") {
            calves.sort((a, b) => {
                let va = (a.adjusted_205 === null) ? Infinity : parseInt(a.adjusted_205),
                    vb = (b.adjusted_205 === null) ? Infinity : parseInt(b.adjusted_205);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adjusted205Ratio") {
            calves.sort((a, b) => {
                let va = (a.adjusted_205_ratio === null) ? Infinity : parseInt(a.adjusted_205_ratio),
                    vb = (b.adjusted_205_ratio === null) ? Infinity : parseInt(b.adjusted_205_ratio);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "frameScore") {
            calves.sort((a, b) => {
                let va = (a.frame_score === null) ? Infinity : parseInt(a.frame_score),
                    vb = (b.frame_score === null) ? Infinity : parseInt(b.frame_score);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "adg") {
            calves.sort((a, b) => {
                let va = (a.adg === null) ? Infinity : parseInt(a.adg),
                    vb = (b.adg === null) ? Infinity : parseInt(b.adg);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "wda") {
            calves.sort((a, b) => {
                let va = (a.wda === null) ? Infinity : parseInt(a.wda),
                    vb = (b.wda === null) ? Infinity : parseInt(b.wda);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "damId") {
            calves.sort((a, b) => {
                let va = (a.dam_id === null) ? Infinity : parseInt(a.dam_id),
                    vb = (b.dam_id === null) ? Infinity : parseInt(b.dam_id);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "damBreed") {
            calves.sort((a, b) => {
                let va = (a.dam_breed === null) ? Infinity : parseInt(a.dam_breed),
                    vb = (b.dam_breed === null) ? Infinity : parseInt(b.dam_breed);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "damAge") {
            calves.sort((a, b) => {
                let va = (a.dam_age === null) ? Infinity : parseInt(a.dam_age),
                    vb = (b.dam_age === null) ? Infinity : parseInt(b.dam_age);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "sireId") {
            calves.sort((a, b) => {
                let va = (a.sire_id === null) ? Infinity : parseInt(a.sire_id),
                    vb = (b.sire_id === null) ? Infinity : parseInt(b.sire_id);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.sortBy == "sireBreed") {
            calves.sort((a, b) => {
                let va = (a.sire_breed === null) ? Infinity : parseInt(a.sire_breed),
                    vb = (b.sire_breed === null) ? Infinity : parseInt(b.sire_breed);
                return va > vb ? 1 : (va === vb ? 0 : -1);
            })
        }

        if (sortValue.order == "descending") {
            calves.reverse();
        }
        return calves;
    }

    return (
        <>
            {
                data == null ?
                    <p>
                        Loading
                    </p>
                    :
                <table className="table">
                    <thead>
                        <tr className="primary-header">
                            <th colSpan={6} className="label-col darker-right">
                                Birth Data
                            </th>
                            <th colSpan={7} className="label-col darker-right">
                                Weaning Data
                            </th>
                            <th colSpan={5} className="label-col">
                                Dam/Sire Data
                            </th>
                        </tr>
                        <tr className="secondary-header">
                                <th className="label-col thin-border" onClick={() => updateSortValue("earTagId", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Calf ID
                                {
                                    sortValue.sortBy == "earTagId" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "earTagId" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "earTagId" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" style={{ width: "7%" }} onClick={() => updateSortValue("birthDate", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Date  
                                {
                                    sortValue.sortBy == "birthDate" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "birthDate" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "birthDate" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }   
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("calvingEase", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Ease
                                {
                                    sortValue.sortBy == "calvingEase" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "calvingEase" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "calvingEase" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                } 
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("sex", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Sex
                                {
                                    sortValue.sortBy == "sex" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "sex" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "sex" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("birthCircumstance", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Circumstance
                                {
                                    sortValue.sortBy == "birthCircumstance" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "birthCircumstance" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "birthCircumstance" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border darker-right" onClick={() => updateSortValue("birthWeight", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Weight (lb)
                                {
                                    sortValue.sortBy == "birthWeight" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "birthWeight" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "birthWeight" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("weanWeight", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Weight (lb)
                                {
                                    sortValue.sortBy == "weanWeight" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "weanWeight" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "weanWeight" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("ageInDays", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Age (Days)
                                {
                                    sortValue.sortBy == "ageInDays" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "ageInDays" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "ageInDays" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("adjusted205", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Adjusted 205 (lb)
                                {
                                    sortValue.sortBy == "adjusted205" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "adjusted205" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "adjusted205" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("adjusted205Ratio", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Adjusted 205 Ratio
                                {
                                    sortValue.sortBy == "adjusted205Ratio" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "adjusted205Ratio" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "adjusted205Ratio" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("frameScore", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Frame Score
                                {
                                    sortValue.sortBy == "frameScore" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "frameScore" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "frameScore" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("adg", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                ADG (lb)
                                {
                                    sortValue.sortBy == "adg" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "adg" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "adg" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border darker-right" onClick={() => updateSortValue("wda", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                WDA (lb)
                                {
                                    sortValue.sortBy == "wda" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "wda" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "wda" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("damId", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Dam ID
                                {
                                    sortValue.sortBy == "damId" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "damId" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "damId" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("damBreed", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Dam Breed
                                {
                                    sortValue.sortBy == "damBreed" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "damBreed" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "damBreed" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("damAge", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Dam Age (yr)
                                {
                                    sortValue.sortBy == "damAge" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "damAge" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "damAge" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col darker-right" onClick={() => updateSortValue("sireId", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Sire ID
                                {
                                    sortValue.sortBy == "sireId" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "sireId" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "sireId" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                            <th className="label-col thin-border" onClick={() => updateSortValue("sireBreed", sortValue.order == "ascending" ? "descending" : "ascending")}>
                                Sire Breed
                                {
                                    sortValue.sortBy == "sireBreed" && sortValue.order == "ascending" &&
                                    <span><ArrowDropUpIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy == "sireBreed" && sortValue.order == "descending" &&
                                    <span><ArrowDropDownIcon fontSize="small" className="down-arrow" /></span>
                                }
                                {
                                    sortValue.sortBy != "sireBreed" &&
                                    <span>
                                        <ArrowDropUpIcon fontSize="small" className="down-arrow-disabled" />
                                        <ArrowDropDownIcon fontSize="small" className="down-arrow-disabled" />
                                    </span>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((calf) => {
                                return(
                                    <tr key={calf.id} className="thin-border">
                                        <td className="data-col thin-border">
                                            {calf?.ear_tag_id}
                                        </td>
                                        <td className="data-col thin-border">
                                            {formatStandardDate(calf?.birth_date)}                                       
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.calving_ease}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.sex}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.birth_circumstance}
                                        </td>
                                        <td className="data-col darker-right">
                                            {calf?.birth_weight}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.weaning_weight}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.weaning_age_in_days}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.adjusted_205}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(calf?.adjusted_205_ratio)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(calf?.frame_score)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {twoDecimalFormat(calf?.adg)}
                                        </td>
                                        <td className="data-col darker-right">
                                            {twoDecimalFormat(calf?.wda)}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.dam_id}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.dam_breed}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.dam_age}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.sire_id}
                                        </td>
                                        <td className="data-col thin-border">
                                            {calf?.sire_breed}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </>
    )
}