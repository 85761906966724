import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HomeInfo from '../shared/HomeInfo';

export default function Index(){
    return(
        <div className="pages-background main-container">
            <Grid container alignItems="flex-end" className="container">
                <HomeInfo />
                <Grid item md={6} xs={12} >
                    <Grid
                    container
                    justify="center">
                        <Grid item md={4} xs={12} className="get-started-box">
                            <Typography variant="h5" gutterBottom className="green-font bold-font">
                                Are you a new user?
                            </Typography>
                            <a
                                variant="h4"
                                href="/users/new"
                                className="get-started-link bold-font"
                            >
                                GET STARTED 
                            </a><span className="right-caret"></span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}