import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Link,
    Button,
    InputLabel,
}
from '@material-ui/core';
import Flash from "../layouts/Flash";
import AddIcon from '@material-ui/icons/Add';
import CrossBreedForm from './CrossBreedFrom';
import SingleBreedForm from './SingleBreedForm';

export default function New(props) {
    //selectedSingleBreedList is used as temprorary holding area of selected single breed
    //finalSingleBreedList is when single breeds are saved, selectedSingleBreedList converts into finalSingleBreedList
    
    //crossBreedCombinationList holds all possibleCombination of crossBreeds from finalSingleBreedList
    //propsBreeds is state, initally holds all breeds from props and get updated as filter applies

    //allBreeds are structured for multicolumn list eg. [[{id: 1, breed_code: "AN", breed_name: "Angus"}]]
    //allCrossBreeds are grouped by sire breed eg[[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}]]

    const [showSingleBreedsForm, setShowSingleBreedsForm] = useState(false);
    const [showCrossBreedsForm, setShowCrossBreedsForm] = useState(false);
    const [selectedSingleBreedList, setSelectedSingleBreedList] = useState([]); 
    //eg: [{id: 1, breed_code: "AN", breed_name: "Angus"}], used to temproraliy save single breed, persists on cancel but not saved
    
    const [finalSingleBreedList, setFinalSingleBreedList] = useState([]);
    //eg: [{id: 1, breed_code: "AN", breed_name: "Angus"}], not update until saved, used for cross breed combination construction
    
    const [crossBreedCombinationList, setCrossBreedCombinationList] = useState([]); 
    // eg: [{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, dam: {id: 2, breed_code: "LM", breed_name: "limousin"}}], array of all possible combination of cross breed
    //crossBreedCombinationList feeds to allCrossBreed, which is structured for mulicolumn lists
    
    const [selectedCrossBreedList, setSelectedCrossBreedList] = useState([]);
    // this array is temproray holding for selected cross breeds, canceling the form persists the selected option

    const [finalCrossBreedList, setFinalCrossBreedList] = useState([]);
    //until user hit saved selectedCrossBreed wont turn into finalCross Breed, only the item in finalCrossBreed will submitted

    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);
    const [propsBreeds, setPropsBreeds] = useState(props.breeds);
    //propsBreeds is an array of all single breeds, [{id: 2, breed_code: "LM", breed_name: "limousin"}, {id: 1, breed_code: "AN", breed_name: "Angus"}]
    //the reason for propsBreeds is feeder for allBreeds which is designed for multicolumn grid

    const [allBreeds, setAllBreeds] = useState([]);
    //this converts array of breeds into array of array of breeds so that we can display in multiple columns
    //eg [[{id: 1, breed_code: "AN", breed_name: "Angus"}], [{id: 1, breed_code: "AN", breed_name: "Angus"}], [{id: 1, breed_code: "AN", breed_name: "Angus"}]]

    const [allCrossBreeds, setAllCrossBreeds] = useState([]);
    //this convers array of crossBreeds into array or array of crossBreed so that we can display in multiple column
    //eg: [[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}],[{sire: {id: 1, breed_code: "AN", breed_name: "Angus"}, {dam: {id: breed_code: "LM", breed_name: "limousin"}}}]]

    const updateFinalBreedList = () =>{
        //calls on save in single breed form
        //converts temporary saving SelectedSingleBreedList into finalSingleBreedList that feeds cross breed list
        setFinalSingleBreedList(selectedSingleBreedList);
        setShowSingleBreedsForm(false);
    }

    useEffect(() => {
        //after finalSingleBreedList update, we generate all the possible combinations of crossBreeds
        const crossBreedsOptions = []
        finalSingleBreedList.map((sire) => {
            finalSingleBreedList.map((dam) => {
                if(sire.id != dam.id)
                {
                    crossBreedsOptions.push({
                        sire: sire,
                        dam: dam
                    })
                }
            });
        });
        setCrossBreedCombinationList(crossBreedsOptions);
    }, [finalSingleBreedList]);

    useEffect(() => {
        //after array of crossBreed update we group them by sire breed_code and convert form array of crossBreed to array of array of crossBreed for multicoulumn view
        // [] => [[][][]]
        const crossBreedsArray = [...crossBreedCombinationList];
        const dividedCrossBreeds = crossBreedsArray.reduce((r,a) =>{
            r[a.sire.breed_code] = [...r[a.sire.breed_code] || [], a];
            return r;
        }, {});
        setAllCrossBreeds(Object.values(dividedCrossBreeds));
    }, [crossBreedCombinationList]);

    useEffect(() => {
        //this convers array of breed into array of array of breeds
        //the sub array has almost equal length so that we can show that in muliple column
        // [] => [[][][]]
        const breedsArray = [...propsBreeds];
        const dividedBreeds = [];
        for (let i = 3; i > 0; i--) {
            dividedBreeds.push(breedsArray.splice(0, Math.ceil(breedsArray.length / i)))
        }
        dividedBreeds[dividedBreeds.length - 1].push(...props.hidden_breeds)
        setAllBreeds(dividedBreeds);
    }, [propsBreeds]);

    const updateFinalCrossBreedList = () =>{
        //when on save on finalCrossBreed form we updated the finalCrossBreedList array and get ready for submission
        setFinalCrossBreedList(selectedCrossBreedList);
        setShowCrossBreedsForm(false);
    }

    const filterKeys = (keys) =>() => {
        let filteredBreeds = [];
        if(keys == "AG"){
            filteredBreeds = props.breeds.filter((breed) => ["A", "B", "C", "D", "E", "F", "G"].includes(breed.breed_code[0]));
        }else if(keys == "HP"){
            filteredBreeds = props.breeds.filter((breed) => ["H", "I", "J", "K", "L", "M", "N", "O", "P"].includes(breed.breed_code[0]));
        }else if(keys == "RZ"){
            filteredBreeds = props.breeds.filter((breed) => ["Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"].includes(breed.breed_code[0]));
        }else if(keys == "all"){
            filteredBreeds = props.breeds
        }
        setPropsBreeds(filteredBreeds);
    }

    const submitHerdSetup = () =>{
        fetch(`/herds/${props.herd.id}/preferred_breeds`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
            body: JSON.stringify({
                preferred_breeds: {
                    single_breeds: finalSingleBreedList,
                    cross_breeds: finalCrossBreedList
                }
            })
        })
        .then((result) => result.json())
        .then((result) => {
            if (result.success) {
                window.location.href = `/dashboard`
            } else {
                setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                setShowFlash(true);
                setTimeout(() => { setShowFlash(false) }, 6000);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="main-container herd preferred-breeds content-body">
            <form action="/herds">
                <Grid container className="grid-container">
                    <Grid item md={12} xs={12} >
                        <Typography variant="h6" gutterBottom className="green-font">
                            <b>Create a New Herd</b>
                        </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Grid container>
                            <Grid item md={3} xs={12}>
                                <Grid container>
                                    <Grid item xs={12} className="herd-info-container">
                                        <InputLabel htmlFor="component-simple" className="pb-15">Herd ID</InputLabel>
                                        {props.herd.herd_id}
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item xs={12} className="herd-info-container">
                                        <InputLabel htmlFor="component-simple" className="pb-15">Description</InputLabel>
                                        {props.herd.description}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} className="herd-info-container">
                                        <InputLabel htmlFor="component-simple" className="pb-15">Physical Location</InputLabel>
                                        {props.location.name}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                showSingleBreedsForm ?
                                    <SingleBreedForm 
                                        allBreeds={allBreeds}
                                        filterKeys={filterKeys}
                                        updateFinalBreedList={updateFinalBreedList}
                                        herd={props.herd}
                                        setShowSingleBreedsForm={setShowSingleBreedsForm}
                                        setSelectedSingleBreedList={setSelectedSingleBreedList}
                                        selectedSingleBreedList={selectedSingleBreedList}
                                        setFinalSingleBreedList={setFinalSingleBreedList}
                                        finalSingleBreedList={finalSingleBreedList}
                                        />
                                :
                                showCrossBreedsForm ?
                                    <CrossBreedForm
                                        selectedCrossBreedList={selectedCrossBreedList}
                                        finalCrossBreedList={finalCrossBreedList}
                                        finalSingleBreedList={finalSingleBreedList}
                                        allCrossBreeds={allCrossBreeds}
                                        herd={props.herd}
                                        setShowCrossBreedsForm={setShowCrossBreedsForm}
                                        updateFinalCrossBreedList={updateFinalCrossBreedList}
                                        setSelectedCrossBreedList={setSelectedCrossBreedList}
                                        />
                                :
                                <Grid> 
                                    <Grid item xs={12}>
                                        {
                                            (finalSingleBreedList.length >= 1 || finalCrossBreedList.length >= 1) &&
                                                <div className="selected-breed-container">
                                                    <InputLabel htmlFor="component-simple" className="pb-15">Preferred Breeds</InputLabel>
                                                    {
                                                        finalSingleBreedList.map((breed) =>{
                                                            return(
                                                                <div key={breed.id} className="pb-5">
                                                                    <b>{breed.breed_code}</b> {breed.breed_name}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        finalCrossBreedList.length > 0 &&
                                                        finalCrossBreedList.map((crossBreed, index) => {
                                                            return (
                                                                <div key={index} className="pb-5">
                                                                    <b>{crossBreed.sire.breed_code}{crossBreed.dam.breed_code}</b> {crossBreed.sire.breed_name}-{crossBreed.dam.breed_name}
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </div>  
                                        }
                                            <div className="add-breed-link">
                                                <Link href="#" className={`underline-el pl-5 ${finalSingleBreedList.length > 0 ? "grey-font": "green-font" }`} onClick={() => setShowSingleBreedsForm(true)}>
                                                    <span className="green-font"> <AddIcon className="plus-icon" /></span>Add single breed(s) to Preferred Breed list
                                                </Link>
                                            </div>
                                        {
                                            finalSingleBreedList.length < 1 &&
                                            <p className="pl-10">
                                                You must assign at least one breed to this herd. <br />
                                                Start by adding single breed(s) to your Preferred Breed List.
                                            </p>
                                        }
                                        {
                                            finalSingleBreedList.length > 1 &&
                                            <div className="add-breed-link">
                                                <Link href="#" className={`underline-el pl-5 ${finalCrossBreedList.length > 0 ? "grey-font" : "green-font"}`} onClick={() => setShowCrossBreedsForm(true)}>
                                                    <span className="green-font"> <AddIcon className="plus-icon" /></span>Add crossbreed(s) to Preferred Breed list
                                                </Link>
                                                {
                                                    finalCrossBreedList.length == 0 &&
                                                    <p className="pl-10">
                                                        Add crossbreeds if necessary.
                                                    </p>
                                                }
                                                
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        finalSingleBreedList.length >= 1 && !showCrossBreedsForm && !showSingleBreedsForm &&
                        <Grid container justify="flex-end" >
                            <Grid item md={2} className="finish-herd-setup-btn">
                                <Button variant="contained" className="submit-btn" onClick={submitHerdSetup}>
                                    <span className="green-font">FINISH HERD SET UP</span>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </form>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </div>
    )
}