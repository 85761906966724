import React, {useState} from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button

} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Flash from "../layouts/Flash";
import { formatStandardDate } from "../utils/Helper"

export default function ReplacementRecord(props) {
    const [open, setOpen] = React.useState(false);
    const [flashInfo, setFlashInfo] = useState({ flashSeverity: "", flashMessage: "" })
    const [showFlash, setShowFlash] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const replacementDelete = () => {
        event.preventDefault();
        fetch(`/herds/${props.herd.id}/replacements/${props.calf.replacement.id}`, {
            method: "DELETE",
            credentials: "same-origin",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                "X-CSRF-Token": props.authenticity_token,
            },
        })
            .then((result) => result.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = `/herds/${props.herd.id}/calves/${props.calf.id}`
                } else {
                    setFlashInfo({ flashSeverity: "error", flashMessage: result.message })
                    setShowFlash(true);
                    setTimeout(() => { setShowFlash(false) }, 6000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Grid container className="the-box">
            <Grid item md={12} xs={12} className="the-box-header">
                <div className="left-item">
                    Replacement Record
                </div>
                {
                    props.calf.replacement.deleteable && 
                    <div className="right-item">
                        <span style={{ marginLeft: "20px" }}>
                            <Link href="#" className="teeth-white-icon">
                                <DeleteIcon onClick={handleClickOpen} fontSize="small" />
                            </Link>
                        </span>
                    </div>
                }
            </Grid>
            <Grid item md={12} xs={12} className="box-table-container">
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    props.calf.sex == "Heifer" ?
                                        <TableCell className="header-cell">Herd Entry Date</TableCell>
                                        : props.calf.sex == "Bull" &&
                                        <TableCell className="data-cell">Replacement Date</TableCell>
                                }
                                <TableCell className="header-cell">Adult ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="data-cell">
                                    {formatStandardDate(props.calf.replacement.replacement_date)}
                                </TableCell>
                                {
                                    props.calf.sex == "Heifer" ?
                                        <TableCell className="data-cell">
                                            <Link className="grey-font" href={`/herds/${props.herd.id}/cows/${props.calf.replaced_animal.id}`}>
                                                {props.calf.replaced_animal.ear_tag_id}
                                            </Link>
                                        </TableCell>
                                        : props.calf.sex == "Bull" &&
                                        <TableCell className="data-cell">
                                            <Link className="grey-font" href={`/businesses/${props.herd.business_id}/bulls/${props.calf.replaced_animal.id}`}>
                                                {props.calf.replaced_animal.ear_tag_id}
                                            </Link>
                                        </TableCell>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <div className="delete-modal">
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="delete-dialog-title">Delete replacement for calf {props.calf.ear_tag_id}</DialogTitle>
                    <DialogContentText className="delete-dialog-content">
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText className="delete-dialog-content">
                        Continue?
                    </DialogContentText>
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Grid item xs={6}>
                                <Button className="green-font modal-no-link" autoFocus onClick={handleClose} color="primary">
                                    <span className="black-font"><b>No</b></span>
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="modal-button-container" >
                                <Button onClick={replacementDelete} className="modal-yes-button">
                                    <span className="green-font">Yes</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
            {
                showFlash &&
                <Flash severity={flashInfo.flashSeverity} message={flashInfo.flashMessage} />
            }
        </Grid>
    )
}